export const orderConfirmationHTML = `
    <div id="printableTableConferma-<?php the_ID(); ?>">
            <style type="text/css">
                body,div,table,thead,tbody,tfoot,tr,th,td,p { font-family:"Liberation Sans"; font-size:large; }
                a.comment-indicator:hover + comment { background:#ffd; position:absolute; display:block; border:1px solid black; padding:0.5em;  } 
                a.comment-indicator { background:red; display:inline-block; border:1px solid black; width:0.5em; height:0.5em;  } 
                comment { display:none;  } 
            </style>
            <body>
            <table align="left" cellspacing="0" border="0">
                <colgroup width="73"></colgroup>
                <colgroup span="6" width="67"></colgroup>
                <colgroup width="87"></colgroup>
                <colgroup width="56"></colgroup>
                <colgroup width="49"></colgroup>
                <tbody><tr>
                    <td colspan="10" rowspan="6" height="102" align="left" valign="top">
            <img src="/assets/conferma_ordine.png" width="250" height="97" style="float: right;">    
            <font face="Calibri" size="2.8" color="#000000">FACILEAUTORICAMBI SRL<br>Via Chambéry 93/115 Q - 10142 Torino (TO)<br>Tel. 011.19.83.66.08 - Fax. 011.19.82.99.23<br>Sito: www.facileautoricambi.it - Mail: info@facileautoricambi.it<br>Partita IVA 12571870018</font>
            </td>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    <td height="19" align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="middle"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td colspan="3" align="left" valign="bottom"><b><font face="Calibri" size="2.8" color="#000000">CONFERMA ORDINE N°</font></b></td>
                    <td colspan="2" align="right" valign="bottom" sdval="0" sdnum="1040;0;@"><b><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Numero_conferma'); ?></font></b></td>
                    </tr>
                <tr>
                    <td height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td colspan="5" align="left" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Nome'); ?> <?php echo the_field('Cognome'); ?></font></td>
                    </tr>
                <tr>
                    <td height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td colspan="5" align="left" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Telefono'); ?></font></td>
                    </tr>
                <tr>
                    <td height="10" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">TORINO,</font></td>
                    <td colspan="4" align="center" valign="bottom" sdval="0" sdnum="1040;0;@"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Data_conferma'); ?></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td height="15" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="10" height="21" align="justify" valign="bottom"><font face="Calibri" size="2.8" color="#000000">Inviamo la presente per comunicarle l'importo ed i dati per effettuare il versamento dell'</font></td>
                    </tr>
                <tr>
                    <td colspan="2" height="21" align="left" valign="bottom"><b><font face="Calibri" size="2.8" color="#000000">IMPORTO:</font></b></td>
                    <td colspan="2" align="center" valign="bottom" sdval="0" sdnum="1040;0;[$�-2] #.##0,00;[$�-2] - #.##0,00"><b><font face="Calibri" size="2.8" color="#000000">€ <?php echo the_field('Acconto'); ?></font></b></td>
                    <td colspan="6" align="justify"><font face="Calibri" size="2.8" color="#000000">per il servizio di ricerca dei ricambi sotto indicati. È </font></td>
                    </tr>
                <tr>
                    <td colspan="10" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">necessario inviare la ricevuta del versamento alla mail "facileautoricambi@gmail.com" </font></td>
                    </tr>
                <tr>
                    <td colspan="4" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">oppure al fax 011.19.82.99.23</font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td height="17" align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="2" height="21" align="center" valign="bottom"><b><font face="Calibri" size="2.8" color="#000000">VEICOLO:</font></b></td>
                    <td colspan="3" align="left" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Modello'); ?></font></td>
                    <td align="left" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Anno'); ?></font></td>
                    <td colspan="2" align="left" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Codice_motore'); ?></font></td>
                    <td align="right" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Cilindrata'); ?></font></td>
                    <td align="right" valign="bottom" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Alimentazione'); ?></font></td>
                </tr>
                <tr>
                    <td colspan="2" height="21" align="center" valign="middle"><b><font face="Calibri" size="2.8" color="#000000">RICAMBI USATI:</font></b></td>
                    <td colspan="8" align="center" valign="middle" sdval="0" sdnum="1040;"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Ricambio'); ?></font></td>
                    </tr>
                <tr>
                    <td colspan="10" rowspan="2" height="63" align="left" valign="top"><font face="Calibri" size="2.8" color="#000000">A) Estremi bancari per bonifico:<br>                  INTESTAZIONE: FACILEAUTORICAMBI SRL      BANCA INTESA SAN PAOLO<br>                  IBAN:  IT48 P030 6901 0041 0000 0077 965   BIC/SWIFT: BCITITMM<br>              </font></td>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    <td colspan="5" height="20" align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000">CAUSALE: Conferma ordine N.</font></td>
                    <td colspan="2" align="center" valign="top" sdval="0" sdnum="1040;0;@"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Numero_conferma'); ?></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td height="9" align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="10" rowspan="3" height="62" align="left" valign="top"><font face="Calibri" size="2.8" color="#000000">B) Versamento tramite BOLLETTINO POSTALE:<br>                  INTESTAZIONE: FACILEAUTORICAMBI SRL<br>                  N° CONTO POSTALE: 001058967843<br>                  </font></td>
                </tr>
                <tr>
                </tr>
                <tr>
                </tr>
                <tr>
                    <td colspan="5" height="20" align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000">CAUSALE: Conferma ordine N.</font></td>
                    <td colspan="2" align="center" valign="top" sdval="0" sdnum="1040;0;@"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Numero_conferma'); ?></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="top"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="10" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">INFO BOLLETTINO POSTALE:</font></td>
                </tr>
                <tr>
                    <td colspan="10" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">1. Si può fare anche dal Tabacchino comunicando Vocalmente l'operazione senza l'effettiva compilazione del bollettino.</font></td>
                </tr>
                <tr>
                    <td colspan="10" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">2. Necessario un documento d’identità e il codice fiscale, pagamento effettuabile con Contanti, Carta di Credito e Bancomat.</font></td>
                </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    <td height="12" align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="10" rowspan="1" height="32" align="left" valign="top"><font face="Calibri" size="2.8" color="#000000">C) Pagamento con carte di credito/bancomat -> Contattare il commerciale di riferimento per il link del pagamento.</font></td>
                </tr>
                <tr>
                    <td colspan="10" rowspan="1" height="50" align="left" valign="top"><font face="Calibri" size="2.8" color="#000000">D) Pagamento con SATISPAY -> Contattare il commerciale di riferimento per il link del pagamento.</font></td>
                </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>

                <?php if statement ?>

                <tr>
                    <td colspan="4" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">spese di spedizione circa:</font></td>
                    <td colspan="2" align="right" valign="bottom" sdval="0" sdnum="1040;0;_-&quot;�&quot; * #.##0,00_-;-&quot;�&quot; * #.##0,00_-;_-&quot;�&quot; * &quot;-&quot;??_-;_-@_-"><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Spese_spedizione'); ?></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td height="13" align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><b><font face="Calibri" size="2.8" color="#000000"><br></font></b></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="10" rowspan="3" height="66" align="justify" valign="bottom"><font face="Calibri" size="2.8" color="#000000">Le chiediamo di inviare una mail a "facileautoricambi@gmail.com" o un fax allo 011.19.82.99.23 o con i Vs. dati anagrafici per la fatturazione (Codice Fiscale o Partita Iva), luogo di spedizione insieme alla copia della ricevuta dell'avvenuto versamento.</font></td>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    <td height="10" align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" color="#000000"><br></font></td>
                </tr>
                <tr>
                    <td colspan="10" rowspan="3" height="54" align="justify"><font face="Calibri" size="1" color="#000000">Le caratteristiche e le specifiche dei materiali richiesti sono forniti dal cliente il quale detta le informazioni utili e necessarie al reperimento dei pezzi. La società che eroga il servizio di ricerca potrà rispondere solo sui propri errori diretti. La conferma d'ordine ha validità immediata e dopo l'avvenuto versamento ed il fax relativo si effettuerà subito il trasporto come da accordi. Per condizioni di vendita e diritto di recesso visitare il nostro sito www.facileautoricambi.it nell'apposita sezione. Per maggiori informazioni contattare il numero 011.19.83.66.08.</font></td>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    </tr>
                <tr>
                    <td height="21" align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td align="left" valign="bottom"><font face="Arial" size="2.8" color="#000000"><br></font></td>
                    <td colspan="5" align="center" valign="middle"><font face="Calibri" size="2.8" color="#000000">Cordiali saluti da Facileautoricambi</font></td>
                    </tr>
            </tbody></table>

            <br clear="left">
            <!-- ************************************************************************** -->
            </body>
            <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
    </div>
`

export const isContrassegno = `
    <tr>
        <td colspan="10" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">Alla consegna dei ricambi sopra indicati pagherà al corriere in contrassegno</font></td>
    </tr>
    <tr>
        <td colspan="2" height="21" align="left" valign="bottom"><b><font face="Calibri" size="2.8" color="#000000"> l'IMPORTO:</font></b></td>
        <td colspan="2" align="center" valign="bottom" sdval="0" sdnum="1040;0;[$�-2] #.##0,00;[$�-2] - #.##0,00"><b><font face="Calibri" size="2.8" color="#000000">€ <?php echo the_field('Contrassegno'); ?></font></b></td>
        <td colspan="2" align="center" valign="bottom"><font face="Calibri" size="2.8" color="#000000">a mezzo:</font></td>
        <td colspan="3" align="center" valign="bottom" sdval="0" sdnum="1040;"><b><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Mezzo_pagamento'); ?></font></b></td>
        <td align="right" valign="bottom" sdnum="1040;0;_-&quot;�&quot; * #.##0,00_-;-&quot;�&quot; * #.##0,00_-;_-&quot;�&quot; * &quot;-&quot;??_-;_-@_-"><font face="Calibri" size="2.8" color="#000000"><br></font></td>
    </tr>
`

export const isBonifico = `
    <tr>
        <td colspan="10" height="21" align="left" valign="bottom"><font face="Calibri" size="2.8" color="#000000">Il pagamento dei ricambi sopra indicati verrà effettuato tramite:</font></td>
    </tr>
    <tr>
        <td colspan="10" align="left" valign="bottom" sdval="0" sdnum="1040;0;[$�-2] #.##0,00;[$�-2] - #.##0,00"><b><font face="Calibri" size="2.8" color="#000000"><?php echo the_field('Bonifico'); ?></font></b></td>
    </tr>
`