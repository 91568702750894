import { AxiosResponse } from 'axios';
import axios from '../api';
import { IAction, IPermission, IPermissionBody } from '../../helpers/interfaces/permessi-navigazione';

const API_URL = process.env.REACT_APP_API_URL;

class PermessiNavigazioneService {

    getAllUserActions(): Promise<IAction[]> {
        return axios.get<IAction[]>(API_URL + 'userActions')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getOneUserActions(id: any): Promise<IAction> {
        return axios.get<IAction>(API_URL + 'userAction/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    // lista dei permessi collegati ad una singolo utente
    getPermissionsPerUser(id: any): Promise<IPermission> {
        return axios.get<IPermission>(API_URL + 'user/' + id + '/permissions')
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    // modifica dei permessi collegati ad un singolo utente
    setPermissionsPerUser(id: any, body: IPermissionBody): Promise<any> {
        return axios.put<IPermissionBody>(API_URL + 'user/' + id + '/permissions', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // lista degli utenti collegati ad una singola user action
    getUsersPerAction(id: any): Promise<any> {
        return axios.get<any>(API_URL + 'userAction/' + id + '/users')
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    // modifica degli utenti collegati ad una singola user action
    setUsersPerAction(id: any, body: any): Promise<any> {
        return axios.put<any>(API_URL + 'userAction/' + id + '/users', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}

export default new PermessiNavigazioneService();