import { AxiosResponse } from 'axios';
import axios from '../api';
import { ICustomer, ICustomerRow, ICustomerList, ICustomerBody, IMultipleDelete, searchFuncionBody } from '../../helpers/interfaces/customer';

const API_URL = process.env.REACT_APP_API_URL;

class GestioneClientiService {

    getCustomersAll(): Promise<ICustomerList> {
        return axios.get<ICustomerList>(API_URL + 'customers/all')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getCustomers(limit: any, offset: any): Promise<ICustomer> {
        return axios.get<ICustomer>(API_URL + 'customers?limit=' + limit + '&offset=' + offset)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getCustomer(id: any): Promise<ICustomerRow> {
        return axios.get<ICustomerRow>(API_URL + 'customer/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addCustomer(body: ICustomerBody): Promise<ICustomer> {
        // const payload = {...body, commercial: Number(body.commercial)};
        const payload: any = {
            broadcast: body.broadcast && body.broadcast !== "" ? body.broadcast : null,
            commercial: body.commercial && body.commercial !== 0 ? body.commercial : null,
            date: body.date && body.date !== "" ? body.broadcast : null,
            email_address: body.email_address && body.email_address !== "" ? body.email_address : null,
            first_name: body.first_name && body.first_name !== "" ? body.first_name : null,
            last_name: body.last_name && body.last_name !== "" ? body.last_name : null,
            phone_number: body.phone_number && body.phone_number !== "" ? body.phone_number : null,
        }
        return axios.post<ICustomerBody>(API_URL + 'customer', payload )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editCustomer(id: any, body: ICustomerBody): Promise<ICustomer> {
        const payload = {...body, commercial: Number(body.commercial)};
        return axios.put<ICustomerBody>(API_URL + 'customer/' + id, payload )
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteCustomer(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'customer/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // AZIONI SU SELEZIONATI
    moveSelectedCustomers(id: any, body: {customer_ids: Array<number>}): Promise<any> {
        return axios.put<any>(API_URL + 'customers/move/commercial/' + id, body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteSelectedCustomers(body: {customer_ids: Array<number>}): Promise<IMultipleDelete> {
        return axios.post<any>(API_URL + 'customers/delete', body)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // RICERCA
    searchFuncion(body: searchFuncionBody): Promise<ICustomer> {
        return axios.post<any>(API_URL + 'customers/search', body)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new GestioneClientiService();