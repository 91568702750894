import axios, { AxiosResponse } from "axios";
import { ILoggedUser, IChangePassword } from "../helpers/interfaces/user";
// import { history } from "../helpers/history";
// import EventBus from "../common/EventBus";
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {

  async login(email: string, password: string): Promise<ILoggedUser> {
    return await axios.post(API_URL + "auth/login", {
      email_address: email,
      password: password
    })
      .then((response: AxiosResponse<any>) => {
        const data: ILoggedUser = response.data.data;
        if (data.token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        return response.data;
      });
  }

  logout(): void {
    localStorage.removeItem("user");
    // EventBus.dispatch("hideLoader");
    // history.push("/login");
    window.location.reload();
  }

  async getCurrentUser(): Promise<ILoggedUser | null> {
    const userStr: string | null = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }

  async getIsAdmin() : Promise<boolean> {
    const userString: string | null = localStorage.getItem("user");

    if (!userString) return false;

    const user: ILoggedUser = JSON.parse(userString);
    // return user.roles.find((role: any) => role.code === "admin" || role.code === "amministrazione");
    return !!user.roles.find((role: any) => role.code === "ADM");
  }

  changePassword(body: IChangePassword) {
    return axios.post<any>(API_URL + "changepassword", body)
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  // recoverPassword(body: { registrationemail: string }) {
  //   return axios.post<{ registrationemail: string }>(API_URL + "passwordrecover", body)
  //     .then((response: AxiosResponse<any>) => { return response.data; })
  //     .catch((error) => { return Promise.reject(); });
  // }

  // resetPassword(body: any) {
  //   return axios.post<any>(API_URL + "passwordreset", body)
  //     .then(
  //       (response: AxiosResponse<any>) => { return response.data; })
  //     .catch((error) => { return Promise.reject(); });
  // }
}

export default new AuthService();
