import * as Yup from "yup";
import { IFormField, IFormFieldOption } from "../../helpers/interfaces/generic";

const msg_required = "Campo obbligatorio."
const msg_min3 = "La stringa deve contenere un minimo di 3 caratteri."
const msg_max30 = "La stringa può contenere un massimo di 30 caratteri."
const msg_boundary = "Il codice deve essere una stringa alfanumerica di tre caratteri."

export function addRoleFormFields(): Array<IFormField> {
  return [
    {
      'label': 'Ruolo',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().min(3, msg_min3).max(30, msg_max30).required(msg_required)
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup
        .string()
        .matches(/^[a-zA-Z0-9]{3}$/, msg_boundary)
        .required(msg_required)
    }
  ];
}

export function editRoleFormFields(): Array<IFormField> {
    return [
      {
        'label': 'Ruolo',
        'name': 'name',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().min(3, msg_min3).max(30, msg_max30).required(msg_required)
      },
      {
        'label': 'Codice',
        'name': 'code',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup
          .string()
          .matches(/^[a-zA-Z0-9]{3}$/, msg_boundary)
          .required(msg_required)
      }
    ];
  }

  export function joinUserRoleFormFields(utenti: IFormFieldOption[]): Array<IFormField> {
    return [
      {
        'label': 'Utenti',
        'name': 'users',
        'type': 'multi-select',
        'options': utenti,
        'class': 'form-control',
      }
    ];
  }