import { AxiosResponse } from 'axios';
import axios from '../api';
import { IPracticeCategory, IPracticeCategoryRow, IPracticeCategoryBody } from '../../helpers/interfaces/categories';
import { ICategoryAll } from '../../helpers/interfaces/practices';

const API_URL = process.env.REACT_APP_API_URL;

class CategoriePraticheService {

    getPracticeCategories(): Promise<IPracticeCategory> {
        return axios.get<IPracticeCategory>(API_URL + 'practiceCategories')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }
    
    getPracticeCategoriesAll(): Promise<ICategoryAll> {
        return axios.get<ICategoryAll>(API_URL + 'practiceCategories/all')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getPracticeCategory(id: any): Promise<IPracticeCategoryRow> {
        return axios.get<IPracticeCategoryRow>(API_URL + 'practiceCategory/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addPracticeCategory(body: IPracticeCategoryBody): Promise<IPracticeCategoryRow> {
        return axios.post<IPracticeCategoryBody>(API_URL + 'practiceCategory', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editPracticeCategory(id: any, body: IPracticeCategoryBody): Promise<IPracticeCategoryRow> {
        return axios.put<IPracticeCategoryBody>(API_URL + 'practiceCategory/' + id, body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deletePracticeCategory(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'practiceCategory/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new CategoriePraticheService();