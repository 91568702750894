import * as Yup from "yup";
import { IFormField } from "../../helpers/interfaces/generic";

const msg_required = "Campo obbligatorio."
const msg_min3 = "La stringa deve contenere un minimo di 3 caratteri."
const msg_max60 = "La stringa può contenere un massimo di 60 caratteri."

export function addRouteGroupFormFields(): Array<IFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().min(3, msg_min3).max(30, msg_max60).required(msg_required)
    },
    {
      'label': 'Descrizione',
      'name': 'description',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().min(3, msg_min3).max(30, msg_max60).required(msg_required)
    }
  ];
}

export function editRouteGroupFormFields(): Array<IFormField> {
    return [
      {
        'label': 'Descrizione',
        'name': 'description',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().required(msg_required)
      }
    ];
  }