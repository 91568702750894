import { AxiosResponse } from 'axios';
import axios from '../api';
import { IFile } from '../../helpers/interfaces/file-types';

const API_URL = process.env.REACT_APP_API_URL;

class GestioneAllegatiService {

    getFiles(): Promise<IFile[]> {
        return axios.get<IFile[]>(API_URL + 'files')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getFile(id: any): Promise<IFile> {
        return axios.get<IFile>(API_URL + 'file/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addFile(body: any): Promise<IFile> {
        return axios.post<any>(API_URL + 'file', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editFile(id: any, body: any): Promise<IFile> {
        return axios.put<any>(API_URL + 'file/' + id, body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteFile(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'file/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // downloadFile(id: any): Promise<any> {
    //     return axios({
    //         method: 'GET',
    //         url: API_URL + 'file/' + id + '/download',
    //         responseType: 'blob'
    //     })
    //     .then((response: any) => { 

    //         let urlFile = URL.createObjectURL(response.data);

    //         let a = document.createElement('a');
    //         a.href = urlFile;
    //         a.setAttribute('download', response.headers["content-description"]);
    //         document.body.appendChild(a);
    //         a.click();
           
    //         document.body.removeChild(a);
    //         URL.revokeObjectURL(urlFile);
    //         return null;
    //     })
    //     .catch((error) => { return Promise.reject(error); });
    // }

    downloadFile(id: any): Promise<any> {
        return axios({
            method: 'GET',
            url: API_URL + 'file/' + id + '/download',
            responseType: 'blob'
        })
            .then((response: AxiosResponse<any>) => { return response; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new GestioneAllegatiService();