function Dashboard() {

    return (
        <div className="custom-container">
            <div className='card'>
                <div className='card-body'>
                    <h2 className='card-title'>DASHBOARD</h2>
                </div>
            </div>
        </div>
    )
}
export default Dashboard