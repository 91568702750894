import { AxiosResponse } from 'axios';
import axios from '../api';
import { IFileType, IFileTypeBody } from '../../helpers/interfaces/file-types';

const API_URL = process.env.REACT_APP_API_URL;

class TipologieAllegatiService {

    getFileTypes(): Promise<IFileType[]> {
        return axios.get<IFileType[]>(API_URL + 'fileTypes')
            .then((response: AxiosResponse<any>) => { return Array.isArray(response.data.data) ? response.data.data : []; })
            .catch((error) => { return Promise.reject(error); });
    }

    getFileType(id: any): Promise<IFileType> {
        return axios.get<IFileType>(API_URL + 'fileType/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addFileType(body: IFileTypeBody): Promise<IFileType> {
        return axios.post<IFileTypeBody>(API_URL + 'fileType', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editFileType(id: any, body: IFileTypeBody): Promise<IFileType> {
        return axios.put<IFileTypeBody>(API_URL + 'fileType/' + id, body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteFileType(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'fileType/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new TipologieAllegatiService();