import React, { Component } from 'react';
import TipologieAllegatiService from '../../services/api/tipologie-allegati.service';
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import getFileTypeColumnDefs, { listFileTypeColumns } from '../../config/table-columns/tipologie-allegati';
import { addFileTypeFormFields, editFileTypeFormFields } from '../../config/form-fields/tipologie-allegati';
import { IFileType } from '../../helpers/interfaces/file-types';
import { IRole, ILoggedUser } from '../../helpers/interfaces/user';
import { IFormField } from "../../helpers/interfaces/generic";
import * as Yup from "yup";
import Swal from "sweetalert2";
import EventBus from '../../common/EventBus';

const $ = require('jquery');

type State = {
    formFields: Array<IFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    tipologieAllegati: IFileType[],
    tipologieAllegatiNumber: number,
    hasPermissions: boolean
}

export default class TipologieAllegati extends Component<{}, State> {

    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            tipologieAllegati: [],
            tipologieAllegatiNumber: 0,
            hasPermissions: false
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const userString: any = localStorage.getItem("user");
        const currentUser: ILoggedUser = JSON.parse(userString);
        currentUser.roles.map((role: IRole) => {
            // if (role.code.toLocaleLowerCase() === "adm") this.setState({hasPermissions: true});
            this.setState({hasPermissions: true});
            return null;
        });

        TipologieAllegatiService.getFileTypes()
        .then((response: IFileType[]) => {
            this.setState({ tipologieAllegati: response });
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });

        $('#table_file-type').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const typeId = $(e.currentTarget).data('id');
            const fileTypeDetails = await TipologieAllegatiService.getFileType(typeId);

            if (fileTypeDetails) {
                this.setState({
                    formFields: editFileTypeFormFields(),
                    formInitialValues: {id: fileTypeDetails.id, code: fileTypeDetails.code, name: fileTypeDetails.name}
                }, () => {
                    this.apiSubmit = TipologieAllegatiService.editFileType;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica i dettagli della tipologia' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_file-type').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idItem = $(e.currentTarget).data('id');
                    const response: any = await TipologieAllegatiService.deleteFileType(idItem);
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    async openAddModale() {
        this.setState({
            formFields: addFileTypeFormFields(),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = TipologieAllegatiService.addFileType;
            this.setState({ showModal: true, modalType: 'add', modalTitle: 'Aggiungi una nuova tipologia' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    render() {
        const { formFields, formInitialValues, showModal, modalTitle, modalType, tipologieAllegati, hasPermissions } = this.state;

        // const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Elenco tipologie di allegati</h2>
                                {
                                    hasPermissions &&
                                    <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={() => this.openAddModale()}>
                                        <span>Aggiungi tipologia</span>
                                    </button>
                                }
                            </div>
                            <ModalForm
                                showModal={showModal}
                                modalTitle={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                            {
                                // tipologieAllegati.length > 0 &&
                                <Table id={'table_file-type'} columns={listFileTypeColumns} columnDefs={getFileTypeColumnDefs()} datas={tipologieAllegati} buttons={[]} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
