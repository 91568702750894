import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IRole } from '../../helpers/interfaces/user';

export const listRolesColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Ruolo", data: "name" },
    { title: "Codice", data: "code" }
];

export default function getRolesColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            width: "15%",
            render: (data: any, type: any, row: IRole) => {

                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${data}" title="Modifica dettagli ruolo">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                const join = `<a class="join_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${data}" title="Associa utenti a ruolo">
                                <i style="font-size: 18px" class="fa fa-user-circle" aria-hidden="true"></i>
                            </a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}" title="Elimina ruolo">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + join + trash + '</div>';
            },
        }
    ];
}
