import { ConfigColumns } from "datatables.net";
import { IRoute } from '../../helpers/interfaces/admin';

export const listRoleAuthorizationsColumns: ConfigColumns[] = [
    { title: "", data: "isChecked" },
    { title: "Nome", data: "name" },
    { title: "Codice", data: "code" }
];

export function getRoleAuthorizationsColumnDefs(group: any) {
    return [
        {
            target: 0,
            searchable: false,
            orderable: false,
            width: "5%",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: IRoute): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox"' + (isChecked && 'checked') + ' data-id="' + row.id + '" data-group="' + group + '" />';

                return '<span>' + checkbox + '</span>'
            }
        }
    ];
}