import React from 'react';
import AdminService from '../../../services/api/admin.service';
import { IAuthorizationList, IRouteGroup, IRouteObj } from '../../../helpers/interfaces/admin';
import Table from '../../../common/TableComponent';
import { Tab, TabProp } from '../../../common/TabComponent';
import { getRouteAuthorizationsColumnDefs, listRouteAuthorizationsColumns } from '../../../config/table-columns/autorizzazione-rotte';
import $ from 'jquery';
import Swal from 'sweetalert2';
import eventBus from '../../../common/EventBus';

type Props = {};

type State = {
    tabs: TabProp[];
    currentTab: number;
    changes: number[];
    routesList: IRouteObj[];
    routeGroupsList: IRouteGroup[];
    authorizationsList: IAuthorizationList;
    group: { id: number, code: string };
    data: { [key: string]: number[] };
};

export default class AutorizzazioneRotte extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            tabs: [],
            currentTab: 0,
            changes: [],
            routesList: [],
            routeGroupsList: [],
            authorizationsList: {},
            group: { id: -1, code: '' },
            data: {}
        }
    }

    async componentDidMount(): Promise<void> {
        await this.loadData();
        // .then(() => { // senza questa inizializzazione va' in errore nell'if di riga 97 (Codice gruppo non trovato)
        //     if (this.state.routeGroupsList[0]) {
        //         this.setState({
        //             group: {
        //                 id: this.state.routeGroupsList[0].id,
        //                 code: this.state.routeGroupsList[0].name
        //             }
        //         });
        //     }
        // });

        $('#tab-container').on('change', 'input[type=checkbox]', async (e) => {
            e.preventDefault();

            let { data, changes } = this.state;
            const routeId = $(e.currentTarget).data('id');
            const groupId = $(e.currentTarget).data('group');
            const checked = $(e.currentTarget).is(':checked');

            if (routeId && groupId && data[groupId]) {
                const index = data[groupId].findIndex(elem => {
                    return elem === routeId;
                })

                if (checked && !data[groupId][index]) {
                    data[groupId].push(routeId);
                } else if (!checked && data[groupId][index]) {
                    data[groupId] = data[groupId].filter((value: number, key: number) => {
                        return key !== index
                    });
                }

                const changeIndex = changes.findIndex(value => {
                    return value === routeId
                });

                if (changeIndex === -1) {
                    changes.push(routeId);
                } else {
                    changes = changes.filter((value: number, key: number) => {
                        return key !== changeIndex
                    });
                }
            }

            this.setState({ data, changes });
        });

        $('#tab-container').on('click', '.save_btn', async (e: any) => {
            e.preventDefault();

            const { data, group } = this.state;
            const id: any = group?.id;
            
            if (!id || (id && typeof data[id] === 'undefined')) {
                Swal.fire('Errore', 'Codice gruppo non trovato oppure non valido', 'error');
            } else {
                const payload = Object.values(data[id]);

                AdminService.assignRoutesToRouteGroup(id, { routes: payload })
                .then(async (response: any) => {
                    if (typeof response.error === 'undefined') {
                        this.setState({changes: []}, async () => await this.loadData());
                    } else {
                        Swal.fire('Errore', 'Si è verificato un errore durante il salvataggio dei dati', 'error');
                    }
                },
                error => Swal.fire('Errore', error ? error : 'Si è verificato un errore durante il salvataggio dei dati', 'error'))
                .catch(error => Swal.fire('Errore', error ? error : 'Si è verificato un errore imprevisto durante il salvataggio dei dati', 'error'));
            }
        });
    }

    async loadData() {
        eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        Promise.all([
            AdminService.getRouteGroups(),
            AdminService.getRoutes()
        ]).then(async (data: [
            IRouteGroup[],
            IRouteObj[]
        ]) => {
            if (data[0] && data[0].length > 0) {
                await this.getRoutePerRouteGroupFn(data[0])
                .then(() => {
                    this.setState({
                        routeGroupsList: data[0],
                        routesList: data[1] ?? []
                    }, () => {
                        if (this.state.routeGroupsList[0] && this.state.group.id < 0) {
                            this.setState({
                                group: {
                                    id: this.state.routeGroupsList[0].id,
                                    code: this.state.routeGroupsList[0].name
                                }
                            });
                        }
                        this.loadTabData();
                    });
                });
            }
        })
        .catch(() => {
            Swal.fire({
                title: 'Errore',
                text: 'A causa di un errore non è stato possibile caricare i dati.',
                icon: 'error'
            });
        })
        .finally(() => {
            eventBus.dispatch("hideLoader");
        });
    }

    async getRoutePerRouteGroupFn(groups: IRouteGroup[]) {
        let authorizationsList: any = {};

        await Promise.all(groups.map(async (group: IRouteGroup) => {
            await AdminService.getRoutePerRouteGroup(group.id)
            .then((routes: IRouteObj[]) => {
                routes.map((route: IRouteObj) => {
                    if (!authorizationsList[group.id]) authorizationsList[group.id] = [];
                    authorizationsList[group.id].push(route.id);
                    return null;
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Errore',
                    text: 'A causa di un errore non è stato possibile caricare i dati.',
                    icon: 'error'
                });
            });
            return null;
        }));

        this.setState({ authorizationsList: authorizationsList });
    }

    loadTabData() {
        let tabs: TabProp[] = [];
        let data: { [key: string]: number[] } = {};

        this.state.routeGroupsList.forEach((group: IRouteGroup) => {
            let tabData: Array<IRouteObj & { isChecked: boolean }> = [];
            data[group.id] = [];

            this.state.routesList.forEach((route: IRouteObj) => {
                let result: any = { ...route };
                let isChecked = this.state.authorizationsList[group.id] ? this.state.authorizationsList[group.id].includes(route.id) : false;
                Object.assign(result, { isChecked });
                tabData.push(result);
                if (isChecked) {
                    data[group.id].push(route.id);
                }
            });

            const groupName = group.name.replace(/\s/g, '');
            tabs.push({
                id: `${groupName}_${group.id}`,
                label: group.name,
                args: { id: group.id, code: group.id },
                content: <div id={`admin_authorizations_${groupName}_${group.id}`}>
                    <Table
                        id={`table_admin_authorizations_${groupName}_${group.id}`}
                        columns={listRouteAuthorizationsColumns}
                        columnDefs={getRouteAuthorizationsColumnDefs(group.id)}
                        datas={tabData}
                        buttons={[]}
                    />
                    <div className="d-flex justify-content-end">
                        <button className='save_btn btn topic-full-btn-color mt-30'>Salva</button>
                    </div>
                </div>
            });
        });

        this.setState({ tabs, data });
    }

    render() {
        const { tabs, changes } = this.state;

        return <div className="custom-container">
            <div className='card'>
                <div className='card-body'>
                    <h2 className='card-title'>Associa rotte al gruppo-rotte</h2>
                    <div id="tab-container">
                        <Tab
                            canChangeTab={changes.length === 0}
                            tabs={tabs}
                            tabClickHandler={async (currentTab, args) => {
                                this.setState({
                                    currentTab,
                                    changes: [],
                                    group: args ? { id: args.id, code: args.code } : this.state.group
                                }, () => {
                                    this.loadTabData();
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}