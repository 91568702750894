import { useState } from 'react'
import Routes from '../../routes/routes';

function HomePage() {
    const [goTo, setGoTo] = useState(false);

    return (
        // <div style={{ 'margin': '35px 55px' }}>
        <div className="container-fluid py-3" style={{ minHeight: "calc(100% - 112px)" }}>
        {/* <div className="custom-container">
            <div className="card">
                <div className="card-body"> */}
                    {
                        !goTo &&
                        <>
                            <h1>Home page </h1>
                            <p>Home page pubblica, non richiede il login</p>
                            <button className="btn topic-full-btn-color" onClick={() => setGoTo(true)}>Vai al login</button>
                        </>
                    }
                
                    {goTo && <Routes />}
                {/* </div>
            </div> */}
        </div>
    )
}
export default HomePage

// import { Component } from 'react';

// type Props = {
// };
  
// type State = {
//     goTo: boolean
// }

// class HomePage extends Component<Props, State> {
//     constructor(props: Props) {
//         super(props);
//         this.state = {
//             goTo: false,
//         }
//     }

//     render() {
//         const { goTo } = this.state;

//         return (
//             <>
//                 {
//                     !goTo &&
//                     <>
//                         <h1>Home page </h1>
//                         <p>Home page generica, non richiede il login</p>
//                         <button className="btn topic-full-btn-color" onClick={() => this.setState({goTo: true})}>Vai al login</button>
//                     </>
//                 }
           
//                 {goTo && <Routes />}
//             </>
//         )
//     }
// }
// export default HomePage