import { AxiosResponse } from 'axios';
import axios from '../api';
import { IRole, IRoleBody, IJoinUsersRole } from '../../helpers/interfaces/user';

const API_URL = process.env.REACT_APP_API_URL;

class TipologieRuoliService {

    getRoles(): Promise<IRole[]> {
        return axios.get<IRole[]>(API_URL + 'userRoles')
            .then((response: AxiosResponse<any>) => { return Array.isArray(response.data.data) ? response.data.data : []; })
            .catch((error) => { return Promise.reject(error); });
    }

    getRole(id: any): Promise<IRole> {
        return axios.get<IRole>(API_URL + 'userRole/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addRole(body: IRoleBody): Promise<IRole> {
        return axios.post<IRoleBody>(API_URL + 'userRole', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editRole(id: any, body: IRoleBody): Promise<IRole> {
        return axios.put<IRoleBody>(API_URL + 'userRole/' + id, body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    assignUsersToRole(id: any, body: any): Promise<IJoinUsersRole> {
        const payload =  Array.isArray(body?.users) ? body : [];
        return axios.put<Array<number>>(API_URL + 'userRole/' + id + '/users', payload )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteRole(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'userRole/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new TipologieRuoliService();