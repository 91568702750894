export const transportOrderHTML = `
    <div id="printableTableTrasporto-<?php the_ID(); ?>">
    <style type="text/css">
            body,div,table,thead,tbody,tfoot,tr,th,td,p { font-family:"Liberation Sans"; font-size:large; }
            a.comment-indicator:hover + comment { background:#ffd; position:absolute; display:block; border:1px solid black; padding:0.5em;  } 
            a.comment-indicator { background:red; display:inline-block; border:1px solid black; width:0.5em; height:0.5em;  } 
            comment { display:none;  }
        </style>
    <body>
    <table align="left" cellspacing="0" border="0">
        <colgroup width="81"></colgroup>
        <colgroup width="85"></colgroup>
        <colgroup width="65"></colgroup>
        <colgroup width="72"></colgroup>
        <colgroup width="68"></colgroup>
        <colgroup width="79"></colgroup>
        <colgroup width="95"></colgroup>
        <colgroup width="51"></colgroup>
        <colgroup width="52"></colgroup>
        <tr>
            <td colspan=10 rowspan=7 height="119" align="right" valign=top><img src="/assets/conferma_ordine.png" width="250" height="97" style="float: left;"><font face="Calibri" color="#000000">FACILEAUTORICAMBI SRL<br>Via Chambéry 93/115 Q - 10142 Torino (TO)<br>Tel. 011.19.83.66.08 - Fax. 011.19.82.99.23<br>Sito: www.facileautoricambi.it - Mail: info@facileautoricambi.it<br>Partita IVA 12571870018</font></td>
            </tr>
        <tr>
            </tr>
        <tr>
            </tr>
        <tr>
            </tr>
        <tr>
            </tr>
        <tr>
            </tr>
        <tr>
            </tr>
        <tr>
            <td height="20" align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="20" align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="right" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="20" align="left"><font face="Calibri" color="#000000"><br></font></td>
            <td align="left"><font face="Calibri" color="#000000"><br></font></td>
            <td align="left"><font face="Calibri" color="#000000"><br></font></td>
            <td align="left"><font face="Calibri" color="#000000"><br></font></td>
            <td align="left"><font face="Calibri" color="#000000"><br></font></td>
            <td align="left"><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=10 rowspan=2 height="34" align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000">ORDINE DI TRASPORTO</font></b></td>
            </tr>
        <tr>
            </tr>
        <tr>
            <td height="35" align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
            <td align="center" valign=bottom><b><font face="Calibri" size=5 color="#000000"><br></font></b></td>
        </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000">TORINO,</font></td>
            <td colspan=3 align="left" valign=bottom sdval="0" sdnum="1040;0;@"><font face="Calibri" color="#000000"><?php echo the_field('Data_spedizione'); ?></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=3 height="25" align="center" valign=bottom><b><u><font face="Calibri" size=4 color="#000000">AL FORNITORE</font></u></b></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td colspan=4 align="center" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" size=4 color="#000000"><?php echo the_field('Demolitore'); ?></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=7 height="20" align="left" valign=bottom><font face="Calibri" color="#000000">Inviamo il presente fax per confermarle la vendita di:</font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=3 height="20" align="center" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" color="#000000"><?php echo the_field('Modello'); ?></font></td>
            <td align="right" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" color="#000000"><?php echo the_field('Anno'); ?></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td colspan=2 align="center" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" color="#000000"><?php echo the_field('Codice_motore'); ?></font></td>
            <td align="right" valign=bottom sdval="0" sdnum="1040;"><font face="Arial" color="#000000"><?php echo the_field('Cilindrata'); ?></font></td>
            <td align="right" valign=bottom sdval="0" sdnum="1040;"><font face="Arial" color="#000000"><?php echo the_field('Alimentazione'); ?></font></td>
        </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=9 rowspan=2 height="34" align="center" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" color="#000000"><?php echo the_field('Ricambio'); ?></font></td>
            </tr>
        <tr>
            </tr>
        <tr>
            <td height="17" align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=9 height="20" align="left" valign=bottom><font face="Calibri" color="#000000">L&rsquo;organizzazione del trasporto &egrave; affidata a Voi come da accordi.</font></td>
            </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=5 height="20" align="left" valign=bottom><font face="Calibri" color="#000000">Il tutto va consegnato e fatturato  a saldo  per:</font></td>
            <td colspan=2 align="center" valign=bottom sdval="0" sdnum="1040;0;[$&euro;-2] #.##0,00;[$&euro;-2] - #.##0,00"><b><font face="Calibri" color="#000000">€ <?php echo the_field('Contrassegno'); ?></font></b></td>
            <td colspan=2 align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            </tr>
        <tr>
            <td height="20" align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="center" valign=bottom sdnum="1040;0;[$&euro;-2] #.##0,00;[$&euro;-2] - #.##0,00"><b><font face="Calibri" color="#000000"><br></font></b></td>
            <td align="center" valign=bottom sdnum="1040;0;[$&euro;-2] #.##0,00;[$&euro;-2] - #.##0,00"><b><font face="Calibri" color="#000000"><br></font></b></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Calibri" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="17" align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=4 height="25" align="center" valign=bottom><b><u><font face="Calibri" size=4 color="#000000">DATI SPEDIZIONE</font></u></b></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="17" align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=6 height="17" align="left" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" color="#000000"><div style="white-space:pre-wrap;"><?php echo the_field('dati_spedizione'); ?></div></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        
        <tr>
            <td height="17" align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=4 height="25" align="center" valign=bottom><b><u><font face="Calibri" size=4 color="#000000">DATI FATTURAZIONE</font></u></b></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="17" align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td colspan=6 height="17" align="left" valign=bottom sdval="0" sdnum="1040;"><font face="Calibri" color="#000000"><div style="white-space:pre-wrap;"><?php echo the_field('dati_fatturazione'); ?></div></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
        </tr>
        <tr>
            <td height="25" align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td align="left" valign=bottom><font face="Arial" color="#000000"><br></font></td>
            <td colspan=6 align="center" valign=middle><b><font face="Calibri" size=4 color="#000000">Cordiali saluti da Facileautoricambi</font></b></td>
            </tr>
    </table>
    <br clear=left>
    <!-- ************************************************************************** -->
    </body>
    <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
    </div>
    <script>
        function printDiv2(id_articolo) {
                window.frames["print_frame"].document.body.innerHTML = document.getElementById("printableTableTrasporto-"+id_articolo).innerHTML;
                window.frames["print_frame"].window.focus();
                window.frames["print_frame"].window.print();
            }
    </script>
`