import React, { Component } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { IFormField, IFormFieldOption, IFormFieldValue } from '../helpers/interfaces/generic';
import { IFile } from '../helpers/interfaces/practices';
import { Formik, Field, ErrorMessage, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import EventBus from "../common/EventBus";
import AutoNumeric from 'autonumeric';
import { buttonsStyle, iconsStyle } from '../helpers/settings/buttons-icons-styles';
import DomPurify from 'dompurify';
import DatePickerForm from './DatePickerForm';
import moment from 'moment';
import { base64Encode } from './Base64';
// import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ModalHTMLDocument from '../common/ModalHTMLDocument';

declare var bootstrap: any;
const $ = require('jquery');

type Props = {
    id?: string;
    initialValues: { [key: string]: any };
    formFields: Array<IFormField>;
    showModal: boolean;
    modalType: string;
    modalTitle: string;
    apiSubmit: CallableFunction;
    submitCallback?: CallableFunction;
    closeCallback: CallableFunction;
    successAction?: CallableFunction;
    apiDownload?: CallableFunction;
    apiDelete?: CallableFunction;
    fileTypeList?: IFormFieldOption[];
    attachmentsList?: Array<IFile>;
    saveButtonName?: string;
    practicesUpdateCallback: CallableFunction;
};

type State = {
    formValue: any;
    showModal: boolean,
    filteredBySearch?: any[],
    selectedLabel?: string,
    fileTypeList: IFormFieldOption[];
    attachmentType: Array<number>,
    attachmentsList: Array<IFile>;
    attachmentsToUpload: File[],
    inputDisabled: boolean,
    disableCustomerFields: boolean,
    documentModalShow: boolean,
    documentModalTitle: string,
    documentModalType: string,
    documentValues: { [key: string]: any }, // trasporto i valori di initialValue
};

class PracticesModalForm extends Component<Props, State> {
    formFields: Array<IFormField>;
    innerRefs: { [key: string]: string };
    initialValues: { [key: string]: any };
    isFile: boolean;
    modal: any;
    subtitle: string;
    attachmentTypeTemp: Array<number>;
    selectRef: any;

    constructor(props: Props) {
        super(props);
        this.formFields = props.formFields;
        this.innerRefs = {};
        this.initialValues = {};
        this.isFile = false;
        this.attachmentTypeTemp = [];
        this.selectRef = null;

        this.state = {
            formValue: {},
            showModal: false,
            fileTypeList: props.fileTypeList ?? [],
            attachmentType: [],
            attachmentsList: props.attachmentsList ?? [],
            attachmentsToUpload: [],
            inputDisabled: true,
            disableCustomerFields: true,
            documentModalShow: false,
            documentModalTitle: "",
            documentModalType: "",
            documentValues: []
        }

        this.subtitle = ''
        this.modal = null;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if ((prevProps.showModal !== this.props.showModal && this.props.showModal) || (this.props.showModal && prevProps.formFields !== this.props.formFields)) {
            this.formFields = this.props.formFields;
            this.initialValues = this.props.initialValues;

            this.formFields.forEach(
                (value: IFormField) => {
                    let propsValue = this.props.initialValues[value.name];
                    // propsValue = propsValue == false || propsValue == true ? Number(propsValue) : (propsValue || '');
                    this.initialValues[value.name] = propsValue;
                }
            );

            // let parentJoborder = Object.keys(this.props.initialValues).find((element: string) => element === 'parentJoborder');
            // if (parentJoborder) this.subtitle = this.props.initialValues[parentJoborder]
            this.subtitle = this.props.initialValues?.parentJoborder ? this.props.initialValues?.parentJoborder : '';

            let searchField = this.props.formFields.find(field => field.type === 'search')
            if (searchField) this.setState({ filteredBySearch: searchField.values })

            this.setState({ showModal: true });
        }

        if (prevState.showModal !== this.state.showModal && this.state.showModal) {
            const id = this.props.id ? this.props.id : 'practices-modal-form';
            const modalAction = document.getElementById(id);

            if (modalAction) {
                const _self = this;

                modalAction.addEventListener('hidden.bs.modal', function () {
                    _self.setState({ showModal: false }, () => {
                        _self.props.closeCallback();
                    });
                });

                modalAction.addEventListener('shown.bs.modal', function () {
                    if (!AutoNumeric.isManagedByAutoNumeric(modalAction)) {
                        const autoNumericOptionsEuro: any = {
                            digitGroupSeparator: '.',
                            decimalCharacter: ',',
                            decimalCharacterAlternative: '.',
                            currencySymbol: '€',
                        };
                        AutoNumeric.multiple(`#${id} .autoNumeric`, autoNumericOptionsEuro);
                    }
                });

                this.modal = new bootstrap.Modal(modalAction);
                this.modal.show();
            }
        }

        if ((prevProps.fileTypeList !== this.props.fileTypeList && this.props.fileTypeList)) {
            // this.fileTypeList = this.props.fileTypeList;
            this.setState({ fileTypeList: this.props.fileTypeList });
        }
        
        if ((prevProps.attachmentsList !== this.props.attachmentsList && this.props.attachmentsList)) {
            // this.attachmentsList = this.props.attachmentsList;
            this.setState({ attachmentsList: this.props.attachmentsList });
        }
    }

    setFormValue(formValue: any, formik: any) {
        this.setState({ formValue: formValue });
    }

    // handleSubmit(formValue: any, formik: any) {
    handleSubmit(formik: any) {
        const { formValue } = this.state;
        // console.log("PRACTICES MODAL FORM formValue:", formValue);

        let cloneFormValue: any = {};
        for (let key in formValue) {
            cloneFormValue[key] = formValue[key];
        }
        
        if (Object.keys(formValue).filter(elem => elem.toLowerCase() === 'id')) delete cloneFormValue.id;
        if (Object.keys(formValue).filter(elem => elem.toLowerCase() === 'to_export')) delete cloneFormValue.to_export;
        const isFormEmpty: boolean = Object.values(cloneFormValue).every(el => el === undefined || el === '');

        if (!isFormEmpty) {
            EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

            const { modalType, initialValues, successAction } = this.props;

            this.setState({ attachmentsToUpload: [], attachmentType: [] });

            // const getFormData = (formValue: any) => Object.keys(formValue).reduce((formData, key) => {
            //     if (formValue[key]) { // per evitare che nell'oggetto finale vengano inseriti anche i campi con valore non definito
            //         formData.append(key, formValue[key]);
            //     };
            //     return formData;
            // }, new FormData());

            const getFormData = (formValue: any) => Object.keys(formValue).reduce((formData, key) => {
                if (formValue[key] !== null && formValue[key] !== undefined) {  // per evitare che vengano inseriti anche i campi con valore non definito
                    if (Array.isArray(formValue[key])) {
                        formValue[key].map((elem: any, i: number) => {
                            formData.append(key+'['+i+']', formValue[key][i]);
                            return null;
                        });
                    } else {
                        formData.append(key, formValue[key]);
                    }
                };
                return formData;
            }, new FormData());

            const getFormValues = (formValue: any) => Object.keys(formValue).reduce((previous, key, index) => {
                if (this.formFields[index] !== undefined) {
                    if (this.formFields[index].type === 'html') {
                        let rightKey = Object.keys(formValue).find(newKey => newKey === this.formFields[index].name) || ''
                        Object.assign(previous, { ...previous, [rightKey]: base64Encode(DomPurify.sanitize(formValue[rightKey])) })
                    }
                    if (this.formFields[index].type === 'multi-select' && this.formFields[index] !instanceof moment) {
                        Object.assign(previous, { ...previous, [key]: formValue[key].map((option: any) => option.value) })
                    } else {
                        // if (this.formFields[index].name !== 'roles')
                        //     Object.assign(previous, { ...previous, [key]: formValue[key] })
                        // else
                        //     Object.assign(previous, { ...previous, [key]: [formValue[key]] })
                        Object.assign(previous, { ...previous, [key]: formValue[key] });
                    }
                }
                return previous
            }, formValue);

            // const formData = this.isFile ? getFormData(formValue) : formValue;
            const formData = this.isFile ? getFormData(formValue) : getFormValues(formValue);

            if (this.props.submitCallback) {
                EventBus.dispatch("hideLoader");
                const resultCallback = this.props.submitCallback(formData, formik);
                if (resultCallback) { this.modal.hide(); }
                return;
            }

            // const apiSubmit = modalType === 'add' ? this.props.apiSubmit(formData) : this.props.apiSubmit(initialValues.id, formValue);
            const apiSubmit = modalType === 'add' ? this.props.apiSubmit(formData) : this.props.apiSubmit(initialValues.id, formData);

            apiSubmit
            .then(async (response: any) => {
                if (response && response.error) {
                    Swal.fire(
                        'Salvataggio non effettuato.',
                        "Si è verificato un errore.",
                        'error'
                    );
                } else {
                    Swal.fire({
                        title: 'Operazione eseguita.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(async () => {
                        successAction ? await successAction(response) : window.location.reload();
                    });
                }
            }, (error: any) => {
                // let respMessage =
                    // (error.response && error.response.data && error.response.data.message) ||
                    // (error.response && error.response.data && error.response.data.error) ||
                    // (error.response && error.response.data && error.response.data.description) ||
                    // (error.response && error.response.data && error.response.data.description) ||
                    // error.message ||
                    // error.toString();

                // const stringyfiedRespMessage = respMessage ? JSON.stringify(respMessage) : "";

                // switch (true) {
                //     case (stringyfiedRespMessage.toLowerCase()).includes("cannot update"):
                //         respMessage = "L'operazione non è consentita.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("duplicate entry"):
                //         respMessage = "I dati sono già stati inseriti (anche se in seguito cancellati).";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes(" name is already in the database"): // non cancellare lo spazio davanti a name
                //         respMessage = "Il nome utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("code is already in the database"):
                //         respMessage = "Il codice utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("phone number is already in the database"):
                //         respMessage = "Il numero di telefono utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("address is already in the database"):
                //         respMessage = "L'indirizzo e-mail utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("username is already in the database"):
                //         respMessage = "Lo username utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("e-mail address is already registered to another customer"):
                //         respMessage = "L'indirizzo e-mail utilizzato è già stato associato ad un altro cliente.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("phone number is already registered to another customer"):
                //         respMessage = "Il numero di telefono utilizzato è già stato associato ad un altro cliente.";
                //         break;
                //     default:
                //         respMessage = "Si è verificato un errore."
                // }

                let respMessage: string = "";

                if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.message) {
                        const errorKeys: string[] = Object.keys(error.response.data.error.message);
        
                        if (errorKeys && errorKeys.length > 0) {
                            errorKeys.map((errorKey: string) => {
                                if (Array.isArray(error.response.data.error.message[errorKey])) {
                                    error.response.data.error.message[errorKey].map((msg: string) => {
                                        respMessage = respMessage === "" ? msg : respMessage + '\n' + msg;
                                        return null;
                                    })
                                } else {
                                    respMessage = respMessage === "" ? 
                                        error.response.data.error.message[errorKey] : respMessage + '\n' + error.response.data.error.message[errorKey];
                                }
                                return null;
                            });
                        };
                    } else if (error.response.data.error.description) {
                        respMessage = error.response.data.error.description;
                    }
                }

                Swal.fire({
                    title: 'Salvataggio non effettuato.',
                    text: respMessage === "" ? 'Si è verificato un errore' : respMessage,
                    icon: 'warning'
                });
            })
            .catch(() => {
                Swal.fire(
                    'Operazione non completata.',
                    'Si è verificato un errore. Non è stato possibile effettuare il salvataggio dei dati.',
                    'error'
                );
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });

            this.setState({ disableCustomerFields: true });
            this.modal.hide();
        } else {
            const messaggio: string = this.props.modalType.toLowerCase() === "add" ? "Compilare almeno un campo." : "Compilare almeno i campi obbligatori." ;
            
            Swal.fire({
                title: 'Salvataggio non effettuato.',
                text: messaggio,
                icon: 'warning',
            });
        }
    }

    handleSearchClick(searchValue: string, allElements: IFormFieldValue[]) {
        this.setState(
            {
                selectedLabel: searchValue
            },
            () => {
                const all = allElements
                if (searchValue !== "") {
                    const filtered = all.filter(
                        (option) => {
                            const value = searchValue.toLowerCase()
                            const name = option.value.toLowerCase();

                            return value && name.includes(value)
                        })
                    this.setState({ filteredBySearch: filtered })
                } else {
                    this.setState({ filteredBySearch: all })
                }

                if ($("#search").is(':focus')) {
                    $('#search').addClass('show')
                    $('#menu').addClass('show')
                }
                else {
                    $('#search').removeClass('show')
                    $('#menu').removeClass('show')
                }
            }
        )
    }

    handleFilesUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const { attachmentsToUpload } = this.state;
        let attachment = (event.currentTarget.files && event.currentTarget.files[0]) ? Array.from(event.currentTarget.files) : [];

        if (attachmentsToUpload.length === 0) {
            this.setState({ attachmentsToUpload: attachment }, () => { event.target.value = "" });
        } else {
            let concatArray = attachment.concat(attachmentsToUpload.reverse());
            attachmentsToUpload.reverse()
            // removing duplicates after merging new and old files
            let attachments = concatArray.reverse().filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.name === value.name &&
                    t.size === value.size &&
                    t.type === value.type &&
                    t.lastModified === value.lastModified
                ))
            )
            this.setState({ attachmentsToUpload: attachments }, () => { event.target.value = "" });
        }
    }

    async downloadAttachment(id: number) {
        EventBus.dispatch("showLoader", { text: 'Download del file in corso...' });

        if (this.props.apiDownload) {
            // await this.props.apiDownload(id);
            // EventBus.dispatch("hideLoader");

            this.props.apiDownload(id)   
            .then(async (response: any) => {
                let urlFile = URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = urlFile;
                a.setAttribute('download', response.headers["content-description"]);
                document.body.appendChild(a);
                a.click();
               
                document.body.removeChild(a);
                URL.revokeObjectURL(urlFile);
            })
            .catch(() => {
                Swal.fire(
                    'Si è verificato un errore',
                    '',
                    'error'
                );
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            })
        }
    }

    async deleteAttachment(id: number, index: number) {
        Swal.fire({
            title: 'Confermi l\'eliminazione dell\'allegato?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        })
        .then(async (result) => {
            if (result.isConfirmed) {
                EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });
        
                let { attachmentsList } = this.state;

                if (this.props.apiDelete) {
                    this.props.apiDelete(id)   
                    .then(async () => {
                        attachmentsList.splice(index, 1);
                        this.setState({ attachmentsList: [...attachmentsList] });
                    })
                    .catch(() => {
                        Swal.fire(
                            'Si è verificato un errore',
                            'L\'allegato non è stato cancellato',
                            'error'
                        );
                    })
                    .finally(() => {
                        EventBus.dispatch("hideLoader");
                    })
                }
            }
        })
    }

    openConfermaOrdine() {
        // compilo l'html con i dati di initialValues
        this.setState({
            documentModalShow: true,
            documentModalType: "confirmation",
            documentModalTitle: 'Conferma d\'ordine',
            documentValues: this.props.initialValues
        });
    }

    openOrdineTrasporto() {
        // compilo l'html con i dati di initialValues
        this.setState({
            documentModalShow: true,
            documentModalType: "transport",
            documentModalTitle: 'Ordine di trasporto',
            documentValues: this.props.initialValues
        });
    }

    validationSchema() {
        let validations: any = {}
        this.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    componentWillUnmount(): void {
        $('.modal-backdrop').remove();
    }

    closeHTMLDocumentModal() {
      this.setState({ documentModalShow: false });
    }

    // renderField(item: IFormField, key: number, formik: FormikProps<any>) {
    renderField(item: IFormField, formik: FormikProps<any>) {
        const { filteredBySearch, selectedLabel = '' } = this.state
        const value = !isNaN(Number(formik.values[item.showFromFieldName ?? ''])) ? Number(formik.values[item.showFromFieldName ?? '']) : formik.values[item.showFromFieldName ?? ''];
        const valueIsPresent: string = (item.showFromFieldName && formik.values[item.showFromFieldName] && formik.values[item.showFromFieldName] > 0) ? 'true' : 'false';

        const displayItem = ((item.showFromFieldName && item.showFromFieldValue?.includes(value)) ||
            (item.showFromFieldName && item.showFromFieldType?.toString().includes(valueIsPresent)) ||
            typeof item.showFromFieldName == 'undefined');
        formik.initialValues[item.name] = formik.initialValues[item.name] ?? item.value;

        if (!displayItem || item.hidden) {
            return null;
        }

        switch (item.type) {
            case 'unset':
                return null
            case 'search':
                return <React.Fragment>
                    <div className="nav-item dropdown">
                        <input
                            id="search"
                            type={item.type}
                            name={item.name}
                            value={selectedLabel}
                            className="form-control w-100"
                            data-bs-toggle="dropdown"
                            placeholder={item.placeholder ?? "Cerca un elemento..."}
                            onChange={event => {
                                item.values && this.handleSearchClick(event.target.value, item.values)
                            }}
                        />

                        <div id="menu" className="dropdown-menu w-100 dropdown-menu-arrow dropdown-menu-card">
                            <div className="search-list">
                                {
                                    filteredBySearch !== undefined && filteredBySearch.length > 0 && filteredBySearch.map(
                                        option =>
                                            <div key={option.key} className="dropdown-item" onClick={async () => {
                                                formik.setFieldValue(item.name, option.key)
                                                this.setState(
                                                    {
                                                        selectedLabel: option.value
                                                    },
                                                    () => item.values && this.handleSearchClick(option.value, item.values)
                                                )
                                            }}>
                                                {option.value}
                                            </div>
                                    )
                                }
                                {
                                    filteredBySearch !== undefined && filteredBySearch.length === 0 && <span className="dropdown-item">Nessun risultato</span>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            case 'select':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Field
                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                            as={item.type}
                            name={item.name}
                            className={item.class}
                            value={formik.values[item.name]}
                            disabled={item.disabled}
                            onChange={async (event: any) => {
                                formik.setFieldValue(item.name, event.target.value)
                                if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                    await item.updateCallback(event.target.value, formik);
                                }
                            }}
                        >
                            <option key={''} value={''}>{item.value ?? '----'}</option>
                            {item.values?.map((item: IFormFieldValue) => {
                                return <option key={item.key} value={item.key} defaultValue={item.defaultValue} disabled={item.disabled}>{item.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>
            case 'multi-select':
                const multiSelectValue = item.options?.filter((option: IFormFieldOption) => {
                    return formik.values[item.name] && formik.values[item.name].find((value: any) => {
                        return value === option.value
                    })
                });

                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Select
                            options={item.options}
                            isMulti={true}
                            name={item.name}
                            defaultValue={multiSelectValue}
                            isDisabled={item.disabled}
                            onChange={async (values: MultiValue<IFormFieldOption>) => {
                                // formik.setFieldValue(item.name, values.length && values.map((item: IFormFieldOption) => item.value));
                                formik.setFieldValue(item.name, values.length ? values.map((item: IFormFieldOption) => item.value) : []);
                                if (
                                    typeof item.updateField !== 'undefined' &&
                                    typeof item.updateCallback !== 'undefined'
                                ) {
                                    await item.updateCallback(values, formik);
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
            case 'search-select':
                const searchSelectValue = item.options?.filter((option: IFormFieldOption) => {
                    return formik.values[item.name] && formik.values[item.name] === option.value
                });

                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Select
                            name={item.name}
                            isSearchable={true}
                            isClearable={true}
                            // isOptionDisabled={(option) => option.disabled ?? false}
                            placeholder={item.placeholder}
                            defaultValue={searchSelectValue}
                            value={item.options?.find(el => el.value === formik.values[item.name])}
                            options={item.options}
                            isDisabled={item.disabled}
                            onChange={async (option: SingleValue<IFormFieldOption>) => {
                                formik.setFieldValue(item.name, option ? option.value : '');
                
                                if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                    await item.updateCallback(option?.value, formik);
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
            case 'file':
                this.isFile = true;
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <input
                            ref={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type={item.type}
                            className={item.class}
                            onChange={(event: any) => {
                                formik.setFieldValue(item.name, event.currentTarget.files[0]);
                            }}
                            title='Select a file'
                            disabled={item.disabled}
                        />
                    </div>
                </React.Fragment>
            case 'files':
                this.isFile = true;
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <input
                            ref={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type='file'
                            multiple={true}
                            className={item.class}
                            onChange={(event: any) => {
                                formik.setFieldValue(item.name, event.currentTarget.files[0]);
                            }}
                            title='Select files'
                            disabled={item.disabled}
                        />
                    </div>
                </React.Fragment>
            case 'attachment':
                const { attachmentsToUpload, inputDisabled } = this.state;
                this.isFile = true;

                const fileTypeName: string[] = [];
                if (this.state.attachmentType.length > 0 && this.state.fileTypeList.length > 0) {
                    this.state.attachmentType.map((item: number) => {
                        this.state.fileTypeList.map((fileType: IFormFieldOption) => {
                            if (fileType.value === item) fileTypeName.push(fileType.label);
                            return null;
                        })
                        return null;
                    });
                }

                return (
                    <React.Fragment>
                        <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                        
                        <div>
                            <Select
                                id="category-select"
                                className="basic-single"
                                classNamePrefix="select"
                                // defaultValue={this.state.fileTypeList[0]}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={false}
                                placeholder={"Seleziona la tipologia dell'allegato"}
                                name="file-type"
                                options={this.state.fileTypeList}
                                ref={ref => {
                                    this.selectRef = ref;
                                }}
                                onChange={async (event: any) => {
                                    if (event && event.value) {
                                        if (attachmentsToUpload.length !== this.attachmentTypeTemp.length) // per gestire più cambiamenti consecutivi della select
                                            this.attachmentTypeTemp.splice(this.attachmentTypeTemp.length-1, 1);
                                        this.attachmentTypeTemp.push(event.value);
                                        this.setState({ inputDisabled: false });
                                    } else { // per gestire la cancellazione del contenuto della select
                                        if (attachmentsToUpload.length !== this.attachmentTypeTemp.length) { // eseguito solo dopo la cancellazione manuale (non attraverso il clearValue) della select
                                            this.attachmentTypeTemp.splice(this.attachmentTypeTemp.length-1, 1);
                                            this.setState({ inputDisabled: true });
                                        }
                                    }
                                }}
                            />
                            <input
                                id={`${item.name}`}
                                ref={(el: any) => {
                                    this.innerRefs[item.name] = el;
                                }}
                                name={item.name}
                                type='file'
                                className={item.class}
                                onChange={(event: any) => {
                                    this.handleFilesUpload(event);
                                    this.setState({
                                        attachmentType: this.attachmentTypeTemp,
                                        inputDisabled: true
                                    }, () => {
                                        formik.setFieldValue(item.name, this.state.attachmentsToUpload);
                                        formik.setFieldValue("file_type_ids", this.state.attachmentType);
                                        this.selectRef.clearValue();
                                    });
                                }}
                                placeholder={"Carica un allegato"}
                                title={"Selezionare una tipologia prima di caricare l'allegato!"}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                data-trigger="hover"
                                disabled={inputDisabled}
                            />
                        </div>
                        
                        <div className="mt-18">
                            <h4>Allegati da caricare</h4>
                            {
                                attachmentsToUpload.length > 0 ?
                                <div>
                                    {
                                        attachmentsToUpload.map((file: any, index: number) => (
                                            <div className='d-flex align-items-center mb-1'>
                                                <button
                                                    style={buttonsStyle}
                                                    className='btn btn-outline-danger rounded-circle me-2'
                                                    type='button'
                                                    key={`file_${index}`}
                                                    // disabled={this.state.disabledForm}
                                                    onClick={() => {
                                                        let files = attachmentsToUpload;
                                                        const index = files.indexOf(file);
                                                        files.splice(index, 1);

                                                        let types = this.state.attachmentType;
                                                        types.splice(index, 1);

                                                        this.setState({
                                                            attachmentsToUpload: files,
                                                            attachmentType: types
                                                        }, () => {
                                                            formik.setFieldValue(item.name, this.state.attachmentsToUpload);
                                                            formik.setFieldValue("file_type_ids", this.state.attachmentType);
                                                        });
                                                    }}
                                                >
                                                    <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                                </button>
                                                <span key={index + '_' + Date.now()}>
                                                    <b>Allegato: </b>{file.name}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <b>Tipologia: </b>{fileTypeName[index]}
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div> : <p className='mt-3'> Non ci sono allegati da caricare. </p>
                            }
                        </div>

                        {
                            this.props.modalType === "edit" &&
                            <div className="mt-18">
                                <h4>Allegati caricati</h4>
                                {
                                    (this.state.attachmentsList.length !== 0 && this.state.fileTypeList.length > 0) ? 
                                        this.state.attachmentsList.map((file: IFile, index: number) => (
                                            <div className='d-flex align-items-center mb-1' key={index + '_' + Date.now()}>
                                                <button type='button' className='custom-icon btn topic-empty-btn-color rounded-circle me-2'
                                                    title="Scarica il file" style={buttonsStyle} onClick={() => this.downloadAttachment(file.id)}
                                                >
                                                    <i style={iconsStyle} className="fa fa-download" aria-hidden="true"></i>
                                                </button>
                                                <button type='button' className='btn btn-outline-danger rounded-circle me-2' //disabled={this.state.disabledForm}
                                                    title="Elimina il file" style={buttonsStyle} onClick={() => this.deleteAttachment(file.id, index)}
                                                >
                                                    <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                                </button>
                                                <span key={index + '_' + Date.now()}>
                                                    <b>Allegato: </b>{file.client_name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <b>Tipologia: </b>{this.state.fileTypeList[file.file_type.id-1].label}
                                                </span>
                                            </div>
                                    )) : <p className='mt-3'> Non sono stati caricati allegati. </p>
                                }
                            </div>
                        }
                    </React.Fragment>
                )    
            case 'radio':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        {item.values?.map((value: IFormFieldValue, key: number) => {
                            return <label key={value.key}>
                                <Field
                                    type="radio"
                                    name={item.name}
                                    value={value.key}
                                    disabled={item.disabled}
                                />
                                {value.value}
                            </label>
                        })}
                    </div>
                </React.Fragment>
            case 'checkbox':
                return displayItem && <label className={"form-check form-switch" + (item.labelClass ? ' ' + item.labelClass : '')}>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        name={item.name}
                        type={item.type}
                        className={item.class}
                        disabled={item.disabled}
                    />
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                </label>
            case 'number':
                return displayItem && <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        type={item.type}
                        name={item.name}
                        className={item.class}
                        min={item.min}
                        max={item.max}
                        disabled={item.disabled}
                        onChange={async (event: any) => {
                            formik.setFieldValue(item.name, event.target.value); //Number(event.target.value)
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.target.value, formik);
                            }
                        }}
                    />
                </label>
            case 'time':
                return displayItem && <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                    <div className='row'>
                        <div className='col'>
                            <Field
                                innerRef={(el: any) => this.innerRefs[item.name + '_hh'] = el}
                                type={'number'}
                                name={item.name + '_hh'}
                                className={item.class}
                                min={0}
                                max={23}
                                disabled={item.disabled}
                                onChange={async (event: any) => {
                                    formik.setFieldValue(item.name + '_hh', event.target.value)
                                    if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                        await item.updateCallback(event.target.value, formik);
                                    }
                                }}
                            />
                        </div>
                        <div className='col'>
                            <Field
                                innerRef={(el: any) => this.innerRefs[item.name + '_mm'] = el}
                                as={item.minuteType === 'select' ? 'select' : 'input'}
                                type={item.minuteType !== 'select' ? 'number' : undefined}
                                name={item.name + '_mm'}
                                className={item.class}
                                min={0}
                                max={59}
                                disabled={item.disabled}
                                onChange={async (event: any) => {
                                    formik.setFieldValue(item.name + '_mm', event.target.value)
                                    if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                        await item.updateCallback(event.target.value, formik);
                                    }
                                }}
                            >
                                {item.values?.map((item: IFormFieldValue) => {
                                    return <option key={item.key} value={item.key}>{item.value}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                </label>
            case 'datetime':
                return <>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>
                        {item.label}
                    </label>
                    <DatePickerForm
                        name={item.name}
                        className={item.class}
                        dataFormat={'dd/MM/yyyy HH:mm'}
                        readValue={formik.values[item.name]}
                        valueFormat={'yyyy-MM-DDTHH:mm:ssZ'}
                        shouldCloseOnSelect
                        showTimeSelect
                        timeIntervals={15}
                        timeFormat='HH:mm'
                        disabled={item.disabled}
                        onChange={async (event) => {
                            formik.setFieldValue(item.name, event.value);

                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.value, formik);
                            }
                        }}
                    />
                </>
            case 'textarea':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        name={item.name}
                        as={item.type}
                        className={item.class}
                        rows="5"
                        disabled={item.disabled}
                        onChange={async (event: any) => {
                            formik.setFieldValue(item.name, event.target.value)
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.value, formik);
                            }
                        }}
                    />
                </React.Fragment>
            case 'html':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        name={item.name}
                        as={'textarea'}
                        className={item.class}
                        rows="5"
                        disabled={item.disabled}
                    />
                    {
                        formik.values[item.name] && <div className='mt-2'>
                            <h4>Anteprima del risultato</h4>
                            <div className='border border-1 p-3' dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(formik.values[item.name]) }}></div>
                        </div>
                    }
                </React.Fragment>
            default:
                //return <Field innerRef={(el: any) => this.innerRefs[item.name] = el} name={item.name} type={item.type} className={item.class} />
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Field
                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type={item.type}
                            className={item.class}
                            value={formik.values[item.name] ?? ''}
                            disabled={item.disabled}
                            onChange={async (event: any) => {
                                formik.setFieldValue(item.name, event.target.value)
                                if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                    await item.updateCallback(event.target.value, formik);
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
        }
    }

    render() {
        const { id = "practices-modal-form", modalType, modalTitle, initialValues, saveButtonName } = this.props;
        const { showModal, disableCustomerFields, documentModalShow, documentModalType, documentModalTitle, documentValues } = this.state;

        return showModal && <React.Fragment>

            <ModalHTMLDocument
                documentValues={documentValues}
                showModal={documentModalShow}
                modalTitle={documentModalTitle}
                modalType={documentModalType}
                closeDocumentCallback={this.closeHTMLDocumentModal.bind(this)}
            />

            <div id={id} className="modal modal-blur fade" role="dialog" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                {/* <div className="modal-dialog modal-lg modal-dialog-centered" role="document"> */}
                <div className="modal-dialog modal-dialog-centered" style={{'maxWidth': '90%'}} role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="topic-empty-btn-color btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => { this.setState({ selectedLabel: '', disableCustomerFields: true }) }}
                        ></button>
                        <div className="modal-body">
                            <h3>
                                {modalTitle}
                                {
                                    this.subtitle !== '' && <span className='ms-1'>
                                        figlia di {initialValues.parentJoborder}
                                    </span>
                                }
                            </h3>
                            <Formik
                                initialValues={this.initialValues}
                                validationSchema={this.validationSchema.bind(this)}
                                onSubmit={this.setFormValue.bind(this)} // {this.handleSubmit.bind(this)}
                            >
                                {(formik: FormikProps<{[key: string]: any;}>) => {
                                    return <Form>
                                        {
                                            this.formFields && modalType === "add" && <div>  
                                                <div className='row'>
                                                    <div className='col-2'>
                                                        {this.renderField(this.formFields[0], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[0].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                    <div className='col-2'>
                                                        {this.renderField(this.formFields[1], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[2], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[3], formik)}
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[4], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[5], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[6], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[7], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[8], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[9], formik)}
                                                    </div>
                                                </div>

                                                <div className='customer-section-border'>
                                                    <label style={{'fontWeight': '500'}}>Dati cliente</label>
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            {this.renderField(this.formFields[10], formik)}
                                                        </div>
                                                        <div className='col-3'>
                                                            {this.renderField(this.formFields[11], formik)}
                                                        </div>
                                                        <div className='col-3'>
                                                            {this.renderField(this.formFields[12], formik)}
                                                        </div>
                                                        <div className='col-3'>
                                                            {this.renderField(this.formFields[13], formik)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[14], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[15], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[16], formik)}
                                                    </div>
                                                </div>
                                            
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {this.renderField(this.formFields[17], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[17].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>
                                            
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {this.renderField(this.formFields[18], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[18].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {this.renderField(this.formFields[19], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[19].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        {this.renderField(this.formFields[20], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[20].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                    <div className='col-6'>
                                                        {this.renderField(this.formFields[21], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[22].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[22], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[23], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[24], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[25], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[26], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[27], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[28], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[29], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[30], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[31], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[32], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6'>
                                                        {this.renderField(this.formFields[33], formik)}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.formFields && modalType !== "add" && <div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[0], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[0].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[1], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[1].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[2], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[3], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[4], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[5], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[6], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[7], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[8], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[9], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[10], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[11], formik)}
                                                    </div>
                                                </div>

                                                <div className='customer-section-border'>
                                                    <label style={{'fontWeight': '500'}}>Dati cliente</label>
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            {/* {this.renderField(this.formFields[12], formik)} */}

                                                            <label className={"form-label col-form-label"}>Nome</label>
                                                            <div className="col">
                                                                <Field
                                                                    innerRef={(el: any) => this.innerRefs['first_name'] = el}
                                                                    name={'first_name'}
                                                                    type={'text'}
                                                                    className={'form-control'}
                                                                    value={formik.values['first_name'] ?? ''}
                                                                    disabled={this.state.disableCustomerFields}
                                                                    onChange={async (event: any) => {formik.setFieldValue('first_name', event.target.value)}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            {/* {this.renderField(this.formFields[13], formik)} */}

                                                            <label className={"form-label col-form-label"}>Cognome</label>
                                                            <div className="col">
                                                                <Field
                                                                    innerRef={(el: any) => this.innerRefs['last_name'] = el}
                                                                    name={'last_name'}
                                                                    type={'text'}
                                                                    className={'form-control'}
                                                                    value={formik.values['last_name'] ?? ''}
                                                                    disabled={this.state.disableCustomerFields}
                                                                    onChange={async (event: any) => {formik.setFieldValue('last_name', event.target.value)}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            {/* {this.renderField(this.formFields[14], formik)} */}

                                                            <label className={"form-label col-form-label"}>E-mail</label>
                                                            <div className="col">
                                                                <Field
                                                                    innerRef={(el: any) => this.innerRefs['email_address'] = el}
                                                                    name={'email_address'}
                                                                    type={'text'}
                                                                    className={'form-control'}
                                                                    value={formik.values['email_address'] ?? ''}
                                                                    disabled={this.state.disableCustomerFields}
                                                                    onChange={async (event: any) => {formik.setFieldValue('email_address', event.target.value)}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-2'>
                                                            {/* {this.renderField(this.formFields[15], formik)} */}

                                                            <label className={"form-label col-form-label"}>Telefono</label>
                                                            <div className="col">
                                                                <Field
                                                                    innerRef={(el: any) => this.innerRefs['phone_number'] = el}
                                                                    name={'phone_number'}
                                                                    type={'text'}
                                                                    className={'form-control'}
                                                                    value={formik.values['phone_number'] ?? ''}
                                                                    disabled={this.state.disableCustomerFields}
                                                                    onChange={async (event: any) => {formik.setFieldValue('phone_number', event.target.value)}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-1 lock-icon'>
                                                            <button style={buttonsStyle} className='btn rounded-circle' type='button'
                                                                onClick={() => this.setState({ disableCustomerFields: !disableCustomerFields }, () => {
                                                                    this.props.practicesUpdateCallback(this.state.disableCustomerFields);
                                                                })}
                                                            >
                                                                {
                                                                    disableCustomerFields ?
                                                                        <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                                        <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[16], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[17], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[18], formik)}
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {this.renderField(this.formFields[19], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[19].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {this.renderField(this.formFields[20], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[20].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        {this.renderField(this.formFields[21], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[21].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6'>
                                                        {this.renderField(this.formFields[22], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[22].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                    <div className='col-6'>
                                                        {this.renderField(this.formFields[23], formik)}
                                                        <ErrorMessage
                                                            name={this.formFields[23].name}
                                                            component="div"
                                                            className="alert alert-danger"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[24], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[25], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[26], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[27], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[28], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[29], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[30], formik)}
                                                    </div>
                                                    <div className='col-3'>
                                                        {this.renderField(this.formFields[31], formik)}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[32], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[33], formik)}
                                                    </div>
                                                    <div className='col-4'>
                                                        {this.renderField(this.formFields[34], formik)}
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        {this.renderField(this.formFields[35], formik)}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='row practices-form-btn'>
                                            <div className='col-12 col-sm-6 justify-content-center'>
                                                <button type="submit" className="btn topic-empty-btn-color" onClick={() => this.openConfermaOrdine()}>
                                                    <span>{saveButtonName ? saveButtonName : 'Conferma ordine'}</span>
                                                </button>
                                            </div>
                                            <div className='col-12 col-sm-6 justify-content-center'>
                                                <button type="submit" className="btn topic-empty-btn-color" onClick={() => this.openOrdineTrasporto()}>
                                                    <span>{saveButtonName ? saveButtonName : 'Ordine trasporto'}</span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn topic-full-btn-color mt-30" onClick={() => {
                                                setTimeout(() => this.handleSubmit(formik), 300)
                                            }}>
                                                <span>{saveButtonName ? saveButtonName : 'Salva'}</span>
                                            </button>
                                        </div>
                                    </Form>
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default PracticesModalForm;
