import { ConfigColumns } from "datatables.net";
import { ICustomerRow } from "../../helpers/interfaces/customer";

export const listCustomersColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    {
        title: "Nome", "data": null, render: (data: any, type: any, row: ICustomerRow) => {
            const firstName = row.first_name ?? '';
            const lastName = row.last_name ?? '';
            return firstName + ' ' + lastName;
        }
    },
    { title: "Numero di telefono", data: "phone_number" },
    { title: "Indirizzo e-mail", data: "email_address" },
    { title: "Data", data: "date" },
    { title: "Broadcast", data: "broadcast" },
    {
        title: "Commerciale", "data": null, render: (data: any, type: any, row: ICustomerRow) => {
            const commercial = row.commercial && row.commercial.fullname ? row.commercial.fullname : null;
            return commercial;
        }
    },
    { title: "Seleziona", data: "isChecked" }
];

// export default function getListCustomersColumnDefs(): ConfigColumnDefs[] {
export default function getListCustomersColumnDefs(): any[] {
    return [
        {
            target: 0,
            searchable: false,
            orderable: false,
            width: "8%",
            render: (id: number, type: string, row: ICustomerRow) => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${id}" title="Modifica dettagli cliente">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}" title="Elimina cliente">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + trash + '</div>';
            },
        },
        {
            target: 1,
            width: "20%"
        },
        {
            target: 2,
            width: "10%"
        },
        {
            target: listCustomersColumns.length - 1,
            searchable: false,
            orderable: false,
            width: "5%",
            class: "text-align-center",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: ICustomerRow): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox" class="checkbox-style"' + (isChecked && 'checked') + ' data-id="' + row.id + '" />';

                return '<span>' + checkbox + '</span>'
            }
        }
    ];
}