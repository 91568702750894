import React from 'react';
import GestionePraticheService from '../../services/api/gestione-pratiche.service';
import CategoriePraticheService from '../../services/api/categorie-pratiche.service';
import CategorieRicambioService from '../../services/api/categorie-ricambio.service';
import TipologieAllegatiService from '../../services/api/tipologie-allegati.service';
import GestioneAllegatiService from '../../services/api/gestione-allegati.service';
import GestioneUtentiService from '../../services/api/gestione-utenti.service';
// import GestioneClientiService from '../../services/api/gestione-clienti.service';
import PracticesModalForm from '../../common/PracticesModalForm';
import InfiniteScroll from "react-infinite-scroll-component";
import Table from '../../common/TableComponent';
import { Tab, TabProp } from "../../common/TabComponent";
import getListPracticesColumnDefs, { listPracticesColumns } from '../../config/table-columns/gestione-pratiche';
import { addPraticheFormFields, editPraticheFormFields } from '../../config/form-fields/gestione-pratiche';
import { IPractice, IPracticeRow, IFile, IMultipleDelete } from '../../helpers/interfaces/practices';
import { IPartCategory, IPartCategoryRow, IPracticeCategory, IPracticeCategoryRow, IChild } from '../../helpers/interfaces/categories';
import { IFileType } from '../../helpers/interfaces/file-types';
import { IUser, IRole, ILoggedUser } from '../../helpers/interfaces/user';
// import { ICustomerList, ICustomerListRow } from '../../helpers/interfaces/customer';
import { IFormField, IFormFieldOption, IFormFieldValue } from '../../helpers/interfaces/generic';
import Swal from "sweetalert2";
import EventBus from '../../common/EventBus';
import Select from 'react-select';
import * as Yup from "yup";

const $ = require('jquery')

type Props = {}

type State = {
    formFields: IFormField[];
    formInitialValues: {};
    showModal: boolean;
    modalType: string;
    modalTitle: string;
    practicesList: IPracticeRow[];
    practicesNumber: number;
    practiceCategories: IFormFieldValue[];
    partCategories: IFormFieldValue[];
    operatorsList: IFormFieldValue[];
    commercialsList: IFormFieldValue[];
    customersList: IFormFieldOption[];
    fileTypes: IFormFieldOption[];
    practicesAttachments: Array<IFile>;
    selectedItems: Array<number>;
    moveToCategory: any;
    moveToCommercial: any;
    myPractice: boolean;
    allChecked: boolean;
    hasPermissionsAdmin: boolean;
    hasPermissions2: boolean;
    hasPermissionsInsert: boolean;
    hasPermissionsResearch: boolean;
    hasPermissionsEmpty: boolean;
    itemsToShow: number;
    hasMore: boolean;
    categoriesTabs: TabProp[];
    tabKey: string[]; // etichette del TAB
    tabItemsNumber: number[]; // numero di pratiche nel TAB (pratiche per categoria)
    categoryId: number[]; // id delle categorie
    currentTab: number;
    moveSelected: boolean;
    deleteSelected: boolean;
    sort: string;
    order: string;
    filterObj: {
        categoryId: number,
        partCategoryId: number,
        operatorId: number
        commercialId: number,
        affiliateId: number
    },
    searchText: string;
    disableCustomerForm: boolean
}

export default class GestionePratiche extends React.Component<Props, State> {

    apiSubmit: any;
    defaultLimit: number = 25;
    defaultOffset: number = 0;
    defaultTab: number = 0;
    isSearchTab: boolean = false;
    searchTabTitle: string = '';
    isCategoryExportDisabled: boolean = false;
    selectRef1: any;
    selectRef2: any;
    selectRef3: any;
    selectRef4: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            practicesList: [],
            practicesNumber: 0,
            practiceCategories: [],
            partCategories: [],
            operatorsList: [],
            commercialsList: [],
            customersList: [],
            fileTypes: [],
            practicesAttachments: [],
            selectedItems: [],
            moveToCategory: "",
            moveToCommercial: "",
            myPractice: false,
            allChecked: false,
            hasPermissionsAdmin: false,
            hasPermissions2: false,
            hasPermissionsInsert: false,
            hasPermissionsResearch: false,
            hasPermissionsEmpty: false,
            itemsToShow: this.defaultLimit,
            hasMore: false,
            categoriesTabs: [],
            tabKey: [],
            tabItemsNumber: [],
            categoryId: [],
            currentTab: 1, // INIT TAB
            moveSelected: true,
            deleteSelected: true,
            sort: '',
            order: '',
            filterObj: {
                categoryId: 0,
                partCategoryId: 0,
                operatorId: 0,
                commercialId: 0,
                affiliateId: 0
            },
            searchText: '',
            disableCustomerForm: true
        }

        this.selectRef1 = null;
        this.selectRef2 = null;
        this.selectRef3 = null;
        this.selectRef4 = null;
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const userString: any = localStorage.getItem("user");
        const currentUser: ILoggedUser = JSON.parse(userString);

        currentUser.roles.map((role: IRole) => {
            if (role.code.toLocaleLowerCase() === "adm") this.setState({ hasPermissionsAdmin: true });
            // if (role.code.toLocaleLowerCase() === "adm" || role.code.toLocaleLowerCase() === "cmm") this.setState({hasPermissions2: true});
            this.setState({ hasPermissions2: true });
            return null;
        })

        let insert: boolean = false;
        let research: boolean = false;
        let empty: boolean = false;
        currentUser.actions.map((action: string) => {
            if (action.toLowerCase() === "ins") insert = true;
            if (action.toLowerCase() === "ric") research = true;
            if (action.toLowerCase() === "eep") empty = true;
            return null;
        });

        this.setState({ hasPermissionsInsert: insert, hasPermissionsResearch: research, hasPermissionsEmpty: empty });

        Promise.all([
            // GestionePraticheService.getPractices(this.defaultLimit, this.defaultOffset),
            CategoriePraticheService.getPracticeCategories(),
            CategorieRicambioService.getPartCategories(),
            TipologieAllegatiService.getFileTypes(),
            GestioneUtentiService.getUsers(),
            // GestioneClientiService.getCustomersAll()
        ]).then(async (data: [
            // IPractice,
            IPracticeCategory,
            IPartCategory,
            IFileType[],
            IUser[],
            // ICustomerList
        ]) => {
            // la chiamata a tutte le pratiche (di tutte le categorie) serve?
            // data[0].rows.map((row: IPracticeRow) => row.isChecked = false );
            // this.setState({ practicesList: data[0].rows, practicesNumber: data[0].total_rows });

            // let practicesAttachments: Array<IFile> = [];
            // data[0].rows.map((practice: IPracticeRow) => {
            //   if (practice.files && practice.files.length > 0) {
            //     practice.files.map((attachment: IFile) => practicesAttachments.push(attachment));
            //   }
            //   return null;
            // });
            // this.setState({ practicesAttachments });

            if (data[0]?.rows && data[0].rows.length > 0) {
                let categoriesList: IPracticeCategoryRow[] = [];
                let tabKey: string[] = ['Da esportare'];
                let tabItemsNumber: number[] = [0]; // il primo valore è il numero di pratiche 'da esportare', viene aggiornato in seguito
                let categoryId: number[] = [-1]; // il primo tab contiene la categoria 'da esportare'

                data[0].rows.map((category: IPracticeCategoryRow) => {
                    categoriesList.push(category);
                    tabKey.push(category.name + ' (' + category.count + ')');
                    categoryId.push(category.id);
                    tabItemsNumber.push(category.count);

                    if (category.childrens.length > 0) {
                        category.childrens.map((child: IChild) => {
                            const elem: any = {
                                id: child.id,
                                code: child.code,
                                name: '--- ' + child.name,
                                parent: category.name,
                                childrens: []
                            }
                            categoriesList.push(elem);
                            return null;
                        });
                    }
                    return null;
                });

                this.setState({ tabKey, categoryId, tabItemsNumber }, () => {
                    this.getPracticesPerCategory(this.state.categoryId[1]); // INIT TAB
                });

                let practiceCategories: IFormFieldValue[] = categoriesList.map((category: IPartCategoryRow) => { return { key: category.id, value: category.name } });
                // practiceCategories = practiceCategories.sort((itemA: any, itemB: any) => itemA.value.localeCompare(itemB.value));

                this.setState({ practiceCategories });
            }

            if (data[1]?.rows && data[1].rows.length > 0) {
                let partCategories: IFormFieldValue[] = data[1].rows.map((category: IPartCategoryRow) => { return { key: category.id, value: category.name } });
                partCategories = partCategories.sort((itemA: any, itemB: any) => itemA.value.localeCompare(itemB.value));
                this.setState({ partCategories });
            }

            if (data[2] && data[2].length > 0) {
                const fileTypes: IFormFieldOption[] = data[2].map((type: IFileType) => { return { value: type.id, label: type.name + ' (' + type.code + ')' } });
                this.setState({ fileTypes });
            }

            let operatorsList: IFormFieldValue[] = [];
            let commercialsList: IFormFieldValue[] = [];
            data[3].map((user: IUser) => {
                user.roles.map((role: IRole) => {
                    if (role.code.toLowerCase() === "opt") {
                        const obj = { key: user.id, value: user.first_name + ' ' + user.last_name };
                        operatorsList.push(obj);
                    };
                    if (role.code.toLowerCase() === "cmm") {
                        const obj = { key: user.id, value: user.first_name + ' ' + user.last_name };
                        commercialsList.push(obj);
                    };
                    return null;
                });
                return null;
            });

            operatorsList = operatorsList.sort((itemA: any, itemB: any) => itemA.value.localeCompare(itemB.value));
            commercialsList = commercialsList.sort((itemA: any, itemB: any) => itemA.value.localeCompare(itemB.value));
            this.setState({ operatorsList, commercialsList });

            // se nei form serve la select con l'elenco dei clienti
            // if (data[4] && data[4].rows && data[4].rows.length > 0) {
            //     let customersList: IFormFieldOption[] = data[4].rows.map((customer: ICustomerListRow) => { return { value: customer.id, label: customer.full_name }});

            //     // customersList = customersList.sort((itemA: any, itemB: any) => itemA.label.localeCompare(itemB.label));
            //     customersList.sort((a, b) => a.label > b.label ? 1 : -1)
            //     this.setState({ customersList });
            // }
        })
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore.',
                    text: 'Non è stato possibile recuperare i dati.',
                    icon: 'error'
                });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });

        $('#practices-container').on('change', '#select-my-practice', async (event: any) => {
            event.preventDefault();

            const { myPractice } = this.state;
            const myPracticeValue: boolean = !myPractice;

            const { categoryId, currentTab, sort, order } = this.state;

            //this.setState({ itemsToShow: this.defaultLimit, hasMore: false, selectedItems: [], allChecked: false });
    
            this.setState({ myPractice: myPracticeValue }, () => {
                this.getPracticesPerCategory(categoryId[currentTab], sort, order, myPracticeValue);                
            });
        });

        $('#practices-container').on('change', 'input[type="checkbox"]', async (event: any) => {
            event.preventDefault();

            const checkboxId = $(event.currentTarget).attr('id');
            if (checkboxId === 'select-my-practice') {
            return;
            }

            const practiceId = $(event.currentTarget).data('id');

            if (practiceId) {
                const { selectedItems } = this.state;

                let found: boolean = false;
                selectedItems.map((item: number, index: number) => {
                    if (item === practiceId) {
                        selectedItems.splice(index, 1);
                        found = true;
                    };
                    return null;
                });
                if (!found) selectedItems.push(practiceId);

                this.setState({ selectedItems }, () => {
                    this.state.practicesList.map((practice: IPracticeRow) => practice.isChecked = false);
                    this.state.practicesList.map((practice: IPracticeRow) => {
                        this.state.selectedItems.map((id: number) => {
                            if (practice.id === id) practice.isChecked = true;
                            return null;
                        })
                        return null;
                    });

                    this.setState({
                        moveSelected: !(this.state.selectedItems.length !== 0 && (this.state.moveToCategory.length !== 0 || this.state.moveToCommercial.length !== 0)),
                        deleteSelected: !(this.state.selectedItems.length !== 0)
                    });
                });
            } else {
                const { allChecked } = this.state;
                const areAllChecked: boolean = !allChecked;
                const checkedItems: Array<any> = [];

                let { practicesList } = this.state;
                if (areAllChecked) {
                    practicesList.map((practice: IPracticeRow) => {
                        practice.isChecked = true;
                        checkedItems.push(practice.id);
                        return null;
                    });
                } else {
                    practicesList.map((practice: IPracticeRow) => {
                        practice.isChecked = false;
                        return null;
                    });
                }

                // non modificare practicesList: [...practicesList] serve per l'aggiornamento della tabella, in particolare delle checkbox
                this.setState({ practicesList: [...practicesList], selectedItems: checkedItems, allChecked: areAllChecked }, () => {
                    this.setState({
                        moveSelected: !(this.state.selectedItems.length !== 0 && this.state.moveToCategory.length !== 0),
                        deleteSelected: !(this.state.selectedItems.length !== 0)
                    });
                });
            }
        });

        $('#practices-container').on('click', '.edit_btn', async (event: any) => {
            event.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const praticaId = $(event.currentTarget).data('id');
            const praticaDettagli: IPracticeRow = await GestionePraticheService.getPractice(praticaId);
            EventBus.dispatch("hideLoader");

            if (praticaDettagli) {
                this.setState({
                    practicesAttachments: praticaDettagli.files,
                    formFields: editPraticheFormFields(
                        this.state.operatorsList,
                        this.state.practiceCategories,
                        this.state.partCategories,
                        this.state.commercialsList,
                        // this.state.disableCustomerForm
                    ),
                    formInitialValues: {
                        // ...praticaDettagli,
                        // to_export: praticaDettagli.to_export ? 1 : 0,
                        // operator: praticaDettagli.operator?.id,
                        // category_id: praticaDettagli.category.id,
                        // part_category: praticaDettagli.part_category?.id,
                        // commercial: praticaDettagli.commercial?.id,
                        // customer_id: praticaDettagli.customer?.id,
                        // first_name: praticaDettagli.customer?.first_name,
                        // last_name: praticaDettagli.customer?.last_name,
                        // email_address: praticaDettagli.customer?.email_address,
                        // phone_number: praticaDettagli.customer?.phone_number

                        id: praticaDettagli.id,
                        increment_number: praticaDettagli.increment_number,
                        operator: praticaDettagli.operator?.id,
                        date: praticaDettagli.date,
                        source: praticaDettagli.source,
                        part_category: praticaDettagli.part_category?.id,
                        category_id: praticaDettagli.category.id,
                        part_required: praticaDettagli.part_required,
                        engine_code: praticaDettagli.engine_code,
                        vehicle: praticaDettagli.vehicle,
                        vehicle_year: praticaDettagli.vehicle_year,
                        engine_capacity: praticaDettagli.engine_capacity,
                        vehicle_supply: praticaDettagli.vehicle_supply,
                        customer_id: praticaDettagli.customer?.id,
                        first_name: praticaDettagli.customer?.first_name,
                        last_name: praticaDettagli.customer?.last_name,
                        email_address: praticaDettagli.customer?.email_address,
                        phone_number: praticaDettagli.customer?.phone_number,
                        discount_code: praticaDettagli.discount_code,
                        province: praticaDettagli.province,
                        commercial: praticaDettagli.commercial?.id,
                        affiliate: praticaDettagli.affiliate?.id,
                        part_notes: praticaDettagli.part_notes,
                        replies: praticaDettagli.replies,
                        informations: praticaDettagli.informations,
                        shipping: praticaDettagli.shipping,
                        billing: praticaDettagli.billing,
                        confirmation_date: praticaDettagli.confirmation_date,
                        deposit: praticaDettagli.deposit,
                        payment_type: praticaDettagli.payment_type,
                        countersign: praticaDettagli.countersign,
                        bank_transfer: praticaDettagli.bank_transfer,
                        shipping_costs: praticaDettagli.shipping_costs,
                        payment_mean: praticaDettagli.payment_mean,
                        confirmation_number: praticaDettagli.confirmation_number,
                        shipping_date: praticaDettagli.shipping_date,
                        wrecker: praticaDettagli.wrecker,
                        invoice_number: praticaDettagli.invoice_number,
                        to_export: praticaDettagli.to_export ? 1 : 0,
                        created_at: praticaDettagli.created_at,
                        updated_at: praticaDettagli.updated_at,
                        files: praticaDettagli.files
                    }
                }, () => {
                    this.apiSubmit = GestionePraticheService.editPractice;
                    this.setState({
                        showModal: true,
                        modalType: 'edit',
                        modalTitle: 'Modifica i dati della pratica'
                    });
                });
            }
        });

        /* il tasto delete presente in ogni riga della tabella è stato nascosto
        $('#practices-container').on('click', '.delete_btn', async (event: any) => {
            event.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const idPratica = $(event.currentTarget).data('id');
                    const pratica: any = await GestionePraticheService.deletePractice(idPratica);
                    if (pratica && typeof pratica.error !== 'undefined') {
                        Swal.fire(
                            pratica.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            })
            .catch((error: any) => {
                if (error.response.data.error.description.includes("cannot delete a practice that has associated files")) {
                    Swal.fire({
                        title: 'Attenzione!',
                        text: "La pratica non può essere cancellata perché ha documenti allegati.",
                        icon: 'warning'
                    });
                } else {
                    Swal.fire({
                        title: 'Si è verificato un errore',
                        text: 'La pratica non è stata cancellata.',
                        icon: 'error'
                    });
                }
            });
        }); */

        // disabilita il tasto invia della tastiera nel text input della ricerca
        $('#page-container').keypress(function (event: any) {
            // console.log("TASTO:", event.which);
            if (event.which === 13) {
                // console.log("TASTO INVIO");
                return false;
            }
        });
    }

    async openAddModale() {
        this.setState({
            // formFields: addPraticheFormFields(this.state.partCategories, this.state.customersList, this.state.commercialsList),
            formFields: addPraticheFormFields(this.state.partCategories, this.state.commercialsList),
            formInitialValues: { to_export: 0 }
        }, () => {
            this.apiSubmit = GestionePraticheService.addPractice;
            this.setState({
                showModal: true,
                modalType: 'add',
                modalTitle: 'Crea una nuova pratica'
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    // selectAllItems() {
    //     const { allChecked, practicesList } = this.state;
    //     const areAllChecked: boolean = !allChecked;
    //     const checkedItems: Array<any> = [];

    //     if (areAllChecked) {
    //         practicesList.map((practice: IPracticeRow) => {
    //             practice.isChecked = true;
    //             checkedItems.push(practice.id);
    //             return null;
    //         });
    //     } else {
    //         practicesList.map((practice: IPracticeRow) => {
    //             practice.isChecked = false;
    //             return null;
    //         });
    //     }

    //     this.setState({ practicesList, selectedItems: checkedItems, allChecked: areAllChecked });
    // }

    async moveSelectedPractices() {
        const { categoryId, currentTab, moveToCategory, moveToCommercial, selectedItems } = this.state;

        if (moveToCategory === categoryId[currentTab]) {
            Swal.fire({
                title: 'Attenzione.',
                text: 'La categoria iniziale e quella finale coincidono.',
                icon: 'warning'
            });
        } else {
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            GestionePraticheService.moveSelectedPractices({
                practice_ids: selectedItems,
                category_id: moveToCategory,
                commercial_id: moveToCommercial
            }).then((response: any) => {
                if (response && typeof response.error !== 'undefined') {
                    Swal.fire(
                        response.error,
                        '',
                        'error'
                    );
                } else {
                    Swal.fire({
                        title: 'Operazione eseguita.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(async () => {
                        window.location.reload();
                    });
                }
            }).catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore.',
                    text: 'Non è stato possibile completare l\'operazione.',
                    icon: 'error'
                });
            }).finally(() => {
                EventBus.dispatch("hideLoader");
            });
        }
    }

    deleteSelectedPractices() {
        // const { practicesList } = this.state;

        Swal.fire({
            title: 'Confermi l\'eliminazione delle pratiche selezionate?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

                    GestionePraticheService.deleteSelectedPractices({ practice_ids: this.state.selectedItems })
                        .then((response: IMultipleDelete) => {
                            // ELABORAZIONE PIU' COMPLESSA DEL MESSAGGIO DI ERRORE
                            // let deleteError: boolean = false;
                            // let genericError: boolean = false;
                            // const deleteErrorPracticesId: Array<number> = [];

                            // if (response.errors && Object.keys(response.errors).length !== 0) {
                            //     Object.keys(response.errors).map((item: any, index: number) => {
                            //         const errorMessage: any = Object.values(response.errors)[index];

                            //         if (errorMessage.includes("cannot delete a practice that has associated files")) {
                            //             deleteError = true;
                            //             deleteErrorPracticesId.push(item);
                            //             practicesList.map((practice: IPracticeRow) => {
                            //                 if (practice.id === item) {}
                            //             })
                            //         } else {
                            //             genericError = true;
                            //         }

                            //         textModalError = textModalError + Object.values(response.errors)[index]
                            //     })

                            //     let messageText: string = "";
                            //     if (deleteErrorPracticesId.length && deleteErrorPracticesId.length === 1)
                            //         messageText = "La pratica " + deleteErrorPracticesId[0] + " non può essere cancellata perché ha documenti allegati."
                            //     else if (deleteErrorPracticesId.length && deleteErrorPracticesId.length > 1) {
                            //         let msg: string = "";
                            //         deleteErrorPracticesId.map((id: any) => {
                            //             msg = msg === "" ? id : msg + ', ' + id;
                            //         });
                            //         messageText = "Le pratiche " + msg + " non possono essere cancellate perché hanno documenti allegati."
                            //     }

                            //     if (deleteError && !genericError) {
                            //         Swal.fire({
                            //             title: 'Attenzione!',
                            //             text: messageText,
                            //             icon: 'warning'
                            //         });
                            //     } else if (!deleteError && genericError) {
                            //         Swal.fire({
                            //             title: 'Si è verificato un errore',
                            //             text: 'La pratica non è stata cancellata.',
                            //             icon: 'error'
                            //         });
                            //     } else if (deleteError && genericError) {
                            //         console.log("TODO");
                            //     }
                            // }

                            // ELABORAZIONE PIU' SEMPLICE DEL MESSAGGIO DI ERRORE
                            let textModalError: string = "";
                            if (response.errors && Object.keys(response.errors).length !== 0) {
                                Object.keys(response.errors).map((item: any, index: number) => {
                                    textModalError = textModalError + '\n' + Object.values(response.errors)[index];
                                    return null;
                                })

                                Swal.fire({
                                    title: 'Attenzione!',
                                    text: textModalError,
                                    icon: 'warning'
                                }).then(async () => {
                                    window.location.reload();
                                });
                            } else {
                                Swal.fire({
                                    title: 'Operazione eseguita.',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'OK',
                                }).then(async () => {
                                    // window.location.reload();

                                    let { practicesList } = this.state;

                                    response.deleted.map((deletedId) => {
                                        practicesList.map((practice: IPracticeRow, index: number) => {
                                            if (practice.id === deletedId) practicesList.splice(index, 1);
                                            return null;
                                        });
                                        return null;
                                    });

                                    this.setState({ practicesList: [...practicesList] });
                                });
                            }
                        })
                        .catch(() => {
                            Swal.fire({
                                title: 'Si è verificato un errore.',
                                text: 'Non è stato possibile completare l\'operazione.',
                                icon: 'error'
                            });
                        })
                        .finally(() => {
                            EventBus.dispatch("hideLoader");
                            this.setState({ selectedItems: [] });
                        });
                }
            });
    }

    // l'elenco delle pratiche da esportare e' stato messo in un tab dedicato
    // listToExportPractices() {
    //     console.log("ELENCA PRATICHE DA ESPORTARE");
    // }

    twoDigit(data: string) {
        const newData: string = data.length === 1 ? '0' + data : data;
        return newData
    }

    getCurrentDateTime() {
        const date = new Date();
        var yyyy = date.getFullYear().toString();
        var MM = this.twoDigit((date.getMonth() + 1).toString());
        var dd = this.twoDigit(date.getDate().toString());
        var hh = this.twoDigit(date.getHours().toString());
        var mm = this.twoDigit(date.getMinutes().toString());
        // var ss = this.twoDigit(date.getSeconds().toString());

        return yyyy + MM + dd + hh + mm
    }

    exportSelectedPractices() {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

        GestionePraticheService.getExportSelectedPractices()
            .then((response: any) => {
                if (response) {
                    // conta le righe del file csv, se e' vuoto c'e' solo la prima riga
                    const csvRows = response.match(/(?:"(?:[^"]|"")*"|[^,\n]*)(?:,(?:"(?:[^"]|"")*"|[^,\n]*))*\n/g).length;

                    if (csvRows === 1) {
                        Swal.fire({
                            title: '',
                            text: 'Non ci sono pratiche da esportare.',
                            icon: 'warning'
                        });
                    } else {
                        const blob = new Blob([response], { type: 'text/csv;charset=utf-8,' });
                        const objUrl = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.setAttribute('href', objUrl);
                        link.setAttribute('download', 'Pratiche-' + this.getCurrentDateTime() + '.csv');
                        link.setAttribute('target', '_blank');
                        document.body.appendChild(link);

                        link.click();
                        link.remove();
                    }
                }
            })
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore.',
                    text: 'Non è stato possibile completare l\'operazione.',
                    icon: 'error'
                });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });
    }

    exportCurrentCategory() {
        const { tabItemsNumber, categoryId, currentTab } = this.state;

        if (tabItemsNumber[currentTab] > 0) {
            EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

            GestionePraticheService.getExportCurrentCategory(categoryId[currentTab])
                .then((response: any) => {
                    if (response) {
                        const blob = new Blob([response], { type: 'text/csv;charset=utf-8,' });
                        const objUrl = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.setAttribute('href', objUrl);
                        link.setAttribute('download', 'Pratiche-' + this.getCurrentDateTime() + '.csv');
                        link.setAttribute('target', '_blank');
                        document.body.appendChild(link);

                        link.click();
                        link.remove();
                    }
                }, () => {
                    Swal.fire({
                        title: 'Si è verificato un errore.',
                        text: 'Non è stato possibile completare l\'operazione.',
                        icon: 'error'
                    });
                })
                .catch(() => {
                    Swal.fire({
                        title: 'Si è verificato un errore.',
                        text: 'Non è stato possibile completare l\'operazione.',
                        icon: 'error'
                    });
                })
                .finally(() => {
                    EventBus.dispatch("hideLoader");
                });
        } else {
            Swal.fire({
                title: '',
                text: 'Non ci sono pratiche nella categoria corrente.',
                icon: 'warning'
            });
        }
    }

    async deleteAllToExportFlag() {
        Swal.fire({
            title: 'Confermi di voler svuotare l\'elenco <i>export?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

                    // ci pensa il be a mettere a false il flag di tutte le pratiche
                    GestionePraticheService.getResetToExport()
                        .then((response: any) => {
                            let textModalError: string = "";
                            if (response.errors && Object.keys(response.errors).length !== 0) {
                                Object.keys(response.errors).map((item: any, index: number) => {
                                    textModalError = textModalError + '\n' + Object.values(response.errors)[index];
                                    return null;
                                })

                                Swal.fire({
                                    title: 'Attenzione!',
                                    text: textModalError,
                                    icon: 'warning'
                                });
                            } else {
                                Swal.fire({
                                    title: 'Operazione eseguita.',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'OK',
                                }).then(async () => {
                                    window.location.reload();
                                });
                            }
                        })
                        .catch(() => {
                            Swal.fire({
                                title: 'Si è verificato un errore.',
                                text: 'Non è stato possibile completare l\'operazione.',
                                icon: 'error'
                            });
                        })
                        .finally(() => {
                            EventBus.dispatch("hideLoader");
                        });
                }
            });
    }

    getPracticesPerCategory(categoryId: number, sort?: string, order?: string, myPractice?: boolean) {
        if (this.isSearchTab) { // nessun TAB illuminato - ricerca o fltra
            EventBus.dispatch("hideLoader");

            if (this.state.searchText !== '') { // caso RICERCA per stringa
                console.log("ORDINAMENTO RICERCA"); // TODO
            } else {
                console.log("ORDINAMENTO FILTRA"); // TODO
            }
        } else { // un TAB illuminato
            if (categoryId < 0) { // elenca pratiche 'da esportare'
                GestionePraticheService.getPracticesToExport(this.defaultLimit, this.defaultOffset)
                    .then((response: IPractice) => {
                        response.rows.map((row: IPracticeRow) => row.isChecked = false);
                        const hasMore: boolean = response.rows.length < response.total_rows;

                        let { tabItemsNumber } = this.state;
                        tabItemsNumber.splice(0, 1);
                        tabItemsNumber = [response.total_rows, ...tabItemsNumber];

                        this.setState({ practicesList: response.rows, practicesNumber: response.total_rows, tabItemsNumber, hasMore });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Si è verificato un errore.',
                            text: 'Non è stato possibile recuperare i dati.',
                            icon: 'error'
                        });
                    })
                    .finally(() => {
                        EventBus.dispatch("hideLoader");
                    })
            } else { // elenca pratiche per categoria
                const apiSubmit = (sort && order) ?
                    GestionePraticheService.getPracticesPerCategory(categoryId, this.defaultLimit, this.defaultOffset, sort, order, myPractice) :
                    GestionePraticheService.getPracticesPerCategory(categoryId, this.defaultLimit, this.defaultOffset, null, null, myPractice);

                // GestionePraticheService.getPracticesPerCategory(categoryId, this.defaultLimit, this.defaultOffset)
                apiSubmit
                    .then((response: IPractice) => {
                        response.rows.map((row: IPracticeRow) => row.isChecked = false);
                        const hasMore: boolean = response.rows.length < response.total_rows;
                        this.setState({ practicesList: response.rows, practicesNumber: response.total_rows, hasMore });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Si è verificato un errore.',
                            text: 'Non è stato possibile recuperare i dati.',
                            icon: 'error'
                        });
                    })
                    .finally(() => {
                        EventBus.dispatch("hideLoader");
                    })
            }
        }
    }

    updatePracticesPerCategory = () => {
        // qui non mettiamo il loader!
        const offset: number = this.state.itemsToShow;
        const limit = this.defaultLimit;
        const itemNumber: number = this.state.itemsToShow + this.defaultLimit;
        this.setState({ itemsToShow: itemNumber });

        const { categoryId, currentTab, sort, order, filterObj, searchText, myPractice } = this.state;

        if (this.isSearchTab) { // nessun TAB illuminato
            if (searchText !== '') { // caso RICERCA per stringa
                const payload = {
                    query: searchText,
                    limit: limit,
                    offset: offset
                }

                GestionePraticheService.searchFuncion(payload)
                    .then((response: IPractice) => {
                        response.rows.map((row: IPracticeRow) => this.state.allChecked ? row.isChecked = true : row.isChecked = false);

                        const list: Array<IPracticeRow> = [...this.state.practicesList, ...response.rows];
                        const hasMore: boolean = list.length < response.total_rows;
                        this.setState({ practicesList: list, practicesNumber: response.total_rows, hasMore });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Si è verificato un errore.',
                            text: 'Non è stato possibile recuperare i dati.',
                            icon: 'error'
                        });
                    })
            } else { // caso FILTRA campi
                const payload = {
                    category: filterObj.categoryId,
                    part_category: filterObj.partCategoryId,
                    operator: filterObj.operatorId,
                    commercial: filterObj.commercialId,
                    limit: this.defaultLimit,
                    offset: this.defaultOffset,
                    sort: '',
                    order: ''
                }

                GestionePraticheService.filterFunction(payload)
                    .then((response: IPractice) => {
                        response.rows.map((row: IPracticeRow) => this.state.allChecked ? row.isChecked = true : row.isChecked = false);

                        const list: Array<IPracticeRow> = [...this.state.practicesList, ...response.rows];
                        const hasMore: boolean = list.length < response.total_rows;
                        this.setState({ practicesList: list, practicesNumber: response.total_rows, hasMore });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Si è verificato un errore.',
                            text: 'Non è stato possibile recuperare i dati.',
                            icon: 'error'
                        });
                    })
            }
        } else { // un TAB illuminato
            if (categoryId[currentTab] < 0) { // caso pratiche da esportare
                GestionePraticheService.getPracticesToExport(limit, offset)
                    .then((response: IPractice) => {
                        response.rows.map((row: IPracticeRow) => this.state.allChecked ? row.isChecked = true : row.isChecked = false);

                        const list: Array<IPracticeRow> = [...this.state.practicesList, ...response.rows];
                        const hasMore: boolean = list.length < response.total_rows;
                        this.setState({ practicesList: list, practicesNumber: response.total_rows, hasMore });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Si è verificato un errore.',
                            text: 'Non è stato possibile recuperare i dati.',
                            icon: 'error'
                        });
                    })
                    .finally(() => {
                        EventBus.dispatch("hideLoader");
                    })
            } else { // caso categoria pratiche
                const apiSubmit = (sort && order) ?
                    GestionePraticheService.getPracticesPerCategory(categoryId[currentTab], limit, offset, sort, order, myPractice) :
                    GestionePraticheService.getPracticesPerCategory(categoryId[currentTab], limit, offset, null, null, myPractice);

                apiSubmit
                    .then((response: IPractice) => {
                        response.rows.map((row: IPracticeRow) => this.state.allChecked ? row.isChecked = true : row.isChecked = false);

                        const list: Array<IPracticeRow> = [...this.state.practicesList, ...response.rows];
                        const hasMore: boolean = list.length < response.total_rows;
                        this.setState({ practicesList: list, practicesNumber: response.total_rows, hasMore });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Si è verificato un errore.',
                            text: 'Non è stato possibile recuperare i dati.',
                            icon: 'error'
                        });
                    });
            }
        }
    }

    sortName(text: string) { // TODO - lasciata in sospeso
        if (text === "asc") {
            console.log("SORT BY NAME ASC") // from A to Z
        } else {
            console.log("SORT BY NAME DESC") // from Z to A
        }
    }

    sortFunction() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' }); // il loader viene interrotto dalla funzione chiamata

        const { categoryId, currentTab, sort, order, myPractice } = this.state;

        this.setState({ itemsToShow: this.defaultLimit, hasMore: false, selectedItems: [], allChecked: false });

        this.getPracticesPerCategory(categoryId[currentTab], sort, order, myPractice);
    }

    filterFunction() {
        this.setState({ currentTab: 0, itemsToShow: this.defaultLimit, hasMore: false, selectedItems: [], allChecked: false, sort: '', order: '', searchText: '' });
        this.isSearchTab = true;
        this.searchTabTitle = '';
        if (this.state.hasPermissionsResearch) (document.getElementById("search-text") as HTMLInputElement).value = '';

        const { filterObj } = this.state;

        if ((filterObj.categoryId === 0 && filterObj.partCategoryId === 0 && filterObj.operatorId === 0 && filterObj.affiliateId === 0) ||
            (filterObj.categoryId !== 0 && filterObj.partCategoryId === 0 && filterObj.operatorId === 0 && filterObj.affiliateId === 0)
        ) {
            Swal.fire({
                title: 'Almeno uno dei campi deve essere complilato.',
                text: 'Se viene compilato il campo \'Categoria\', è obbligatorio compilare anche uno degli altri tre.',
                icon: 'warning'
            });
        } else {
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const payload = {
                category: filterObj.categoryId,
                part_category: filterObj.partCategoryId,
                operator: filterObj.operatorId,
                affiliate: filterObj.affiliateId,
                limit: this.defaultLimit,
                offset: this.defaultOffset,
                sort: '',
                order: ''
            }

            GestionePraticheService.filterFunction(payload)
                .then((response: IPractice) => {
                    response.rows.map((row: IPracticeRow) => row.isChecked = false);
                    const hasMore: boolean = response.rows.length < response.total_rows;
                    this.setState({ practicesList: response.rows, practicesNumber: response.total_rows, hasMore });
                })
                .catch(() => {
                    Swal.fire({
                        title: 'Si è verificato un errore.',
                        text: 'Non è stato possibile recuperare i dati.',
                        icon: 'error'
                    });
                })
                .finally(() => {
                    EventBus.dispatch("hideLoader");
                })
        }
    }

    searchFunction() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        this.setState({
            currentTab: 0,
            itemsToShow: this.defaultLimit,
            hasMore: false,
            selectedItems: [],
            allChecked: false,
            sort: '',
            order: '',
            filterObj: { categoryId: 0, partCategoryId: 0, operatorId: 0, commercialId: 0, affiliateId: 0 }
        });
        this.selectRef1.clearValue();
        this.selectRef2.clearValue();
        this.selectRef3.clearValue();
        this.selectRef4.clearValue();
        this.isSearchTab = true;
        this.searchTabTitle = this.state.searchText;

        const payload = {
            query: this.state.searchText,
            limit: this.defaultLimit,
            offset: this.defaultOffset
        }

        GestionePraticheService.searchFuncion(payload)
            .then((response: IPractice) => {
                response.rows.map((row: IPracticeRow) => row.isChecked = false);
                const hasMore: boolean = response.rows.length < response.total_rows;
                this.setState({ practicesList: response.rows, practicesNumber: response.total_rows, hasMore });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore.',
                    text: 'Non è stato possibile recuperare i dati.',
                    icon: 'error'
                });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            })
    }

    updatePracticesTable(response: IPracticeRow) {
        let { practicesList } = this.state;

        practicesList.map((practice: IPracticeRow, index: number) => {
            if (practice.id === response.id) {
                response.isChecked = practice.isChecked;
                practicesList.splice(index, 1);
                practicesList.splice(index, 0, response);
            };
            return null;
        });

        this.setState({ practicesList: [...practicesList] });
    }

    setCustomerFormState = async (isDisabled: boolean) => {
        this.setState({ disableCustomerForm: isDisabled });
    }

    async tabChange(currentTab: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' }); // il loader viene interrotto dalla funzione chiamata

        const { categoryId, myPractice } = this.state;
        this.isCategoryExportDisabled = categoryId[currentTab] < 0;

        if (this.state.hasPermissionsResearch) (document.getElementById("search-text") as HTMLInputElement).value = '';

        this.setState({
            currentTab,
            itemsToShow: this.defaultLimit,
            hasMore: false,
            selectedItems: [],
            allChecked: false,
            sort: '',
            order: '',
            searchText: '',
            filterObj: { categoryId: 0, partCategoryId: 0, operatorId: 0, commercialId: 0, affiliateId: 0 }
        }, () => {
            this.isSearchTab = false;
            this.searchTabTitle = '';
            this.selectRef1.clearValue();
            this.selectRef2.clearValue();
            this.selectRef3.clearValue();
            this.selectRef4.clearValue();

            this.getPracticesPerCategory(this.state.categoryId[currentTab], '', '', myPractice);
        });
    }

    renderTabs(): TabProp[] {
        // console.log("RENDER TABS RENDER TABS")
        const {
            formFields,
            formInitialValues,
            showModal,
            modalType,
            modalTitle,
            categoryId,
            currentTab,
            tabKey,
            practicesList,
            practicesNumber,
            fileTypes,
            practicesAttachments,
            allChecked,
            myPractice,
            hasMore
        } = this.state;

        let categoriesTabs: TabProp[] = [];

        tabKey.forEach((tab, index) => {
            // const badge = parseInt(tabItemsNumber[index].toString()); // numero di pratiche per categoria
            const labelId: number = categoryId[index];
            const labelClass = (index === currentTab && !this.isSearchTab) ? 'tabKeys topic-bg-color-2' : 'tabKeys';
            const tabTitle: string = this.isSearchTab ?
                this.searchTabTitle ? 'Risultati ricerca: ' + this.searchTabTitle + ' (' + practicesNumber + ' voci)' :
                    'Risultati filtraggio: ' + practicesNumber + ' voci'
                : tabKey[currentTab];

            categoriesTabs.push({
                id: 'tab_category_' + labelId,
                label: tab,
                hasBadge: false,
                // badgeValue: badge,
                classLabel: labelClass,
                content: <React.Fragment>
                    <div id={'card_' + labelId} className="card">
                        <div className="card-body">
                            <div className="form-group d-flex justify-content-between align-items-center">
                                <h2 className="card-title margin-0">{tabTitle}</h2>
                                <div className="margin-0 form-group display-flex justify-content-between align-items-center">
                                    <div className="display-flex align-items-center">
                                        <div style={{ 'color': '#0b5345', 'fontSize': '1.1em', 'marginTop': '0.4em', 'marginRight': '1em' }}>Solo mie pratiche</div>
                                        <div className="select-my-practice" style={{ 'height': '25px' }}>
                                            <input
                                                id="select-my-practice"
                                                name="select-my-practice"
                                                type="checkbox"
                                                className='topic-text-color checkbox-style'
                                                value="selectMyPractice"
                                                checked={myPractice}
                                                // onChange={async () => this.selectAllItemsAltro()}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="display-flex align-items-center">
                                        <div style={{ 'color': '#0b5345', 'fontSize': '1.1em', 'marginTop': '0.4em', 'marginRight': '1em' }}>Seleziona tutti</div>
                                        <div className="select-all" style={{ 'height': '25px' }}>
                                            <input
                                                id="select-all"
                                                name="select-all"
                                                type="checkbox"
                                                className='topic-text-color checkbox-style'
                                                value="selectAll"
                                                checked={allChecked}
                                                // onChange={async () => this.selectAllItems()}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <PracticesModalForm
                                showModal={showModal}
                                modalTitle={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                apiDownload={GestioneAllegatiService.downloadFile}
                                apiDelete={GestioneAllegatiService.deleteFile}
                                fileTypeList={fileTypes}
                                attachmentsList={practicesAttachments}
                                successAction={modalType === "edit" ? this.updatePracticesTable.bind(this) : undefined}
                                closeCallback={this.closeModal.bind(this)}
                                practicesUpdateCallback={this.setCustomerFormState.bind(this)}
                            />

                            <InfiniteScroll
                                dataLength={practicesList.length}
                                next={() => { this.updatePracticesPerCategory() }}
                                hasMore={hasMore}
                                loader={<h4>Scrolldown per caricare altre voci...</h4>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Non ci sono altre voci</b>
                                    </p>
                                }
                                // scrollableTarget="scrollableDiv"
                                scrollThreshold={0.9}
                            >
                                <Table
                                    id={"table_practices_" + labelId}
                                    className={"table_practices"}
                                    columns={listPracticesColumns}
                                    columnDefs={getListPracticesColumnDefs()}
                                    datas={practicesList}
                                    buttons={[]}
                                    searching={false}
                                    paging={false}
                                    info={false}
                                    ordering={false}
                                />
                            </InfiniteScroll>
                        </div>
                    </div>

                </React.Fragment >,
                isActive: false
            })
        })

        if (categoriesTabs.length > 0)
            categoriesTabs[1].isActive = true; // INIT TAB

        return categoriesTabs
    }

    render() {
        const {
            practiceCategories,
            partCategories,
            operatorsList,
            commercialsList,
            searchText,
            hasPermissionsAdmin,
            hasPermissionsInsert,
            hasPermissionsResearch,
            hasPermissionsEmpty,
            hasPermissions2
        } = this.state;
        let { filterObj } = this.state;

        const _practiceCategories: IFormFieldOption[] = [];
        practiceCategories.map((category: IFormFieldValue) => {
            const obj = { value: category.key, label: category.value };
            _practiceCategories.push(obj);
            return null;
        });
        const _partCategories: IFormFieldOption[] = [];
        partCategories.map((category: IFormFieldValue) => {
            const obj = { value: category.key, label: category.value };
            _partCategories.push(obj);
            return null;
        });
        const _operatorsList: IFormFieldOption[] = [];
        operatorsList.map((operator: IFormFieldValue) => {
            const obj = { value: operator.key, label: operator.value };
            _operatorsList.push(obj);
            return null;
        });
        const _commercialsList: IFormFieldOption[] = [];
        commercialsList.map((user: IFormFieldValue) => {
            const obj = { value: user.key, label: user.value };
            _commercialsList.push(obj);
            return null;
        });

        return <div className="custom-container">
            <div id="page-container" className="card mt-10">
                <div className="card-body">
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <h2 className="card-title">Elenco pratiche</h2>
                        {
                            hasPermissionsInsert &&
                            <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={async () => this.openAddModale()}>
                                <span>Crea pratica</span>
                            </button>
                        }
                    </div>
                </div>

                <div className="card-body">
                    <div className="row"> {/* style={{'height': '110px'}}> */}

                        {/* AZIONI SU 'SELEZIONATI' */}
                        {
                            hasPermissions2 &&
                            <div className="col-12 col-md-4 border-bottom-box" style={{ 'backgroundColor': '#F2F0DF' }}>
                                <h2 className="card-title">Azioni su selezionati</h2>
                                <div className="row">
                                    <div className="col-12 col-lg-7">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className={"margin-0 form-label col-form-label"}>Sposta selezionati su</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // defaultValue={categories[0]}
                                                    // isDisabled={isDisabled}
                                                    // isLoading={isLoading}
                                                    // isRtl={isRtl}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder={"Seleziona una categoria"}
                                                    name="categories"
                                                    options={_practiceCategories}
                                                    ref={(ref) => {
                                                        this.selectRef1 = ref;
                                                    }}
                                                    onChange={async (event: any) =>
                                                        this.setState({ moveToCategory: event ? event.value : "" }, () => {
                                                            this.setState({
                                                                moveSelected: !(this.state.selectedItems.length !== 0 && (this.state.moveToCategory.length !== 0 || this.state.moveToCommercial.length !== 0)),
                                                                deleteSelected: !(this.state.selectedItems.length !== 0)
                                                            });
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className={"margin-0 form-label col-form-label"}>Assegna selezionati a</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // defaultValue={categories[0]}
                                                    // isDisabled={isDisabled}
                                                    // isLoading={isLoading}
                                                    // isRtl={isRtl}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder={"Seleziona un commerciale"}
                                                    name="filter-commercials"
                                                    options={_commercialsList}
                                                    onChange={async (event: any) => {
                                                        //filterObj.commercialId = event ? event.value : 0;
                                                        this.setState({ moveToCommercial: event ? event.value : "" }, () => {
                                                            this.setState({
                                                                moveSelected: !(this.state.selectedItems.length !== 0 && (this.state.moveToCategory.length !== 0 || this.state.moveToCommercial.length !== 0)),
                                                                deleteSelected: !(this.state.selectedItems.length !== 0)
                                                            });
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <label className={"margin-0 form-label col-form-label"} style={{ 'color': '#F2F0DF' }}>Muovi</label>
                                        <button
                                            id="move-selected_btn" type="button" className="btn topic-empty-btn-color"
                                            onClick={async () => this.moveSelectedPractices()} disabled={this.state.moveSelected}
                                        >
                                            <span>Muovi selezionati</span>
                                        </button>
                                    </div>
                                </div>
                                <hr style={{ 'margin': '1rem 0' }} />
                                {/* <label className={"margin-0 form-label col-form-label"} style={{'color': '#fff'}}>Elimina</label> */}
                                <button
                                    id="delete-selected_btn" type="button" className="btn topic-empty-btn-color"
                                    onClick={async () => this.deleteSelectedPractices()} disabled={this.state.deleteSelected}
                                >
                                    <span>Elimina selezionati</span>
                                </button>
                                <div style={{ 'marginBottom': '0.7rem' }} />
                            </div>
                        }

                        {/* AZIONI SU 'DA ESPORTARE' */}
                        <div className="col-12 col-md-4 border-bottom-box">
                            <div style={{ 'backgroundColor': '#F2F0DF', 'height': '100%', 'padding': '0 0 0 0.5em' }}>
                                <h2 className="card-title">Gestione richieste da esportare</h2>
                                {/* <button id="move-to-selected-btn" type="button" className="btn topic-empty-btn-color" onClick={async () => this.listToExportPractices()}>
                                    <span>Vai alle richieste da esportare</span>
                                </button>
                                <div style={{'marginBottom': '0.7rem'}}/> */}
                                <button id="export-selected-btn" type="button" className="btn topic-empty-btn-color" onClick={async () => this.exportSelectedPractices()}>
                                    <span>Esporta richieste da esportare</span>
                                </button>
                                <div style={{ 'marginBottom': '0.7rem' }} />
                                <button id="export-category-btn" type="button" className="btn topic-empty-btn-color"
                                    disabled={this.isCategoryExportDisabled} onClick={async () => this.exportCurrentCategory()}>
                                    <span>Esporta la categoria corrente</span>
                                </button>
                                <div style={{ 'marginBottom': '0.7rem' }} />
                                {
                                    hasPermissionsEmpty &&
                                    <button id="delete-to-export_btn" type="button" className="btn topic-empty-btn-color" onClick={async () => this.deleteAllToExportFlag()}>
                                        <span>Svuota elenco <i>export</i></span>
                                    </button>
                                }
                                <div style={{ 'marginBottom': '0.7rem' }} />
                            </div>
                        </div>

                        {/* ORDINAMENTO */}
                        <div className="col-12 col-md-4 border-bottom-box" style={{ 'backgroundColor': '#F2F0DF' }}>
                            {/* <div style={{'backgroundColor': '#F2F0DF', 'height': '100%', 'padding': '0 0 0 0.5em'}}> */}
                            <h2 className="card-title">Ordinamento</h2>
                            {/* <div className="row">
                                    <div className="col-5">
                                        <label className={"margin-0 form-label col-form-label"}>Nome</label>
                                    </div>
                                    <div className="col-7">
                                        <button id="name-asc-btn" type="button" className="btn topic-empty-btn-color order-btn"
                                            onClick={async () => this.sortName("asc")}
                                        >
                                            <span>&#x21e9;</span>
                                        </button>
                                        <button id="name-des-btn" type="button" className="btn topic-empty-btn-color order-btn"
                                            onClick={async () => this.sortName("des")}
                                        >
                                            <span>&#x21e7;</span>
                                        </button>
                                    </div>
                                </div> */}
                            <div style={{ 'marginBottom': '0.6em' }} />
                            <div className="row">
                                <div className="col-5">
                                    <label className={"margin-0 form-label col-form-label"}>Data di inserimento</label>
                                </div>
                                <div className="col-7">
                                    <button id="ins-date-asc-btn" type="button" className="btn topic-empty-btn-color order-btn"
                                        onClick={async () => {
                                            this.setState({ sort: 'createdAt', order: 'ASC' }, () => {
                                                this.sortFunction();
                                            });
                                        }}
                                    >
                                        <span>&#x21e9;</span>
                                    </button>
                                    <button id="ins-date-des-btn" type="button" className="btn topic-empty-btn-color order-btn"
                                        onClick={async () => {
                                            this.setState({ sort: 'createdAt', order: 'DESC' }, () => {
                                                this.sortFunction();
                                            });
                                        }}
                                    >
                                        <span>&#x21e7;</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ 'marginBottom': '0.6em' }} />
                            <div className="row">
                                <div className="col-5">
                                    <label className={"margin-0 form-label col-form-label"}>Data di modifica</label>
                                </div>
                                <div className="col-7">
                                    <button id="mod-date-asc-btn" type="button" className="btn topic-empty-btn-color order-btn"
                                        onClick={async () => {
                                            this.setState({ sort: 'updatedAt', order: 'ASC' }, () => {
                                                this.sortFunction();
                                            });
                                        }}
                                    >
                                        <span>&#x21e9;</span>
                                    </button>
                                    <button id="mod-date-des-btn" type="button" className="btn topic-empty-btn-color order-btn"
                                        onClick={async () => {
                                            this.setState({ sort: 'updatedAt', order: 'DESC' }, () => {
                                                this.sortFunction();
                                            });
                                        }}
                                    >
                                        <span>&#x21e7;</span>
                                    </button>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>

                    {/* FILTRAGGIO */}
                    <div className="row" style={{ 'marginTop': '0.26rem', 'backgroundColor': '#F2F0DF' }}>
                        {/* <h2 className="card-title">Filtraggio</h2> */}
                        <div className="col-12 col-md-5">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <label className={"margin-0 form-label col-form-label"}>Categorie</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={categories[0]}
                                        // isDisabled={isDisabled}
                                        // isLoading={isLoading}
                                        // isRtl={isRtl}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={"Seleziona"}
                                        name="filter-categories"
                                        options={_practiceCategories}
                                        ref={(ref) => {
                                            this.selectRef2 = ref;
                                        }}
                                        onChange={async (event: any) => {
                                            filterObj.categoryId = event ? event.value : 0;
                                            this.setState({ filterObj })
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label className={"margin-0 form-label col-form-label"}>Categorie ricambio</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={categories[0]}
                                        // isDisabled={isDisabled}
                                        // isLoading={isLoading}
                                        // isRtl={isRtl}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={"Seleziona"}
                                        name="filter-part-categories"
                                        options={_partCategories}
                                        ref={(ref) => {
                                            this.selectRef3 = ref;
                                        }}
                                        onChange={async (event: any) => {
                                            filterObj.partCategoryId = event ? event.value : 0;
                                            this.setState({ filterObj })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <label className={"margin-0 form-label col-form-label"}>Operatori</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={categories[0]}
                                        // isDisabled={isDisabled}
                                        // isLoading={isLoading}
                                        // isRtl={isRtl}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={"Seleziona"}
                                        name="filter-operators"
                                        options={_operatorsList}
                                        ref={(ref) => {
                                            this.selectRef4 = ref;
                                        }}
                                        onChange={async (event: any) => {
                                            filterObj.operatorId = event ? event.value : 0;
                                            this.setState({ filterObj })
                                        }}
                                    />
                                </div>
                                {
                                    hasPermissionsAdmin &&
                                    <div className="col-12 col-sm-6">
                                        <label className={"margin-0 form-label col-form-label"}>Affiliazione</label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // defaultValue={categories[0]}
                                            // isDisabled={isDisabled}
                                            // isLoading={isLoading}
                                            // isRtl={isRtl}
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder={"Seleziona"}
                                            name="filter-commercials"
                                            options={_commercialsList}
                                            ref={(ref) => {
                                                this.selectRef4 = ref;
                                            }}
                                            onChange={async (event: any) => {
                                                filterObj.affiliateId = event ? event.value : 0;
                                                this.setState({ filterObj })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-lg-1">
                            <label className={"margin-0 form-label col-form-label"} style={{ 'color': '#F2F0DF' }}>Filtra</label>
                            <button id="filter-btn" type="button" className="btn topic-empty-btn-color" onClick={async () => this.filterFunction()}>
                                <span>Filtra</span>
                            </button>
                        </div>
                        <div style={{ 'marginBottom': '0.6em' }} />
                    </div>

                    {/* RICERCA */}
                    {
                        hasPermissionsResearch &&
                        <div className="row" style={{ 'marginTop': '0.5em', 'backgroundColor': '#F2F0DF' }}>
                            {/* <div className="col-1">
                                <label className={"form-label col-form-label"} style={{'marginTop': '0.4rem'}}>Ricerca</label>
                            </div> */}
                            <div className="col-10">
                                <form id="search-section" style={{ 'margin': '0.6em 0 0 0' }}>
                                    <div className="row">
                                        <div className="col-12 col-sm-9">
                                            <input
                                                type="text"
                                                id="search-text"
                                                className='search-box'
                                                placeholder={"Cerca prodotto"}
                                                onChange={(event) => {
                                                    this.setState({ searchText: event.target.value });
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <button id="search_btn" type="button" className="search-button" onClick={() => this.searchFunction()} disabled={searchText === ''}>
                                                <span>Cerca</span>
                                            </button>
                                            {/* <input type="reset" value="Cerca" className="search-button" onClick={() => this.searchFunction()} disabled={searchText === ''}/> */}
                                        </div>
                                    </div>
                                </form>
                                <div style={{ 'marginBottom': '0.6em' }} />
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className='mt-4'>
                <div id="practices-container">
                    <Tab
                        useState={true}
                        tabs={this.renderTabs()}
                        tabClickHandler={async (index) =>
                            await this.tabChange(index)
                        }
                    />
                </div>
            </div>
        </div>
    }
}
