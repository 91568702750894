import React from 'react';
import AdminService from '../../../services/api/admin.service';
import { IRouteGroup, IGroupObj, IRouteObj } from '../../../helpers/interfaces/admin';
import getRouteGroupsColumnDefs, {listRouteGroupsColumns } from '../../../config/table-columns/elenco-gruppi-rotte';
import { addRouteGroupFormFields, editRouteGroupFormFields } from '../../../config/form-fields/elenco-gruppi-rotte';
import { IFormField } from '../../../helpers/interfaces/generic';
import ModalForm from '../../../common/ModalForm';
import Table from '../../../common/TableComponent';
import Swal from "sweetalert2";
import $ from 'jquery';
import EventBus from '../../../common/EventBus';

type Props = {};

type State = {
    formFields: IFormField[];
    formInitialValues: { [key: string]: any };
    showModal: boolean;
    modalType: string;
    modalTitle: string;
    routeGroup: Array<IGroupObj>;
};

export default class ElencoGruppiRotte extends React.Component<Props, State> {

    apiSubmit: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            routeGroup: []
        }
    }

    async componentDidMount() {
        this.getRouteGroupsList();

        $('#table_route_groups.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const routeGroupId = $(e.currentTarget).data('id');
            this.getRoutePerRouteGroup(routeGroupId); // TODO per aggiungere colonna 'rotte' nella tabella dei gruppi

            AdminService.getRouteGroup(routeGroupId)
            .then((response: IGroupObj) => {
                this.setState({
                    formFields: editRouteGroupFormFields(),
                    formInitialValues: {id: response.id, description: response.description, name: response.name}
                }, () => {
                    this.apiSubmit = AdminService.editRouteGroups;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica dettagli gruppo rotte' });
                });
                }
            )
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore',
                    text: 'Non è stato possibile salvare la modifica.',
                    icon: 'error',
                });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });
        });

        $('#table_route_groups').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idItem = $(e.currentTarget).data('id');
                    const response: any = await AdminService.deleteRouteGroup(idItem);
                    
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    getRouteGroupsList() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        AdminService.getRouteGroups()
        .then((response: IRouteGroup[]) => {
            if (response.length > 0) {
            this.setState({ routeGroup: response });
            }
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });
    }

    getRoutePerRouteGroup(id: any) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        
        AdminService.getRoutePerRouteGroup(id)
        .then((response: IRouteObj[]) => {
        })
        .catch(() => {
            EventBus.dispatch("hideLoader");
            Swal.fire({
            title: 'Si è verificato un errore',
            text: 'Non è stato possibile recuperare i dati.',
            icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });
    }
    
    async openAddModale() {
        this.setState({
            formFields: addRouteGroupFormFields(),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = AdminService.addRouteGroup;
            this.setState({ showModal: true, modalType: 'add', modalTitle: 'Aggiungi un nuovo gruppo rotte' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { formFields, formInitialValues, showModal, modalType, modalTitle, routeGroup } = this.state;

        return <div className="custom-container">
            <div className="card">
                <div className="card-body">
                    <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                        <h2 className="card-title">Elenco gruppi di rotte</h2>
                        <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={() => this.openAddModale()}>
                            <span>Aggiungi gruppo</span>
                        </button>
                    </div>
                    <ModalForm
                        showModal={showModal}
                        modalTitle={modalTitle}
                        modalType={modalType}
                        formFields={formFields}
                        initialValues={formInitialValues}
                        apiSubmit={this.apiSubmit}
                        closeCallback={this.closeModal.bind(this)}
                        successAction={this.getRouteGroupsList.bind(this)}
                    />
                    <Table id="table_route_groups" columns={listRouteGroupsColumns} columnDefs={getRouteGroupsColumnDefs()} datas={routeGroup} buttons={[]} />
                </div>
            </div>
        </div>
    }
}