import React from 'react';
import AdminService from '../../../services/api/admin.service';
import { IRouteSync } from '../../../helpers/interfaces/admin';
import { history } from '../../../helpers/history';
import Swal from "sweetalert2";
import EventBus from '../../../common/EventBus';

export default class SincronizzaRotte extends React.Component {
    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await AdminService.syncRoute()
        .then((response: IRouteSync) => {
            EventBus.dispatch("hideLoader");
        })
        .catch(() => {
            EventBus.dispatch("hideLoader");

            Swal.fire({
                title: 'Si è verificato un errore',
                text: 'Non è stato possibile effettuare la sincronizzazione.',
                icon: 'error',
            });
        });

        history.push("/amministrazione/sicurezza/autorizzazione-gruppo-rotte");
    }

    render() {
      return <></>
    }
}