import { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { history } from '../helpers/history';
import EventBus from "../common/EventBus";
import CssLoader from "../common/cssloader/CssLoader";
import NavigationBar from "../components/NavigationBar";
import route from './routes.json';
import navBar from './navbar.json';
import Breadcrumb from '../components/Breadcrumb';
import authService from '../services/auth.service';
import { ILoggedUser } from '../helpers/interfaces/user';
import '@tabler/core/dist/css/tabler.min.css'
import '@tabler/core/dist/js/tabler.min.js'
import "../App.css";
import 'font-awesome/css/font-awesome.min.css';

// import components
import HomePage from '../pages/home-page-pubblica/home-page-pubblica';
import Login from '../pages/login';
import SetUserHome from '../pages/set-user-home';
import Profile from '../pages/profile';
import Dashboard from '../pages/dashboard/dashboard';
import GestioneClienti from '../pages/gestione-clienti';
import GestionePratiche from '../pages/pratiche/gestione-pratiche';
import TipologieRuoli from '../pages/amministrazione/tipologie-ruoli';
import GestioneUtenti from '../pages/amministrazione/gestione-utenti';
import TipologieAllegati from '../pages/amministrazione/tipologie-allegati';
import CategoriePratiche from '../pages/amministrazione/categorie-pratiche';
import CategorieRicambio from '../pages/amministrazione/categorie-ricambio';
import ElencoRotte from '../pages/amministrazione/sicurezza/elenco-rotte';
import ElencoGruppiRotte from '../pages/amministrazione/sicurezza/elenco-gruppi-rotte';
import SincronizzaRotte from '../pages/amministrazione/sicurezza/sincronizza-rotte';
import AutorizzazioneRuolo from '../pages/amministrazione/sicurezza/autorizzazione-ruolo';
import AutorizzazioneRotte from '../pages/amministrazione/sicurezza/autorizzazione-rotte';
// import AutorizzazioneAzioni from '../pages/amministrazione/sicurezza/autorizzazione-azioni';
import AutorizzazioneNavigazione from '../pages/amministrazione/sicurezza/autorizzazione-navigazione';
import NotFound from '../pages/notfound';

type Props = {};

type State = {
  showLoader: boolean,
  loaderText: string,
  currentUser: ILoggedUser | null
};

class Routes extends Component<Props, State> {
  data: any = route;

  // add components
  components: any = {
    HomePage,
    Login,
    SetUserHome,
    Profile,
    Dashboard,
    GestioneClienti,
    GestionePratiche,
    TipologieRuoli,
    GestioneUtenti,
    TipologieAllegati,
    CategoriePratiche,
    CategorieRicambio,
    ElencoRotte,
    ElencoGruppiRotte,
    SincronizzaRotte,
    AutorizzazioneRuolo,
    AutorizzazioneRotte,
    // AutorizzazioneAzioni,
    AutorizzazioneNavigazione,
    NotFound,
    Redirect
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      showLoader: false,
      loaderText: '',
      currentUser: null
    }

    EventBus.on("showLoader", this.showLoader);

    navBar.forEach((item) => {
      item?.elements && item?.elements?.length > 0 && this.getNavbarElements(item.elements);

      this.data.push({
        component: "Redirect",
        name: item.name,
        path: item.path,
        protected: true,
        to: item.to
      });
    });

    // Home Route
    this.data.push({
      component: "Home",
      name: "home",
      path: "/",
      exact: false,
      protected: true
    });

    // Route not found
    this.data.push({
      component: "NotFound",
      name: "not-found",
      path: "*",
      exact: false,
      protected: false
    });

    EventBus.on("hideLoader", this.hideLoader);
  }

  async componentDidMount(): Promise<void> {
    const currentUser: ILoggedUser | null = await authService.getCurrentUser()
    this.setState({currentUser});

    history.listen(async () => {
      const currentUser: ILoggedUser | null = await authService.getCurrentUser()
      this.setState({currentUser});

      if (currentUser) {
        const decodedToken = this.parseJwt(currentUser.token);

        if (decodedToken.exp * 1000 < Date.now()) {
          authService.logout();
        }
      }
    });
  }

  parseJwt = (token: string): any => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  getNavbarElements = (elements: any[]): void => {
    elements.forEach((item: any) => {
      item?.elements?.length > 0 && this.getNavbarElements(item.elements);

      if (item.isDropend) {
        this.data.push({
          component: "Redirect",
          name: item.name,
          path: item.path,
          protected: true,
          to: item.to
        });
      }
    });
  }

  showLoader = (data: any): void => { this.setState({ showLoader: true, loaderText: data.detail.text }); }
  hideLoader = (): void => { this.setState({ showLoader: false }); }

  render(): JSX.Element {
    const { showLoader, currentUser, loaderText } = this.state;

    return <Router history={history}>
      {
        currentUser && <NavigationBar navBar={navBar} />
      }
      <div className="container-fluid py-3" style={{ minHeight: "calc(100% - 112px)" }}>
        <CssLoader active={showLoader} text={loaderText} />
        <Switch>
          {this.data.map((route: any, key: number) => (
            <PrivateRoute key={key} {...route} component={this.components[route.component]} />
          ))}
        </Switch>
      </div>
      {
        currentUser ? <footer className="navbar navbar-dark topic-bg-color">
          <div className='d-flex align-items-center mx-auto'>
            <span>Powered by</span>
            <a href="http://www.cgmconsulting.it" target="blank">
              <img src="/assets/CGM-logo-bianco.svg" alt="logo" height="40px" />
            </a>
          </div>
        </footer> : ''
      }
    </Router>
  }
}

const PrivateRoute = (
  { component: Component, ...rest }: any
) => (
  <Route
    {...rest}

    render={(props) => {
      const userString = localStorage.getItem("user");
      const currentUser = userString ? JSON.parse(userString) : null;

      if (rest.protected) {
        const routes = currentUser ? currentUser.routes : [];

        if (!currentUser) {
          return <Redirect to={'/login'} />;
        } else {
          if (rest.alias && !routes.includes(rest.alias))
            return <Redirect to={'/'} />;
        }
      }

      return <>
        {rest.protected && <Breadcrumb route={rest} />}
        {Component ? <Component {...props} routeParams={rest} to={rest.to ? rest.to : '/'} /> : <SetUserHome history={history} />}
      </>;
    }}
  />
);

export default Routes;