import { IRoute } from "../../helpers/interfaces/admin";
import { ConfigColumns } from "datatables.net";

export const listRouteAuthorizationsColumns: ConfigColumns[] = [
    { title: "", data: "isChecked" },
    { title: "Pattern", data: "pattern" },
    { title: "Nome", data: "name" },
    { title: "Metodo", data: "method" },
    { title: "Descrizione", data: "description" }
];

export function getRouteAuthorizationsColumnDefs(group: any) {
    return [
        {
            target: 0,
            searchable: false,
            orderable: false,
            width: "5%",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: IRoute): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox"' + (isChecked && 'checked') + ' data-id="' + row.id + '" data-group="' + group + '" />';

                return '<span>' + checkbox + '</span>'
            }
        }
    ];
}