import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

type Props = {
    resetSelectedPage?: boolean;
    itemsNumber: number;
    defaultItemsPerPage: number;
    updateCallback: CallableFunction;
    resetCallback?: CallableFunction
}

type State = {
    itemsPerPage: number,
    selectedPage: number
}

class Pagination extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            itemsPerPage: this.props.defaultItemsPerPage,
            selectedPage: 1
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if ((prevProps.resetSelectedPage !== this.props.resetSelectedPage && this.props.resetSelectedPage)) {
            this.setState({ selectedPage: 1 });
            if (this.props.resetCallback) this.props.resetCallback();
        }
    }

    handleItemsPerPageClick = (items: number) => {
        this.setState({ itemsPerPage: items, selectedPage: 1 }, () => {
            this.props.updateCallback(this.state.itemsPerPage, this.state.selectedPage);
        });
    }

    handlePageClick = (event: any) => {
        this.setState({ selectedPage: event.selected + 1}, () => {
            this.props.updateCallback(this.state.itemsPerPage, this.state.selectedPage);
        });
    };

    render() {
        const { itemsPerPage, selectedPage } = this.state;
        let numberOfPages: number = 1;
        if (this.props.itemsNumber) {
            numberOfPages = Math.ceil(this.props.itemsNumber/itemsPerPage);
        }

        const fromItem = 1+Number((selectedPage-1)*itemsPerPage);
        const lastItem = Number(itemsPerPage)+Number((selectedPage-1)*itemsPerPage);
        const toItems = lastItem < this.props.itemsNumber ? lastItem : this.props.itemsNumber;

        const opt1 = this.props.defaultItemsPerPage;
        const opt2 = Number.isInteger(this.props.defaultItemsPerPage/2) ? 2 * this.props.defaultItemsPerPage + this.props.defaultItemsPerPage/2 : 2 * this.props.defaultItemsPerPage;
        const opt3 = 2 * opt2;
        const opt4 = 2 * opt3;

        return <React.Fragment>
            <div className="dataTables_wrapper">
                <div className="d-flex justify-content-between align-items-center m-2">
                    <div className="dataTables_length" id="table_commesse_length">
                        <label>
                            Mostra 
                            <select name="table_commesse_length"
                                aria-controls="table_commesse"
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={async (event: any) => {
                                    this.handleItemsPerPageClick(event.target.value);
                                }}
                            >
                                <option key="1" value={opt1}>{opt1}</option>
                                <option key="2" value={opt2}>{opt2}</option>
                                <option key="3" value={opt3}>{opt3}</option>
                                <option key="4" value={opt4}>{opt4}</option>
                            </select>
                            voci
                        </label>
                    </div>

                    <div className="dataTables_info" id="table_commesse_info" role="status" aria-live="polite">
                        Visualizzate da {fromItem} a {toItems} di {this.props.itemsNumber} voci
                    </div>

                    <div className="dataTables_paginate paging_simple_numbers" id="table_commesse_paginate">
                        <ReactPaginate
                            nextLabel="Successiva >"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={numberOfPages}
                            previousLabel="< Precedente"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            forcePage={this.state.selectedPage - 1}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default Pagination;