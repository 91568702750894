import React, { Component } from 'react';
import TipologieRuoliService from '../../services/api/tipologie-ruoli.service';
import GestioneUtentiService from '../../services/api/gestione-utenti.service';
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import getRolesColumnDefs, { listRolesColumns } from '../../config/table-columns/tipologie-ruoli';
import { addRoleFormFields, editRoleFormFields, joinUserRoleFormFields } from '../../config/form-fields/tipologie-ruoli';
import { IRole, IUser, ILoggedUser } from '../../helpers/interfaces/user';
import { IFormField, IFormFieldOption } from "../../helpers/interfaces/generic";
import * as Yup from "yup";
import Swal from "sweetalert2";
import EventBus from '../../common/EventBus';

const $ = require('jquery');

type State = {
    formFields: Array<IFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    ruoli: IRole[],
    hasPermissions: boolean
}

export default class TipologieRuoli extends Component<{}, State> {

    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            ruoli: [],
            hasPermissions: false
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const userString: any = localStorage.getItem("user");
        const currentUser: ILoggedUser = JSON.parse(userString);
        currentUser.roles.map((role: IRole) => {
            // if (role.code.toLocaleLowerCase() === "adm") this.setState({hasPermissions: true});
            this.setState({hasPermissions: true});
            return null;
        });

        TipologieRuoliService.getRoles()
        .then((response: IRole[]) => {
            this.setState({ ruoli: response });
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });

        $('#table_ruoli').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const ruoloId = $(e.currentTarget).data('id');
            const ruoloDettagli = await TipologieRuoliService.getRole(ruoloId);

            if (ruoloDettagli) {
                this.setState({
                    formFields: editRoleFormFields(),
                    formInitialValues: {id: ruoloDettagli.id, code: ruoloDettagli.code, name: ruoloDettagli.name}
                }, () => {
                    this.apiSubmit = TipologieRuoliService.editRole;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica i dettagli del ruolo' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_ruoli').on('click', '.join_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const ruoloId = $(e.currentTarget).data('id');
            const ruoloDettagli = await TipologieRuoliService.getRole(ruoloId);

            let users: IFormFieldOption[] = [];
            GestioneUtentiService.getUsers()
            .then((userList: IUser[]) => {
                if (userList && userList.length > 0)
                users = userList.map((role: IUser) => { return { value: role.id, label: role.last_name + ' ' + role.first_name } });

                this.setState({
                    formFields: joinUserRoleFormFields(users),
                    formInitialValues: { ...ruoloDettagli }
                }, () => {
                    this.apiSubmit = TipologieRuoliService.assignUsersToRole;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Associa utenti al ruolo: ' + ruoloDettagli.name });
                });
            })
            .catch(() => {
              Swal.fire({
                  title: 'Si è verificato un errore.',
                  text: 'Riprovare più tardi.',
                  icon: 'error',
              });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });
            
            // const userList: IUser[] = await GestioneUtentiService.getUsers();
            // if (userList && userList.length > 0) {
            //     users = userList.map((role: IUser) => { return { value: role.id, label: role.last_name + ' ' + role.first_name } });
            // }
            // EventBus.dispatch("hideLoader");
        });

        $('#table_ruoli').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idItem = $(e.currentTarget).data('id');
                    const response: any = await TipologieRuoliService.deleteRole(idItem);
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    async openAddModale() {
        this.setState({
            formFields: addRoleFormFields(),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = TipologieRuoliService.addRole;
            this.setState({ showModal: true, modalType: 'add', modalTitle: 'Aggiungi un nuovo ruolo' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { formFields, formInitialValues, showModal, modalType, modalTitle, ruoli, hasPermissions } = this.state;

        // const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Elenco ruoli</h2>
                                {
                                    hasPermissions &&
                                    <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={() => this.openAddModale()}>
                                        <span>Aggiungi ruolo</span>
                                    </button>
                                }
                            </div>
                            <ModalForm
                                showModal={showModal}
                                modalTitle={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                            <Table id={'table_ruoli'} columns={listRolesColumns} columnDefs={getRolesColumnDefs()} datas={ruoli} buttons={[]} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
