import { ConfigColumns } from "datatables.net";
import { IAction } from '../../helpers/interfaces/permessi-navigazione';

// let isDisabled: string = "disabled";

export const listCategoriesAuthorizationsColumns: ConfigColumns[] = [
    { title: "", data: "isChecked" },
    { title: "Nome", data: "name" },
    { title: "Codice", data: "code" },
    // { title: "", data: "selectAll" }
];

export const listActionsAuthorizationsColumns: ConfigColumns[] = [
    { title: "", data: "isChecked" },
    { title: "Nome", data: "name" },
    { title: "Codice", data: "code" }
];

export function getCategoriesAuthorizationsColumnDefs(user: any) {
    return [
        {
            target: 0,
            searchable: false,
            orderable: false,
            width: "6%",
            class: "text-align-center",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: IAction): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox" class="checkbox-style"' + (isChecked && 'checked') + ' data-id="' + row.id + '" data-user="' + user + '" data-table=1 />';

                return '<span>' + checkbox + '</span>'
            }
        },
        {
            target: 1,
            width: "64%"
        },
        {
            target: 1,
            width: "30%"
        },
        // {
        //     target: listCategoriesAuthorizationsColumns.length - 1,
        //     searchable: false,
        //     orderable: false,
        //     width: "20%",
        //     checkboxes: {
        //         selectRow: true
        //     },
        //     render: (selectAll: boolean, type: string, row: IAction): string => {
        //         const checkbox = 'Vede tutti <input id="all-' + row.id + '" type="checkbox"' + (selectAll && 'checked') + ' data-id=all-"' + row.id + '" data-user="' + user + '" data-table=1 ' + isDisabled + ' />';

        //         return '<span>' + checkbox + '</span>'
        //     }
        // }
    ];
}

export function getActionsAuthorizationsColumnDefs(user: any) {
    return [
        {
            target: 0,
            searchable: false,
            orderable: false,
            width: "5%",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: IAction): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox" class="checkbox-style"' + (isChecked && 'checked') + ' data-id="' + row.id + '" data-user="' + user + '" data-table=2 />';

                return '<span>' + checkbox + '</span>'
            }
        }
    ];
}