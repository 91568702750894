import React, { Component } from 'react';
import CategoriePraticheService from '../../services/api/categorie-pratiche.service';
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import getListPracticeCategorieColumnDefs, { listPracticeCategoriesColumns } from '../../config/table-columns/categorie-pratiche';
import { addPracticeCategoryFormFields, editPracticeCategoryFormFields } from '../../config/form-fields/categorie-pratiche';
import { IRole, ILoggedUser } from '../../helpers/interfaces/user';
import { IFormField, IFormFieldValue } from "../../helpers/interfaces/generic";
import * as Yup from "yup";
import Swal from "sweetalert2";
import EventBus from '../../common/EventBus';
import { ICategoryAll, ICategoryAllRow } from '../../helpers/interfaces/practices';

const $ = require('jquery');

type State = {
    formFields: Array<IFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    categoriesList: ICategoryAllRow[],
    parentCategoriesList: ICategoryAllRow[],
    categoriesNumber: number,
    practiceCategories: IFormFieldValue[],
    hasPermissions: boolean
}

export default class CategoriePratiche extends Component<{}, State> {

    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            categoriesList: [],
            parentCategoriesList: [],
            categoriesNumber: 0,
            practiceCategories: [],
            hasPermissions: false
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const userString: any = localStorage.getItem("user");
        const currentUser: ILoggedUser = JSON.parse(userString);
        currentUser.roles.map((role: IRole) => {
            // if (role.code.toLocaleLowerCase() === "adm") this.setState({hasPermissions: true});
            this.setState({hasPermissions: true});
            return null;
        });

        CategoriePraticheService.getPracticeCategoriesAll()
        .then((response: ICategoryAll) => {
            let categoriesList: ICategoryAllRow[] = [];
            categoriesList = response.rows.map((category: ICategoryAllRow) => {
                return category;
            });

            this.setState({ categoriesList, parentCategoriesList: response.rows, categoriesNumber: response.total_rows }, () => {
                let practiceCategories: IFormFieldValue[] = this.state.parentCategoriesList.map((category: ICategoryAllRow) => { 
                    return { key: category.id, value: category.name }
                });

                // let practiceCategories: IFormFieldValue[] = [];
                // this.state.categoriesList.map((category: IPracticeCategoryRow) => {
                //     let item: IFormFieldValue = { key: category.id, value: category.name };
                //     practiceCategories.push(item);
                //     if (category.childrens.length > 0) {
                //         category.childrens.map((child: IChild) => {
                //             item = { key: child.id, value: child.name };
                //             practiceCategories.push(item);
                //         });
                //     }
                //     return practiceCategories;
                // });
                
                practiceCategories = practiceCategories.sort((itemA: any, itemB: any) => itemA.value.localeCompare(itemB.value));
                this.setState({ practiceCategories });
            });
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });

        $('#table_practice-categories').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const categoriaId = $(e.currentTarget).data('id');
            const categoriaDettagli = await CategoriePraticheService.getPracticeCategory(categoriaId);

            if (categoriaDettagli) {
                this.setState({
                    formFields: editPracticeCategoryFormFields(this.state.practiceCategories),
                    formInitialValues: {...categoriaDettagli, parent: categoriaDettagli.parent?.id}
                }, () => {
                    this.apiSubmit = CategoriePraticheService.editPracticeCategory;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica i dettagli della categoria' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_practice-categories').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idItem = $(e.currentTarget).data('id');
                    
                    // const response: any = await CategoriePraticheService.deletePracticeCategory(idItem);
                    // if (response && typeof response.error !== 'undefined') {
                    //     Swal.fire(
                    //         response.error,
                    //         '',
                    //         'error'
                    //     );
                    // } else {
                    //     window.location.reload();
                    // }

                    CategoriePraticheService.deletePracticeCategory(idItem)
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log("ERROR", error);
                         if (error.response.data.error.description.includes("Cannot delete or update a parent row")) {
                            Swal.fire({
                                title: 'Attenzione!',
                                text: "La categoria non può essere cancellata perché ha categorie figlie associate.",
                                icon: 'warning',
                            });
                        } else {
                            Swal.fire({
                                title: 'Si è verificato un errore',
                                text: 'La categoria non è stata cancellata.',
                                icon: 'error',
                            });
                        }
                    });

                }
            });
        });
    }

    async openAddModale() {
        this.setState({
            formFields: addPracticeCategoryFormFields(this.state.practiceCategories),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = CategoriePraticheService.addPracticeCategory;
            this.setState({ showModal: true, modalType: 'add', modalTitle: 'Aggiungi una nuova categoria' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    render() {
        const { formFields, formInitialValues, showModal, modalTitle, modalType, categoriesList, hasPermissions } = this.state;

        // const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Categorie pratiche</h2>
                                {
                                    hasPermissions &&
                                    <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={() => this.openAddModale()}>
                                        <span>Aggiungi categoria</span>
                                    </button>
                                }
                            </div>
                            <ModalForm
                                showModal={showModal}
                                modalTitle={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                            <Table id={'table_practice-categories'} columns={listPracticeCategoriesColumns} columnDefs={getListPracticeCategorieColumnDefs()} datas={categoriesList} buttons={[]} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
