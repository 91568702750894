import React from 'react';
import GestioneClientiService from '../services/api/gestione-clienti.service';
import GestioneUtentiService from '../services/api/gestione-utenti.service';
import ModalForm from '../common/ModalForm';
import Table from '../common/TableComponent';
import Pagination from '../common/Pagination';
import getListCustomersColumnDefs, { listCustomersColumns } from '../config/table-columns/gestione-clienti';
import { addCustomerFormFields, editCustomerFormFields } from '../config/form-fields/gestione-clienti';
import { IRole, IUser } from '../helpers/interfaces/user';
import { ICustomer, ICustomerRow, IMultipleDelete } from '../helpers/interfaces/customer';
import { IFormField, IFormFieldValue } from '../helpers/interfaces/generic';
import Select from 'react-select';
import Swal from "sweetalert2";
import EventBus from '../common/EventBus';

const $ = require('jquery')

type Props = {}

type State = {
    formFields: IFormField[],
    formInitialValues: {},
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    limit: number,
    offset: number,
    customersList: ICustomerRow[],
    customersNumber: number,
    hasPermissions: boolean,
    commercialList: IFormFieldValue[],
    moveToCommercial: any,
    selectedItems: Array<number>,
    allChecked: boolean,
    moveSelected: boolean,
    deleteSelected: boolean,
    searchText: string;
}

export default class GestioneClienti extends React.Component<Props, State> {

    apiSubmit: any;
    defaultLimit: number = 10;
    defaultOffset: number = 0;
    isSearchTab: boolean = false;
    resetSelectedPage: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            limit: this.defaultLimit,
            offset: this.defaultOffset,
            customersList: [],
            customersNumber: 0,
            hasPermissions: false,
            commercialList: [],
            moveToCommercial: "",
            selectedItems: [],
            allChecked: false,
            moveSelected: true,
            deleteSelected: true,
            searchText: ""
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        // const userString: any = localStorage.getItem("user");
        // const currentUser: ILoggedUser = JSON.parse(userString);
        // currentUser.roles.map((role: IRole) => {
        //     if (role.code.toLocaleLowerCase() === "adm") this.setState({hasPermissions: true});
        //     return null;
        // })

        Promise.all([
            GestioneClientiService.getCustomers(this.defaultLimit, this.defaultOffset),
            GestioneUtentiService.getUsers()
        ]).then(async (data: [
            ICustomer,
            IUser[]
        ]) => {
            data[0].rows.map((row: ICustomerRow) => row.isChecked = false);
            this.setState({customersList: data[0].rows, customersNumber: data[0].total_rows});

            let commercialList: any[] = [];
            data[1].map((user: IUser) => {
                user.roles.map((role: IRole) => {
                    if (role.code.toLowerCase() === 'cmm') {
                        const obj = {key: user.id, value: user.first_name + ' ' + user.last_name};
                        commercialList.push(obj);
                    };
                    return null;
                });
                return null;
            });
            this.setState({ commercialList });
        }).catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        }).finally(() =>
            EventBus.dispatch("hideLoader")
        );

        $('#client-section-container').on('change', 'input[type=checkbox]', async (event: any) => {
            event.preventDefault();

            const clientId = $(event.currentTarget).data('id');

            if (clientId) {
                const { selectedItems } = this.state;

                let found: boolean = false;
                selectedItems.map((item: number, index: number) => {
                    if (item === clientId) {
                        selectedItems.splice(index, 1);
                        found = true;
                    };
                    return null;
                });
                if (!found) selectedItems.push(clientId);

                this.setState({ selectedItems }, () => {
                    this.state.customersList.map((customer: ICustomerRow) => customer.isChecked = false );
                    this.state.customersList.map((customer: ICustomerRow) => {
                        this.state.selectedItems.map((id: number) => {
                            if (customer.id === id) customer.isChecked = true;
                            return null;
                        })
                        return null;
                    });

                    this.setState({
                        moveSelected: !(this.state.selectedItems.length !== 0 && this.state.moveToCommercial.length !== 0),
                        deleteSelected: !(this.state.selectedItems.length !== 0)
                    });
                });
            } else {
                const { allChecked } = this.state;
                const areAllChecked: boolean = !allChecked;
                const checkedItems: Array<any> = [];
        
                let { customersList } = this.state;
                if (areAllChecked) {
                    customersList.map((customer: ICustomerRow) => {
                        customer.isChecked = true;
                        checkedItems.push(customer.id);
                        return null;
                    });
                } else {
                    customersList.map((customer: ICustomerRow) => {
                        customer.isChecked = false;
                        return null;
                    });
                }

                // non modificare customersList: [...customersList] serve per l'aggiornamento della tabella, in particolare delle checkbox
                this.setState({ customersList: [...customersList], selectedItems: checkedItems, allChecked: areAllChecked }, () => {
                    this.setState({
                        moveSelected: !(this.state.selectedItems.length !== 0 && this.state.moveToCommercial.length !== 0),
                        deleteSelected: !(this.state.selectedItems.length !== 0)
                    });
                });
            }
        });

        $('#table_clienti.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const clienteId = $(e.currentTarget).data('id');
            const clienteDettagli: ICustomerRow = await GestioneClientiService.getCustomer(clienteId);

            if (clienteDettagli) {
                this.setState(
                {
                    formFields: editCustomerFormFields(this.state.commercialList),
                    formInitialValues: { ...clienteDettagli, commercial: clienteDettagli.commercial?.id }
                },
                () => {
                    this.apiSubmit = GestioneClientiService.editCustomer
                    this.setState({
                    showModal: true,
                    modalType: 'edit',
                    modalTitle: 'Modifica i dati cliente'
                    });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_clienti.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idCliente = $(e.currentTarget).data('id');
                    // const cliente: any = await 
                    GestioneClientiService.deleteCustomer(idCliente)
                    .then(() => {
                        window.location.reload();
                    }, (error: any) => {
                        let respMessage =
                            (error.response && error.response.data && error.response.data.message) ||
                            (error.response && error.response.data && error.response.data.error) ||
                            (error.response && error.response.data && error.response.data.description) ||
                            error.message ||
                            error.toString();
                        
                        const stringyfiedRespMessage = respMessage ? JSON.stringify(respMessage) : "";
        
                        switch (true) {
                            case (stringyfiedRespMessage.toLowerCase()).includes("cannot delete a customer"):
                                respMessage = "Non è possibile cancellare un cliente che ha pratiche associate.";
                                break;
                            default:
                                respMessage = "Si è verificato un errore."
                        }
        
                        Swal.fire(
                            'Eliminazione non effettuata.',
                            respMessage,
                            'warning'
                        );
                    })
                }
            });
        });

        // disabilita il tasto invia della tastiera nel text input della ricerca
        $('#client-search-section').keypress(function(event: any) {
            // console.log("TASTO:", event.which);
            if (event.which === 13) {
                // console.log("TASTO INVIO");
                return false;
            }
        });
    }

    async openAddModale() {
        this.setState({
            formFields: addCustomerFormFields(this.state.commercialList),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = GestioneClientiService.addCustomer;
            this.setState({
                showModal: true,
                modalType: 'add',
                modalTitle: 'Aggiungi un nuovo cliente'
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false })
    }

    setItemsToRender = async (pages: any, page: any) => {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const limit = pages;
        const offset = (page - 1) * pages;

        if (this.isSearchTab) { // elenco ricerca
            const payload = {
                query: this.state.searchText,
                limit: Number(limit),
                offset: offset
            }

            GestioneClientiService.searchFuncion(payload)
            .then((response: ICustomer) => {
                response.rows.map((row: ICustomerRow) => row.isChecked = false);
                this.setState({ customersList: response.rows, customersNumber: response.total_rows });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore.',
                    text: 'Non è stato possibile recuperare i dati.',
                    icon: 'error'
                });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            })
        } else { // elenco tutti
            GestioneClientiService.getCustomers(limit, offset)
            .then((response: ICustomer) => {
                response.rows.map((row: ICustomerRow) => row.isChecked = false);
                this.setState({
                    limit,
                    offset,
                    customersList: response.rows,
                    customersNumber: response.total_rows,
                    selectedItems: [],
                    allChecked: false
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore.',
                    text: 'Non è stato possibile recuperare i dati.',
                    icon: 'error',
                });
            })
            .finally(() => 
                EventBus.dispatch("hideLoader")
            );
        }
    };

    moveSelectedCustomers() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        
        const { moveToCommercial, selectedItems } = this.state;

        GestioneClientiService.moveSelectedCustomers(moveToCommercial, {customer_ids: selectedItems})
        .then((response: any) => {
            if (response && typeof response.error !== 'undefined') {
                Swal.fire(
                    response.error,
                    '',
                    'error'
                );
            } else {
                Swal.fire({
                    title: 'Operazione eseguita.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                }).then(async () => {
                    window.location.reload();
                });
            }
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile completare l\'operazione.',
                icon: 'error'
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
            this.setState({ selectedItems: [] });
        });
    }

    deleteSelectedItems() {
        Swal.fire({
            title: 'Confermi l\'eliminazione dei dati selezionati?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        })
        .then(async (result) => {
            if (result.isConfirmed) {
                EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

                GestioneClientiService.deleteSelectedCustomers({customer_ids: this.state.selectedItems})
                .then((response: IMultipleDelete) => {
                    let textModalError: string = "";
                    if (response.errors && Object.keys(response.errors).length !== 0) {
                        Object.keys(response.errors).map((item: any, index: number) => {
                            textModalError = textModalError + '\n' + Object.values(response.errors)[index];
                            return null;
                        })

                        Swal.fire({
                            title: 'Attenzione!',
                            text: textModalError,
                            icon: 'warning',
                        });
                    } else {
                        Swal.fire({
                            title: 'Operazione eseguita.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK',
                        }).then(async () => {
                            // window.location.reload();

                            let { customersList } = this.state;

                            response.deleted.map((deletedId: number) => {
                                customersList.map((customer: ICustomerRow, index: number) => {
                                    if (customer.id === deletedId) customersList.splice(index, 1);
                                    return null;
                                });
                                return null;
                            });

                            this.setState({ customersList: [...customersList] });
                        });
                    }
                })
                .catch(() => {
                    Swal.fire({
                        title: 'Si è verificato un errore.',
                        text: 'Non è stato possibile completare l\'operazione.',
                        icon: 'error',
                    });
                })
                .finally(() => {
                    EventBus.dispatch("hideLoader");
                    this.setState({ selectedItems: [] });
                });
            }
        });
    }

    searchFunction() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        this.isSearchTab = true;
        this.setState({ customersList: [], selectedItems: [], customersNumber: 0 });

        const payload = {
            query: this.state.searchText,
            limit: this.defaultLimit,
            offset: this.defaultOffset
        }

        GestioneClientiService.searchFuncion(payload)
        .then((response: ICustomer) => {
            response.rows.map((row: ICustomerRow) => row.isChecked = false);
            this.setState({ customersList: response.rows, customersNumber: response.total_rows });
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error'
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        })
    }
    
    updatePracticesTable(response: ICustomerRow) {
        let { customersList } = this.state;

        customersList.map((customer: ICustomerRow, index: number) => {
            if (customer.id === response.id) {
                response.isChecked = customer.isChecked;
                customersList.splice(index,1);
                customersList.splice(index,0,response);
            };
            return null;
        });

        this.setState({ customersList: [...customersList] });
    }

    resetCallback() {
        this.resetSelectedPage = false;
    }

    render() {
        const { formFields, formInitialValues, showModal, modalType, modalTitle, customersList, customersNumber, commercialList, allChecked, searchText } = this.state;

        const _commercialList: any[] = [];
        commercialList.map((category: IFormFieldValue) => {
            const obj = {value: category.key, label: category.value};
            _commercialList.push(obj);
            return null;
        });
        
        return <div className="custom-container">
            <div id="client-section-container" className="card">
                <div className="card-body">
                    <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                        <h2 className="card-title">Elenco clienti</h2>
                        <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={async () => this.openAddModale()}>
                            <span>Aggiungi cliente</span>
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    {/* AZIONI SU 'SELEZIONATI' */}
                    <div className="row"> {/* style={{'height': '110px'}}> */}
                        <div className="col-12 col-sm-7" style={{'backgroundColor': '#F2F0DF'}}>
                            <h2 className="card-title">Azioni su selezionati</h2>
                            <div className="row">
                                <div className="col-12 col-md-7">
                                    <label className={"margin-0 form-label col-form-label"}>Sposta selezionati su operatore</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // isDisabled={isDisabled}
                                        // isLoading={isLoading}
                                        // isRtl={isRtl}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={"Seleziona un commerciale"}
                                        name="categories"
                                        options={_commercialList}
                                        onChange={async (event: any) => this.setState({ moveToCommercial: event ? event.value : ""}, () => {
                                            this.setState({
                                                moveSelected: !(this.state.selectedItems.length !== 0 && this.state.moveToCommercial.length !== 0),
                                                deleteSelected: !(this.state.selectedItems.length !== 0)
                                            });
                                        })}
                                    />
                                </div>
                                <div className="col-12 col-md-5">
                                    <label className={"margin-0 form-label col-form-label"} style={{'color': '#F2F0DF'}}>Sposta</label>
                                    <button
                                        id="move-selected_btn" type="button" className="btn topic-empty-btn-color"
                                        onClick={async () => this.moveSelectedCustomers()} disabled={this.state.moveSelected}
                                    >
                                        <span>Sposta selezionati</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{'marginBottom': '0.7rem'}}/>
                        </div>

                        <div className="col-12 col-sm-3" style={{'backgroundColor': '#F2F0DF'}}>
                            <h2 className="card-title" style={{'color': '#F2F0DF'}}>Azioni su selezionati</h2>
                            <label className={"margin-0 form-label col-form-label"}>Elimina selezionati</label>
                            <button
                                id="delete-selected_btn" type="button" className="btn topic-empty-btn-color"
                                onClick={async () => this.deleteSelectedItems()} disabled={this.state.deleteSelected}
                            >
                                <span>Elimina</span>
                            </button>
                        </div>

                        <div className="col-12 col-sm-2" style={{'backgroundColor': '#F2F0DF'}}>
                            <h2 className="card-title" style={{'color': '#F2F0DF'}}>Azioni su selezionati</h2>
                            <label className={"margin-0 form-label col-form-label"}>Seleziona tutti</label>
                            <input
                                id="select-all"
                                name="select-all"
                                type="checkbox"
                                className='topic-text-color checkbox-style'
                                value="selectAll"
                                checked={allChecked}
                                readOnly
                            />
                        </div>
                    </div>

                    {/* RICERCA */}
                    {
                        // hasPermissionsResearch &&
                        <div className="row" style={{'marginTop': '0.5em', 'backgroundColor': '#F2F0DF'}}>
                            <div className="col-12 col-1">
                                <label className={"form-label col-form-label"} style={{'marginTop': '0.4rem'}}>Ricerca</label>
                            </div>
                            <div className="col-12 col-10">
                                <form id="client-search-section" style={{'margin': '0.6em 0 0 0'}}>
                                    <div className="row">
                                        <div className="col-12 col-sm-8">
                                            <input
                                                type="text"
                                                id="client-search-text"
                                                className='search-box'
                                                placeholder={"Cerca cliente"}
                                                onChange={(event) => {
                                                    this.setState({ searchText: event.target.value });
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <button
                                                id="client-search_btn"
                                                type="button"
                                                className="search-button"
                                                onClick={() => {
                                                    this.resetSelectedPage = true;
                                                    this.searchFunction();
                                                }}
                                                disabled={searchText === ''}>
                                                <span>Cerca</span>
                                            </button>
                                            {/* <input type="reset" value="Cerca" className="search-button" onClick={() => this.searchFunction()} disabled={searchText === ''}/> */}
                                        </div>
                                        <div className="col-12 col-sm-1">
                                            <button
                                                id="list-all-btn" type="button" className="btn topic-empty-btn-color"
                                                onClick={() => {
                                                    this.isSearchTab = false;
                                                    const element: any = document.getElementById('client-search-text');
                                                    if (element) element.value = "";
                                                    this.resetSelectedPage = true;
                                                    this.setItemsToRender(this.defaultLimit, 1);
                                                }}
                                            >
                                                <span>Vedi tutti</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div style={{'marginBottom': '0.6em'}}/>
                            </div>
                        </div>
                    }
                </div>

                <ModalForm
                    showModal={showModal}
                    modalTitle={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={this.apiSubmit}
                    closeCallback={this.closeModal.bind(this)}
                    successAction={modalType === "edit" ? this.updatePracticesTable.bind(this) : undefined}
                />

                <Table
                    id="table_clienti"
                    columns={listCustomersColumns}
                    columnDefs={getListCustomersColumnDefs()}
                    datas={customersList}
                    buttons={[]}
                    searching={false}
                    paging={false}
                    info={false}
                />
                <Pagination
                    resetSelectedPage={this.resetSelectedPage}
                    itemsNumber={customersNumber}
                    defaultItemsPerPage={this.defaultLimit}
                    updateCallback={this.setItemsToRender.bind(this)}
                    resetCallback={this.resetCallback.bind(this)}
                />
            </div>
        </div>
    }
}