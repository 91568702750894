// import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { ConfigColumns } from "datatables.net";
import { IPracticeRow } from "../../helpers/interfaces/practices";

let isGreen: boolean = false;

export const listPracticesColumns: ConfigColumns[] = [
    { title: "", data: "isChecked" },
    { title: "", data: "id" },
    {
        title: "Data pratica - n° conferma", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const date = row.date ?? '';
            const confNumber = row.confirmation_number ?? '';
            isGreen = (confNumber !== '');
            
            // return date + ' / ' + confNumber;
            switch (isGreen) {
                case true:
                    return `<div class="bg-cell-green"> ${date + ' / ' + confNumber}</div>`;
                case false:
                    return  `<div class="bg-cell-orange">${date + ' / ' + confNumber}</div>`;
            }
        }
    },
    {
        title: "Categoria ricambi - veicolo", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const catPart = row.part_category?.name ?? '';
            const vehicle = row.vehicle ?? '';

            // return  catPart + ' / ' + vehicle;
            switch (isGreen) {
                case true:
                    return `<div class="bg-cell-green"> ${catPart + ' / ' + vehicle}</div>`;
                case false:
                    return  `<div class="bg-cell-orange">${catPart + ' / ' + vehicle}</div>`;
            }
        }
    },
    {
        title: "Codice motore - anno veicolo", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const engine = row.engine_code ?? '';
            const vehYear = row.vehicle_year ?? '';
            return engine + ' / ' + vehYear;
        }
    },
    {
        title: "Provincia - data pratica", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const province = row.province ?? '';
            const date = row.created_at ?? '';

            let creationDate: string = '';
            let creationTime: string = '';
            if (date !== '') {
                const _date: string[] = row.created_at.split('T');
                creationDate = _date[0];
                creationTime = _date[1].slice(0,5);
            }
            
            return province + ' / ' + creationDate + ' ' + creationTime;
        }
    },
    {
        title: "Nome cliente", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const firstName = row.customer && row.customer.first_name ? row.customer.first_name : '';
            const lastName = row.customer && row.customer.last_name ? row.customer.last_name : '';
            return firstName + ' ' + lastName;
            // return row.customer?.name ?? '';
        }
    },
    // {
    //     title: "Stato - commerciale - data spedizione", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
    //         return row.ToDo + ' / ' + row.commercial + ' / ' + row.shipping_date;
    //     }
    // }
    {
        title: "Categoria - data modifica", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const category = row.category && row.category.name ? row.category.name : '';
            const date = row.updated_at ?? '';

            let updateDate: string = '';
            let updateTime: string = '';
            if (date !== '') {
                const _date: string[] = row.updated_at.split('T');
                updateDate = _date[0];
                updateTime = _date[1].slice(0,5);
            }
            
            return  category + ' / ' + updateDate + ' ' + updateTime;
        }
    },
    {
        title: "Affiliazione", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const affiliate = row.affiliate?.fullname ?? '';
            
            switch (affiliate.trim() !== '') {
                case true:
                    return `<div class="bg-cell-yellow"> ${affiliate}</div>`;
                case false:
                    return affiliate;
            }
        }
    },
    {
        title: "Commerciale", "data": null, render: (data: any, type: any, row: IPracticeRow) => {
            const commercial = row.commercial?.fullname ?? '';
            
            switch (commercial.trim() !== '') {
                case true:
                    return `<div class="bg-cell-yellow"> ${commercial}</div>`;
                case false:
                    return commercial;
            }
        }
    },
    { title: "", data: "id" }
];


// export default function getListPracticesColumnDefs(): ConfigColumnDefs[] {
export default function getListPracticesColumnDefs(): any[] {
    return [
        {
            // target: listPracticesColumns.length - 1,
            target: 0,
            searchable: false,
            orderable: false,
            width: "3%",
            class: "text-align-center",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: IPracticeRow): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox" class="checkbox-style"' + (isChecked && 'checked') + ' data-id="' + row.id + '" />';

                return '<span>' + checkbox + '</span>'
            }
        },
        {
            target: 1,
            width: "3%",
            render: (id: number, type: string, row: IPracticeRow) => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1 center" data-id="${id}" title="Modifica dettagli pratica">
                                <i style="font-size: 18px" class="fa fa-pencil center" aria-hidden="true"></i>
                            </a>`;
                // const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}" title="Elimina pratica">
                //                 <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                //             </a>`;

                // return '<div class="d-flex">' + edit + trash + '</div>';
                return '<div class="d-flex">' + edit + '</div>';
            }
        },
        {
            target: listPracticesColumns.length - 1,
            width: "3%",
            render: (id: number, type: string, row: IPracticeRow) => {
                const _phoneNumber = row.customer && row.customer.phone_number ? row.customer.phone_number : '';
                const phoneNumber = _phoneNumber
                    .replace(/\s/g, '')
                    .replace(/\+/g, '') // .replace(/\+/g, 0)
                    .replace(/-/g, '')
                    .replace(/\./g, '');
                const url = "https://wa.me/" + phoneNumber;

                const chat = `<a href="${url}" class="whatsapp-icon" target="_blank">
                                <img class="center" src="/assets/whatsapp.svg" alt="whatsapp" title="Contatta il cliente">
                            </a>`;
                const chatDisabled = `<a style="opacity: 0.4">
                                        <img class="whatsapp-icon center" src="/assets/whatsapp.svg" alt="whatsapp" title="Numero di contatto mancante">
                                    </a>`;
                            
                const string = phoneNumber !== '' ? '<div class="d-flex">' + chat + '</div>' : '<div class="d-flex">' + chatDisabled + '</div>';
                return string;
            }
        }
    ];
}