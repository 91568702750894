import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IRoute } from "../../helpers/interfaces/admin";

export const listRoutesColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Pattern", data: "pattern" },
    { title: "Nome", data: "name" },
    { title: "Metodo", data: "method" },
    { title: "Descrizione", data: "description" }
    // {
    //     title: "Gruppo", "data": null, render: (data: any, type: any, row: IRoute) => {
    //         let groupsList: any[] = [];
    //         row.groups.map((group: IGroupObj) => {
    //             return groupsList.push(group.name + ' - ' + group.description);
    //         })
    //         return groupsList
    //     }
    // }
];

export default function getRoutesColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IRoute): string => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${id}" title="Modifica dettagli rotta">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
    ];
}
