import { Component, ReactNode } from "react";
import { history } from '../helpers/history';
import { IRoute } from "../helpers/interfaces/generic";
import { iconsStyle, resetButtonsStyle } from "../helpers/settings/buttons-icons-styles";
import Routes from '../routes/routes.json';
import AuthService from "../services/auth.service";
import { ILoggedUser } from '../helpers/interfaces/user';
import 'moment/locale/it';

interface Props {
  navBar: Array<NavBarList>;
};

interface State {
  currentUser: ILoggedUser | undefined;
  isAdmin: boolean;
  navBar: Array<NavBarList>;
}

interface NavBarList {
  id: string;
  name: string;
  label?: string;
  position: number;
  path: string;
  exact: boolean;
  title?: string;
  to?: string;
  alias?: string | Array<string>;
  hidden?: boolean;
  elements?: Array<NavBarElement>;
  dropdown?: boolean;
  link?: string;
}

interface NavBarElement {
  id?: string;
  name: string;
  label?: string;
  position: number;
  path: string;
  exact: boolean;
  to?: string;
  alias?: string;
  hidden?: boolean;
  topDivider?: boolean;
  bottomDivider?: boolean;
  isDropend?: boolean;
  dropend?: Array<NavBarElement>;
}

class NavigationBar extends Component<Props, State> {

  dropdownRef: (HTMLButtonElement | null)[] = []
  dropdownMenuRef: (HTMLElement | null)[] = []

  constructor(props: Props) {
    super(props);

    this.state = {
      isAdmin: false,
      currentUser: undefined,
      navBar: []
    };
  }

  async componentDidMount() {
    let { navBar } = this.props

    const currentUser: ILoggedUser | null = await AuthService.getCurrentUser();
    const isAdmin = await AuthService.getIsAdmin();

    if (currentUser) {
      this.setState({
        currentUser,
        isAdmin
      });
    }

    navBar = this.mergeRoute(navBar);

    this.setState({ navBar })
  }

  logOut() {
    AuthService.logout();
    this && this.setState({ currentUser: undefined })
  }

  mergeRoute(navBar: Array<NavBarList>) {
    navBar && navBar.forEach((value, index) => {
      Routes && Routes.forEach((route: IRoute, key) => {
        if (route.navbar && route.navbar === value.id) {
          if (!navBar[index].elements) navBar[index].elements = [];
          navBar[index].elements && navBar[index].elements?.push({
            path: route.path,
            exact: route.exact,
            name: route.name,
            label: route.label ? route.label : route.name ?? '',
            position: route.navbarPosition ?? key,
            alias: route.alias,
            // topDivider: route.topDivider ?? false,
            bottomDivider: route.bottomDivider ?? false,
            hidden: route.hidden ?? false,
          });
        }

        value.elements && value.elements.forEach((element, key) => {
          if (element.isDropend) {
            if (route.navbar && element.id && route.navbar === element.id) {
              if (!element.dropend) element.dropend = [];
              element.dropend?.push({
                path: route.path,
                exact: route.exact,
                name: route.name,
                label: route.label,
                position: route.navbarPosition ?? key,
                alias: route.alias,
                // topDivider: route.topDivider ?? false,
                bottomDivider: route.bottomDivider ?? false,
                hidden: route.hidden ?? false,
              });
            }
          }
        });
      });

      value.elements && value.elements.forEach((element, k) => {
        if (element.isDropend) {
          if (!element.dropend || (element.dropend && element.dropend?.length === 0)) {
            navBar[index].elements = navBar[index].elements?.filter(item => { return (item.id !== element.id) });
          }

          if (element.dropend && element.dropend?.length) {
            element.dropend?.sort((a, b) => a.position > b.position ? 1 : -1);
          }
        }
      })
    });

    Routes && Routes.forEach((route: IRoute, key: number) => {
      if (!route.navbar && route.navbarRoot === true) {
        navBar.push({
          id: route.name.toLowerCase(),
          name: route.name,
          label: route.label,
          position: route.navbarPosition ? route.navbarPosition : key,
          path: route.path,
          exact: route.exact,
          alias: route.alias,
          link: route.path,
          dropdown: route.dropdown,
          hidden: route.hidden ?? false,
        })
      }
    });

    return navBar;
  }

  getNavList(navBarList: Array<NavBarList>) {
    const { currentUser } = this.state;

    let navBarItems: NavBarList[] = [];
    navBarList && navBarList.forEach((value: NavBarList, index: number) => {
      let aliasAllowed = false;

      value.alias instanceof Array && value.alias.forEach(alias => {
        if (currentUser?.routes.includes(alias)) aliasAllowed = true;
      });

      if (typeof value.alias === 'string' && currentUser?.routes.includes(value.alias)) aliasAllowed = true;

      if (!value.hidden && (!value.alias || aliasAllowed)) {
        navBarItems.push(value);
      }
    });

    let result: Array<NavBarList> = [];
    navBarItems && navBarItems.forEach((value: NavBarList, index: number) => {
      if (value.elements?.length) {
        value.elements?.forEach((element: NavBarElement, key: number) => {
          if (!element.hidden && (element.alias === undefined || (currentUser?.routes && currentUser.routes.includes(element.alias)))) {
            if (!result[index]) {
              result[index] = { ...value, elements: [] };
            }

            let elementDropend: Array<NavBarElement> = [];
            element.dropend && element.dropend.forEach(dropValue => {
              if (dropValue.alias === undefined || (currentUser?.routes && currentUser.routes.includes(dropValue.alias))) {
                elementDropend?.push(dropValue);
              }
            });

            elementDropend.length && elementDropend.sort((a, b) => a.position > b.position ? 1 : -1);
            if (elementDropend.length) {
              element.dropend = elementDropend
            } else {
              delete element.dropend;
            }

            if (element.isDropend) {
              if (!element.dropend || (element.dropend && element.dropend?.length === 0)) {
                delete result[index];
              }

              if (element.dropend && element.dropend?.length) {
                element.dropend?.sort((a, b) => a.position > b.position ? 1 : -1);
              }
            }

            (element && result[index]) && result[index].elements?.push(element);
          }
        })
      } else {
        if (!result[index]) {
          result[index] = { ...value };
        }
      }

      result[index] && result[index]?.elements?.sort((a, b) => a.position > b.position ? 1 : -1);
    });

    result = result.filter((value) => typeof value !== 'undefined');

    if (result.length === 1) {
      result = result[0].elements ? result[0].elements.map(
        (element: NavBarElement, index: number) => {
          return {
            id: element.id ?? element.alias ?? `${result[0].id}_${index.toString()}`,
            name: element.name,
            position: element.position,
            path: element.path,
            exact: element.exact,
            label: element.label,
            alias: element.alias,
            to: element.to ?? element.path,
            elements: [],
            link: element.path,
            dropdown: element.dropend?.length ? element.dropend.length > 0 : false,
            hidden: element.hidden ?? false,
          }
        }
      ) : [];
    }

    return result.sort((a, b) => a.position > b.position ? 1 : -1);
  }

  getDropend(dropend: Array<NavBarElement>, index: number) {
    return dropend?.length && dropend.map((element, key) => {
      return <span key={key}>
        {element.topDivider === true && <div className="dropdown-divider"></div>}
        {element.dropend && element.dropend?.length ? (
          <div className="dropend">
            <button className="topic-menu-btn-color btn-link dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="inside" onClick={e => this.handleHistory(element.path ? element.path : "./#")}>
              {element.name}
            </button>
            <div className="dropdown-menu" data-bs-popper="none">
              {this.getDropend(element.dropend, index)}
            </div>
          </div>
        ) : (
          <button className="topic-menu-btn-color btn-link dropdown-item" onClick={e => this.handleHistory(element.path, index)}>{element.label}</button>
        )}
        {element.bottomDivider === true && <div className="dropdown-divider"></div>}
      </span>
    })
  }

  hasElements(value: NavBarList): boolean {
    let count = 0;
    if (value.elements?.length) {
      value.elements.forEach(element => {
        if (element.dropend) {
          count += this.hasDropdown(element.dropend);
        } else {
          count++;
        }
      });
    }

    if (value.link) {
      count++;
    }

    return count > 0;
  }

  hasDropdown(value: NavBarElement[]): number {
    let count = 0;
    value.forEach(element => {
      if (element.dropend) {
        count += this.hasDropdown(element.dropend);
      } else {
        count++;
      }
    });

    return count;
  }

  handleHistory(path: string = '', index?: number, id?: string) {
    if (index !== undefined) {
      this.dropdownRef[index]?.classList.remove("show")
      this.dropdownMenuRef[index]?.classList.remove("show")
    }
    history.push(`${path}/${id !== undefined ? id : ''}`);
    if (id !== undefined)
      window.location.reload();
  }

  render() {
    const { currentUser, isAdmin, navBar } = this.state;
    const navBarList: Array<NavBarList> = currentUser ? this.getNavList(navBar) : [];

    return (
      currentUser ? (
        <header className="navbar navbar-expand-xl navbar-dark topic-bg-color d-print-none">
          <div style={{ padding: "0 24px" }} className="custom-container d-xl-flex align-items-xl-center">
            <button className="topic-menu-btn-color btn-link d-xl-block d-none align-items-center p-0" onClick={e => this.handleHistory(isAdmin ? "/amministrazione" : "/dipendente/home")}>
              <img className="logo" width="96px" src="/assets/logo-fa-chiaro.png" alt="project-nav-logo" />
            </button>
            <div className="navbar-nav d-none d-xl-flex flex-row order-xl-last justify-content-between">
              <div className="nav-item dropdown">
                <button
                  style={resetButtonsStyle}
                  className="nav-link opacity-90 bg-light d-flex lh-1 px-2 py-1"
                  // title={currentUser.name + ' ' + currentUser.lastname}
                  title={currentUser.username}
                  data-bs-toggle="dropdown"
                  aria-label="Open user menu"
                >
                  {/* <img className="logo" width="96px" src={currentUser.businessunit.logourl} alt="businessunit-nav-logo" /> */}
                  <i style={iconsStyle} className="fa fa-user-circle-o topic-text-color fs-1" aria-hidden="true"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <button className="topic-menu-btn-color btn-link dropdown-item" onClick={e => this.handleHistory("/profile")}>Profilo</button>
                  <div className="dropdown-divider"></div>
                  <button style={resetButtonsStyle} className="dropdown-item" onClick={this.logOut}>Logout</button>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex d-xl-none justify-content-between align-items-center">
              <div className="d-flex user-select-none">
                <button className="navbar-toggler me-2" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <button className="topic-menu-btn-color btn-link d-flex d-xl-none align-items-center" onClick={e => this.handleHistory(isAdmin ? "/amministrazione" : "/dipendente/home")}>
                  <img className="logo" width="96px" src="/assets/logo-fa-chiaro.png" alt="project-nav-logo" />
                </button>
              </div>
              <div className="navbar-nav flex-row order-xl-last justify-content-between">
                <div className="nav-item dropdown">
                  <button style={resetButtonsStyle} className="nav-link rounded d-flex lh-1 p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                    <i style={iconsStyle} className="fa fa-user-circle-o" aria-hidden="true"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <div className="d-flex justify-content-center">
                      {/* <img className="logo" width="96px" src={currentUser.businessunit.logourl} alt="businessunit-nav-logo" /> */}
                    </div>
                    <div className="dropdown-divider"></div>
                    <button className="topic-menu-btn-color btn-link dropdown-item" onClick={e => this.handleHistory("/profile")}>Profilo</button>
                    <div className="dropdown-divider"></div>
                    <button style={resetButtonsStyle} className="dropdown-item" onClick={this.logOut}>Logout</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbar-menu">
              <div className="d-flex flex-column flex-xl-row flex-fill align-items-stretch align-items-xl-center">
                <ul className="navbar-nav">
                  {navBarList && navBarList.map((value, index): ReactNode => {
                    return this.hasElements(value) && (
                      <li key={index} className="nav-item dropdown">
                        {value.dropdown === false ? (
                          <button className="btn-link nav-link border-0" onClick={e => this.handleHistory(value.link)}>
                            <span className="nav-link-title">
                              {value.label}
                            </span>
                          </button>
                        ) : (
                          <>
                            <button ref={ref => this.dropdownRef[index] = ref} className="btn-link nav-link border-0 dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                              <span className="nav-link-title">
                                {value.name}
                              </span>
                            </button>
                            <div ref={ref => this.dropdownMenuRef[index] = ref} className="dropdown-menu">
                              {value.elements && value.elements.map((element: NavBarElement, i: number): ReactNode => {
                                return (
                                  <span key={i}>
                                    {element.topDivider === true && <div className="dropdown-divider"></div>}
                                    {element.dropend && element.dropend?.length ? (
                                      <div key={index} className="dropend">
                                        <button className="topic-menu-btn-color btn-link dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                          {element.name}
                                        </button>
                                        <div className="dropdown-menu" data-bs-popper="none">
                                          {this.getDropend(element.dropend, index)}
                                        </div>
                                      </div>
                                    ) : (
                                      <button className="topic-menu-btn-color btn-link dropdown-item" onClick={e => this.handleHistory(element.path, index)}>{element.label}</button>
                                    )}
                                    {element.bottomDivider === true && <div className="dropdown-divider"></div>}
                                  </span>
                                )
                              }
                              )}
                            </div>
                          </>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </header>
      ) : ('')
    );
  }
}
export default NavigationBar;