import { AxiosResponse } from 'axios';
import axios from '../api';
import { IPractice, IPracticeRow, ICategoryAll, filterFunctionBody, searchFuncionBody } from '../../helpers/interfaces/practices';

const API_URL = process.env.REACT_APP_API_URL;

class GestionePraticheService {

    getPractices(limit: any, offset: any, sort?: any, order?: any): Promise<IPractice> {
        const queryString: string = (sort && order) ?
            'limit=' + limit + '&offset=' + offset + '&sort=' + sort + '&order=' + order : 
            'limit=' + limit + '&offset=' + offset;

        return axios.get<IPractice>(API_URL + 'practices?' + queryString)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getPracticesPerCategoryAll(): Promise<ICategoryAll> {
        return axios.get<ICategoryAll>(API_URL + 'practiceCategories/all')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getPracticesPerCategory(id: any, limit: any, offset: any, sort?: any, order?: any, myPractice?:boolean): Promise<IPractice> {
        const myPracticeValue: number = (myPractice) ? 1 : 0;
        const queryString: string = (sort && order) ?
            'limit=' + limit + '&offset=' + offset + '&sort=' + sort + '&order=' + order + '&mypractice=' + myPracticeValue : 
            'limit=' + limit + '&offset=' + offset + '&mypractice=' + myPracticeValue;

        return axios.get<IPractice>(API_URL + 'practices/category/' + id + '?' + queryString)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getPractice(id: any): Promise<IPracticeRow> {
        return axios.get<IPracticeRow>(API_URL + 'practice/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // addPractice(body: IPracticeBody): Promise<any> {
    //     // const payload = {...body, commercial: Number(body.commercial), part_category: Number(body.part_category)};
    //     return axios.post<any>(API_URL + 'practice', body )
    //         .then((response: AxiosResponse<any>) => { return response.data; })
    //         .catch((error) => { return Promise.reject(error); });
    // }

    addPractice(body: FormData): Promise<any> {
        return axios.post<FormData, AxiosResponse<any>>(API_URL + 'practice', body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // editPractice(id: any, body: IPracticeBody): Promise<any> {
    //     // const payload = {...body, operator: Number(body.operator), commercial: Number(body.commercial), part_category: Number(body.part_category)};
    //     return axios.put<IPracticeBody>(API_URL + 'practice/' + id, body )
    //         .then((response: AxiosResponse<any>) => { return response.data; })
    //         .catch((error) => { return Promise.reject(error); });
    // }

    editPractice(id: string, body: FormData): Promise<any> {
        return axios.put<FormData, AxiosResponse<any>>(API_URL + 'practice/' + id, body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deletePractice(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'practice/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // AZIONI SU SELEZIONATI
    moveSelectedPractices(body: {
        practice_ids: Array<number>,
        category_id?: number,
        commercial_id?: number
    }): Promise<any> {
        return axios.put<any>(API_URL + 'practices/move', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => {
                return Promise.reject(new Error(error));
            });
    }

    deleteSelectedPractices(body: {practice_ids: Array<number>}): Promise<any> {
        return axios.post<any>(API_URL + 'practices/delete', body)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // AZIONI SU DA ESPORTARE
    getPracticesToExport(limit: any, offset: any): Promise<IPractice> {
        return axios.get<IPractice>(API_URL + 'practices/toExport?limit=' + limit + '&offset=' + offset)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getExportSelectedPractices(): Promise<any> {
        return axios.get<any>(API_URL + 'practices/export/csv')
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getExportCurrentCategory(id: any): Promise<any> {
        return axios.get<any>(API_URL + 'practices/category/' + id + '/export/csv')
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getResetToExport(): Promise<any> {
        return axios.get<any>(API_URL + 'practices/resetToExport')
            .then((response: AxiosResponse<any>) => { return response; })
            .catch((error) => { return Promise.reject(error); });
    }

    // FILTRO
    filterFunction(body: filterFunctionBody): Promise<IPractice> {
        return axios.post<any>(API_URL + 'practices/filter', body)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    // RICERCA
    searchFuncion(body: searchFuncionBody): Promise<IPractice> {
        return axios.post<any>(API_URL + 'practices/search', body)
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new GestionePraticheService();