// import ReactDOM from 'react-dom';
// // import Routes from './routes/routes';
// import App from "./App";
// import './index.css';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   // <Routes />,
//   <App />,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import ReactDOM from 'react-dom';
import Routes from './routes/routes';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
