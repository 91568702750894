import * as Yup from "yup";
import { IFormField } from "../../helpers/interfaces/generic";

export function editRouteFormFields(): Array<IFormField> {
  return [
    {
      'label': 'Descrizione',
      'name': 'description',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}
