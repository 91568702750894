import React, { Component } from 'react';
import { orderConfirmationHTML, isContrassegno, isBonifico } from '../helpers/order-confirmation-text';
import { transportOrderHTML } from '../helpers/transport-order-text';
import AutoNumeric from 'autonumeric';
// import DomPurify from 'dompurify';

declare var bootstrap: any;
const $ = require('jquery');

type Props = {
    id?: string;
    documentValues: { [key: string]: any };
    showModal: boolean;
    modalType: string;
    modalTitle: string;
    closeDocumentCallback: CallableFunction;
};

type State = {
    selectedLabel?: string;
    showModal: boolean;
    htmlText: string;
    practiceId: number;
};

class ModalHTMLDocument extends Component<Props, State> {
    innerRefs: { [key: string]: string };
    initialValues: { [key: string]: any };
    isFile: boolean;
    modal: any;
    subtitle: string;

    constructor(props: Props) {
        super(props);
        this.innerRefs = {};
        this.initialValues = {};
        this.isFile = false;

        this.state = {
            showModal: false,
            htmlText: '',
            practiceId: 0
        }

        this.subtitle = ''
        this.modal = null;
    }

    componentDidMount(): void {
        let textString: string = "";
        this.props.modalType === "confirmation" ? textString = orderConfirmationHTML : textString = transportOrderHTML;

        this.setState({ htmlText: textString }, () => {
            this.completeHTMLText();
        });
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
            this.setState({ showModal: true });
        }

        if ((prevProps.modalType !== this.props.modalType && this.props.modalType) ||
            (prevProps.documentValues !== this.props.documentValues && this.props.documentValues)) {
            let textString: string = "";
            this.props.modalType === "confirmation" ? textString = orderConfirmationHTML : textString = transportOrderHTML;

            this.setState({ htmlText: textString }, () => {
                this.completeHTMLText();
            });
        }

        if (prevState.showModal !== this.state.showModal && this.state.showModal) {
            const id = this.props.id ? this.props.id : 'html-document-modal';
            const modalAction = document.getElementById(id);

            if (modalAction) {
                const _self = this;

                modalAction.addEventListener('hidden.bs.modal', function () {
                    _self.setState({ showModal: false }, () => {
                        _self.props.closeDocumentCallback();
                    });
                });

                modalAction.addEventListener('shown.bs.modal', function () {
                    if (!AutoNumeric.isManagedByAutoNumeric(modalAction)) {
                        const autoNumericOptionsEuro: any = {
                            digitGroupSeparator: '.',
                            decimalCharacter: ',',
                            decimalCharacterAlternative: '.',
                            currencySymbol: '€',
                        };
                        AutoNumeric.multiple(`#${id} .autoNumeric`, autoNumericOptionsEuro);
                    }
                });

                this.modal = new bootstrap.Modal(modalAction);
                this.modal.show();
            }
        }
    }

    componentWillUnmount(): void {
        $('.modal-backdrop').remove();
    }

    completeHTMLText() {
        const { modalType, documentValues } = this.props;
        let { htmlText } = this.state;

        let _newHTMLText = '';
        let newHTMLText = '';
        if (modalType === "confirmation") { // conferma d'ordine
            _newHTMLText = htmlText
                .replace("<?php the_ID(); ?>", documentValues.id ?? "")
                .replace("<?php echo the_field('Numero_conferma'); ?>", documentValues.confirmation_number ?? "")
                .replace("<?php echo the_field('Nome'); ?>", documentValues.first_name ?? "")
                .replace("<?php echo the_field('Cognome'); ?>", documentValues.last_name ?? "")
                .replace("<?php echo the_field('Telefono'); ?>", documentValues.phone_number ?? "")
                .replace("<?php echo the_field('Data_conferma'); ?>", documentValues.confirmation_date ?? "")
                .replace("<?php echo the_field('Acconto'); ?>", documentValues.deposit ?? "")
                .replace("<?php echo the_field('Modello'); ?>", documentValues.vehicle ?? "")
                .replace("<?php echo the_field('Anno'); ?>", documentValues.vehicle_year ?? "")
                .replace("<?php echo the_field('Codice_motore'); ?>", documentValues.engine_code ?? "")
                .replace("<?php echo the_field('Cilindrata'); ?>", documentValues.engine_capacity ?? "")
                .replace("<?php echo the_field('Alimentazione'); ?>", documentValues.vehicle_supply ?? "")
                .replace("<?php echo the_field('Ricambio'); ?>", documentValues.part_required ?? "")
                // .replace("<?php echo the_field('Contrassegno'); ?>", documentValues.countersign ?? "")
                // .replace("<?php echo the_field('Mezzo_pagamento'); ?>", documentValues.payment_mean ?? "")
                // .replace("<?php echo the_field('Bonifico'); ?>", documentValues.bank_transfer ?? "")
                .replace("<?php echo the_field('Spese_spedizione'); ?>", documentValues.shipping_costs ?? "");

            if (
                documentValues?.payment_type &&
                documentValues.payment_type.toLowerCase() === 'contrassegno'
            ) {
                let newIsContrassegno: string = '';
                newIsContrassegno = isContrassegno
                    .replace("<?php echo the_field('Contrassegno'); ?>", documentValues.countersign ?? "")
                    .replace("<?php echo the_field('Mezzo_pagamento'); ?>", documentValues.payment_mean ?? "");

                newHTMLText = _newHTMLText.replace("<?php if statement ?>", newIsContrassegno);
            } else if (
                documentValues?.payment_type &&
                documentValues.payment_type.toLowerCase() === 'bonifico'
            ) {
                let newIsBonifico: string = '';
                newIsBonifico = isBonifico.replace("<?php echo the_field('Bonifico'); ?>", documentValues.bank_transfer) ?? "";

                newHTMLText = _newHTMLText.replace("<?php if statement ?>", newIsBonifico);
            } else {
                newHTMLText = _newHTMLText.replace("<?php if statement ?>", "");;
            }

        } else { // ordine di trasporto
            newHTMLText = htmlText
                .replace("<?php the_ID(); ?>", documentValues.id ?? "")
                .replace("<?php echo the_field('Data_spedizione'); ?>", documentValues.shipping_date ?? "")
                .replace("<?php echo the_field('Demolitore'); ?>", documentValues.wrecker ?? "")
                .replace("<?php echo the_field('Modello'); ?>", documentValues.vehicle ?? "")
                .replace("<?php echo the_field('Anno'); ?>", documentValues.vehicle_year ?? "")
                .replace("<?php echo the_field('Codice_motore'); ?>", documentValues.engine_code ?? "")
                .replace("<?php echo the_field('Cilindrata'); ?>", documentValues.engine_capacity ?? "")
                .replace("<?php echo the_field('Alimentazione'); ?>", documentValues.vehicle_supply ?? "")
                .replace("<?php echo the_field('Ricambio'); ?>", documentValues.part_required ?? "")
                .replace("<?php echo the_field('Contrassegno'); ?>", documentValues.countersign ?? "")
                .replace("<?php echo the_field('dati_spedizione'); ?>", documentValues.shipping ?? "")
                .replace("<?php echo the_field('dati_fatturazione'); ?>", documentValues.billing ?? "");
        }

        this.setState({ htmlText: newHTMLText, practiceId: documentValues.id });
    }

    async printDocument() {
        let pageToPrintId: string = "";
        if (this.props.modalType === "confirmation")
            pageToPrintId = "printableTableConferma-" + this.state.practiceId;
        else
            pageToPrintId = "printableTableTrasporto-" + this.state.practiceId;
        
        // apre la finestra di stampa in un altro tab
        // problema: al ritorno non ho il focus sul form
        // const _content: any = document.getElementById(pageToPrintId);
        // if (_content) {
        //     const content = _content.innerHTML;
        //     const printWindow = window.open('', '_blank');
        //     if (printWindow) {
        //         printWindow.document.write('<html><head><title>' + this.props.modalTitle + '</title></head><body>');
        //         printWindow.document.write(content);
        //         printWindow.document.write('</body></html>');
        //         printWindow.document.close();
        //         printWindow.print();
        //     }
        // }

        // apre la finestra di stampa nello stesso tab
        const element: any = document.getElementById(pageToPrintId);
        const _frame: any = "print_frame";
        if (element) {
            window.frames[_frame].document.body.innerHTML = element.innerHTML;
            window.frames[_frame].window.focus();
            window.frames[_frame].window.print();
        }
    }

    render() {
        const { id = "html-document-modal", modalTitle } = this.props;
        const { showModal, htmlText } = this.state;

        return showModal && <React.Fragment>
            <div id={id} className="modal modal-blur fade modal-overlap" role="dialog" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <button type="button" className="topic-empty-btn-color btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                            this.setState({ selectedLabel: '' })
                        }}></button>
                        <div className="modal-body">
                            <h3>
                                {modalTitle}
                            </h3>

                            <div className="card-body">
                                {/* <div dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(htmlText) }} /> */}
                                <div dangerouslySetInnerHTML={{ __html: htmlText }} />

                                <div className="col-12 d-flex justify-content-end mt-5">
                                    <button type="submit"
                                        onClick={() => {
                                            this.printDocument();
                                        }}
                                        className="btn topic-full-btn-color"
                                        // disabled={loading || disabledForm}
                                        style={{ 'backgroundColor': '#ffd700', 'borderColor': '#ffd700', 'color': 'black' }}
                                    >
                                        <span>Stampa</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default ModalHTMLDocument;
