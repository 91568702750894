import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IPracticeCategoryRow } from "../../helpers/interfaces/categories";

export const listPracticeCategoriesColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    // { title: "Codice", data: "code" },
    {
        title: "Codice", "data": null, render: (data: any, type: any, row: IPracticeCategoryRow) => {
            const code = row.parent ? '&#x2014;&emsp;' + row.code : row.code;
            return code;
        }
    },
    // { title: "Nome", data: "name" },
    {
        title: "Nome", "data": null, render: (data: any, type: any, row: IPracticeCategoryRow) => {
            const code = row.parent ? '&#x2014;&emsp;' + row.name : row.name;
            return code;
        }
    },
    {
        title: "Categoria padre", "data": null, render: (data: any, type: any, row: IPracticeCategoryRow) => {
            const parentCategory = row.parent ?? '';
            return parentCategory;
        }
    }
];

export default function getListPracticeCategorieColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            width: "10%",
            render: (id: number, type: string, row: IPracticeCategoryRow) => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${id}" title="Modifica dettagli categoria">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}" title="Elimina categoria">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + trash + '</div>';
            },
        },
        {
            target: 1,
            width: "15%"
        }
    ];
}