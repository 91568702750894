import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IRouteGroup } from "../../helpers/interfaces/admin";

export const listRouteGroupsColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "name" },
    { title: "Descrizione", data: "description" }
];

export default function getRouteGroupsColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IRouteGroup): string => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${id}" title="Modifica dettagli gruppo">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}" title="Elimina gruppo">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;            

                return '<div class="d-flex">' + edit + trash +'</div>';
            },
        },
    ];
}
