import * as Yup from "yup";
import { IFormField } from "../../helpers/interfaces/generic";

const msg_required = "Campo obbligatorio."
const msg_max30 = "La stringa può contenere un massimo di 30 caratteri."

export function addPartCategoryFormFields(): Array<IFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup
        .string()
        .max(30, msg_max30)
        .matches(
          /(?=\S)[^\\]/,
          "Il nome della categoria è obbligatorio e non puo' contenere solo spazi."
        )
        .required(msg_required)
    }
  ];
}

export function editPartCategoryFormFields(): Array<IFormField> {
    return [
      {
        'label': 'Nome',
        'name': 'name',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup
          .string()
          .max(30, msg_max30)
          .matches(
            /(?=\S)[^\\]/,
            "Il nome della categoria è obbligatorio e non puo' contenere solo spazi."
          )
          .required(msg_required)
      }
    ];
  }