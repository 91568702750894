import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IPartCategory } from "../../helpers/interfaces/categories";

export const listPartCategoriesColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "name" }
];

export default function getListPartCategorieColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            width: "10%",
            render: (id: number, type: string, row: IPartCategory) => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${id}" title="Modifica dettagli categoria">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}" title="Elimina categoria">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + trash + '</div>';
            },
        }
    ];
}