import React from 'react';
import AdminService from '../../../services/api/admin.service';
import { IRoute, IGroupObj } from '../../../helpers/interfaces/admin';
import getRoutesColumnDefs, {listRoutesColumns } from '../../../config/table-columns/elenco-rotte';
import { editRouteFormFields } from '../../../config/form-fields/elenco-rotte';
import { IFormField } from '../../../helpers/interfaces/generic';
import ModalForm from '../../../common/ModalForm';
import Table from '../../../common/TableComponent';
import Swal from "sweetalert2";
import $ from 'jquery';
import EventBus from '../../../common/EventBus';

type Props = {};

type State = {
    formFields: IFormField[];
    formInitialValues: { [key: string]: any };
    showModal: boolean;
    modalType: string;
    modalTitle: string;
    routes: Array<IRoute>;
};

export default class ElencoRotte extends React.Component<Props, State> {

    apiSubmit: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            routes: []
        }
    }

    async componentDidMount() {
        this.getRoutesList();

        $('#table_routes.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const routeId = $(e.currentTarget).data('id');
            AdminService.getRoute(routeId)
            .then((response: IRoute) => {
                let idArray: number[] = [];
                response?.groups.map((item: IGroupObj) => idArray.push(item.id));

                this.setState({
                    formFields: editRouteFormFields(),
                    formInitialValues: {id: response.id, description: response.description, groups: idArray}
                }, () => {
                    this.apiSubmit = AdminService.editRoute;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica dettagli rotta' });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Si è verificato un errore',
                    text: 'Non è stato possibile salvare la modifica.',
                    icon: 'error',
                });
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });
        });
    }

    getRoutesList() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        
        AdminService.getRoutes()
        .then((response: IRoute[]) => {
            if (response.length > 0) {
                this.setState({ routes: response });
            }
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });
    }

    closeModal() {
            this.setState({ showModal: false });
    }

    render() {
        const { routes, formFields, formInitialValues, showModal, modalType, modalTitle } = this.state;

        return <div className="custom-container">
            <div className="card">
                <div className="card-body">
                    <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                        <h2 className="card-title">Elenco rotte</h2>
                    </div>
                    <ModalForm
                        showModal={showModal}
                        modalTitle={modalTitle}
                        modalType={modalType}
                        formFields={formFields}
                        initialValues={formInitialValues}
                        apiSubmit={this.apiSubmit}
                        closeCallback={this.closeModal.bind(this)}
                        successAction={this.getRoutesList.bind(this)}
                    />
                    <Table id="table_routes" columns={listRoutesColumns} columnDefs={getRoutesColumnDefs()} datas={routes} buttons={[]} />
                </div>
            </div>
        </div>
    }
}