import React from 'react';
import GestioneUtentiService from '../../../services/api/gestione-utenti.service';
import CategoriePraticheService from '../../../services/api/categorie-pratiche.service';
import PermessiNavigazioneService from '../../../services/api/permessi-navigazione.service'
import { IUser } from '../../../helpers/interfaces/user';
import { IPracticeCategory, IPracticeCategoryRow } from '../../../helpers/interfaces/categories';
import { IAction, IPermission, IPermissionObj } from '../../../helpers/interfaces/permessi-navigazione';
import { IFormFieldOption } from '../../../helpers/interfaces/generic';
import { getCategoriesAuthorizationsColumnDefs, listCategoriesAuthorizationsColumns,
    getActionsAuthorizationsColumnDefs, listActionsAuthorizationsColumns } from '../../../config/table-columns/autorizzazione-navigazione';
import Table from '../../../common/TableComponent';
import Select from 'react-select';
import $ from 'jquery';
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import permessiNavigazioneService from '../../../services/api/permessi-navigazione.service';

type Props = {};

type State = {
    loading: boolean;
    users: Array<IFormFieldOption>;
    categoriesList: Array<IPracticeCategoryRow>;
    actionsList: Array<IAction>;
    categoriesPermissionsList: Array<number>;
    actionsPermissionsList: Array<number>;
    selectedUser: any;
    showTable: boolean;
    data: { [key: string]: number[] };
}

export default class AutorizzazioneNavigazione extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            users: [],
            categoriesList: [],
            actionsList: [],
            categoriesPermissionsList: [],
            actionsPermissionsList: [],
            selectedUser: undefined,
            showTable: false,
            data: {}
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        let utenti: Array<IFormFieldOption> = [];

        Promise.all([
            GestioneUtentiService.getUsers(),
            CategoriePraticheService.getPracticeCategories(),
            PermessiNavigazioneService.getAllUserActions()
        ])
        .then(async (data: [
            IUser[],
            IPracticeCategory,
            IAction[]
        ]) => {
            data[0].map((user: IUser) => {
                const obj = {value: user.id, label: user.first_name + ' ' + user.last_name};
                utenti.push(obj);
                return null;
            });

            if (data[1]?.rows && data[1].rows.length > 0) {
                data[1].rows.map((category: IPracticeCategoryRow) => category.isChecked = false );
            }

            if (data[2] && data[2].length > 0) {
                data[2].map((action: IAction) => action.isChecked = false );
            }

            this.setState({ users: [...utenti], categoriesList: [...data[1].rows], actionsList: [...data[2]] });
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error'
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });
        
        $('#permissions-table').on('change', 'input[type=checkbox]', async (e) => {
            e.preventDefault();

            let { categoriesPermissionsList, actionsPermissionsList } = this.state;
            const userId = $(e.currentTarget).data('user');
            const tableId = $(e.currentTarget).data('table');
            const actionId = $(e.currentTarget).data('id');
            const checked = $(e.currentTarget).is(':checked');
            // const allFlag = $(e.currentTarget).is(':checked');

            if (tableId === 1) { // categories
                if (userId && actionId && categoriesPermissionsList) {
                    const index = categoriesPermissionsList.findIndex(elem => {
                        return elem === actionId;
                    })

                    if (checked && !categoriesPermissionsList[index]) {
                        categoriesPermissionsList.push(actionId);
                    } else if (!checked && categoriesPermissionsList[index]) {
                        categoriesPermissionsList = categoriesPermissionsList.filter((value: number, key: number) => {
                            return key !== index
                        });
                    }
                }
            } else { // actions
                if (userId && actionId && actionsPermissionsList) {
                    const index = actionsPermissionsList.findIndex(elem => {
                        return elem === actionId;
                    })

                    if (checked && !actionsPermissionsList[index]) {
                        actionsPermissionsList.push(actionId);
                    } else if (!checked && actionsPermissionsList[index]) {
                        actionsPermissionsList = actionsPermissionsList.filter((value: number, key: number) => {
                            return key !== index
                        });
                    }
                }
            }

            this.setState({ categoriesPermissionsList, actionsPermissionsList });
        });

        $('#permissions-table').on('click', '.save_btn', async (e: any) => {
            e.preventDefault();

            const { selectedUser, categoriesPermissionsList, actionsPermissionsList } = this.state;

            const id = selectedUser;
            
            if (!id || typeof categoriesPermissionsList === 'undefined' || typeof actionsPermissionsList === 'undefined') {
                Swal.fire('Errore', 'Dati non trovati oppure non validi', 'error');
            } else {
                const payload = {
                    practice_category_ids: Object.values(categoriesPermissionsList),
                    action_ids: Object.values(actionsPermissionsList)
                }

                PermessiNavigazioneService.setPermissionsPerUser(id, payload )
                .then(async (response: any) => {
                    if (typeof response.error === 'undefined') {
                        Swal.fire({
                            title: 'Operazione eseguita.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK',
                        })
                    } else {
                        Swal.fire('Errore', 'Si è verificato un errore durante il salvataggio dei dati', 'error');
                    }
                },
                error => Swal.fire('Errore', error ? error : 'Si è verificato un errore durante il salvataggio dei dati', 'error'))
                .catch(error => Swal.fire('Errore', error ? error : 'Si è verificato un errore imprevisto durante il salvataggio dei dati', 'error'));
            }
        });
    }

    getPermissionsPerUser() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const { selectedUser, categoriesList, actionsList } = this.state;

        const categorie: Array<number> = [];
        const azioni: Array<number> = [];

        permessiNavigazioneService.getPermissionsPerUser(selectedUser)
        .then((response: IPermission) => {
            response.practice_categories.map((elem: IPermissionObj) => categorie.push(elem.id));
            response.actions.map((elem: IPermissionObj) => azioni.push(elem.id));

            this.setState({categoriesPermissionsList: [...categorie], actionsPermissionsList: [...azioni] }, () => {
                categoriesList.map((category: any) => {
                    let isSet: boolean = false;
                    this.state.categoriesPermissionsList.map((elem: number) => {
                        if (category.id === elem) isSet = true;
                        return null;
                    })
                    category.isChecked = isSet;
                    return null;
                });

                actionsList.map((action: any) => {
                    let isSet: boolean = false;
                    this.state.actionsPermissionsList.map((elem: number) => {
                        if (action.id === elem) isSet = true;
                        return null;
                    })
                    action.isChecked = isSet;
                    return null;
                });

                this.setState({ categoriesList: [...categoriesList], actionsList: [...actionsList] });
            })
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error'
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });
    }

    render() {
        const { users, selectedUser, categoriesList, actionsList, showTable } = this.state;

        return <div className="custom-container">
            <div className='card'>
                <div className='card-body'>
                    <h2 className='card-title'>Assegna i permessi di navigazione del gestionale agli utenti</h2>
                    <div id="tab-container" className="row">
                        <div className="col-12 col-md-6">
                            <label className={"margin-0 form-label col-form-label"}>Elenco degli utenti</label>
                            <Select
                                className="topic-border-color"
                                classNamePrefix="select"
                                // defaultValue={categories[0]}
                                // isDisabled={isDisabled}
                                // isLoading={isLoading}
                                // isRtl={isRtl}
                                isClearable={true}
                                isSearchable={true}
                                placeholder={"Seleziona un nome"}
                                name="categories"
                                options={users}
                                // ref={(ref) => {
                                //     this.selectRef1 = ref;
                                // }}
                                onChange={async (event: any) => this.setState({ selectedUser: event ? event.value : undefined}, () => {
                                        this.setState({showTable: !(this.state.selectedUser === undefined)});

                                        if (this.state.selectedUser) {
                                            this.getPermissionsPerUser();
                                        }
                                    })
                                }
                            />
                        </div>
                        {/* <div className="col-12 col-md-6">
                            <label className={"margin-0 form-label col-form-label"} style={{'color': 'transparent'}}>LABEL</label>
                            <button type="submit" className="btn topic-empty-btn-color" disabled={loading}>
                                {loading && (<span className="spinner-border spinner-border-sm me-1"></span>)}
                                <span>Visualizza</span>
                            </button>
                        </div> */}
                    </div>

                    <div id="permissions-table">
                        {showTable &&
                            <div>
                                <div className='row mt-3'>
                                    <div className="col-12 col-md-6">
                                        <label className={"margin-0 form-label col-form-label"}>Elenco delle categorie</label>
                                        <Table
                                            id={"table_categories"}
                                            // className={"table_practices"}
                                            columns={listCategoriesAuthorizationsColumns}
                                            columnDefs={getCategoriesAuthorizationsColumnDefs(selectedUser)}
                                            datas={categoriesList}
                                            buttons={[]}
                                            searching={false}
                                            paging={true}
                                            info={true}
                                            ordering={true}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <label className={"margin-0 form-label col-form-label"}>Elenco delle azioni</label>
                                        <Table
                                            id={"table_actions"}
                                            // className={"table_practices"}
                                            columns={listActionsAuthorizationsColumns}
                                            columnDefs={getActionsAuthorizationsColumnDefs(selectedUser)}
                                            datas={actionsList}
                                            buttons={[]}
                                            searching={false}
                                            paging={true}
                                            info={true}
                                            ordering={true}
                                        />
                                    </div>
                                </div>
                            
                                <div className='row mt-3'>
                                    <div className="col-12 col-md-12 d-flex justify-content-end">
                                        <button className='save_btn btn topic-full-btn-color mt-30'>Salva</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}