import * as Yup from 'yup';
import { IFormField, IFormFieldValue } from "../../helpers/interfaces/generic";

// const msg_required = "Campo obbligatorio."
const msg_max20 = "La stringa può contenere un massimo di 20 caratteri."
const msg_max160 = "La stringa può contenere un massimo di 160 caratteri."
const msg_max255 = "La stringa può contenere un massimo di 255 caratteri."

export function addCustomerFormFields(commerciali: IFormFieldValue[]): Array<IFormField> {
    return [
        {
            'label': 'Nome',
            'name': 'first_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup
                .string()
                .max(255, msg_max255)
                .matches(
                    /(?=\S)[^\\]/,
                    "Il campo è obbligatorio e non puo' contenere solo spazi."
                )
        },
        {
            'label': 'Cognome',
            'name': 'last_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup
                .string()
                .max(255, msg_max255)
                .matches(
                    /(?=\S)[^\\]/,
                    "Il campo è obbligatorio e non puo' contenere solo spazi."
                )
        },
        {
            'label': 'Numero di telefono',
            'name': 'phone_number',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().max(20, msg_max20)
        },
        {
            'label': 'Indirizzo e-mail',
            'name': 'email_address',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().email("Inserire un indirizzo e-mail valido.").max(160, msg_max160)
        },
        {
            'label': 'Data',
            'name': 'date',
            'type': 'date',
            'class': 'form-control',
            'validation': Yup.date()
        },
        {
            'label': 'Broadcast',
            'name': 'broadcast',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().max(255, msg_max255)
        },
        {
            'label': 'Commerciale',
            'name': 'commercial',
            'type': 'select',
            'values': commerciali,
            'class': 'form-select',
            // 'validation': Yup.number().required(msg_required)
        }
    ]
}

export function editCustomerFormFields(commerciali: IFormFieldValue[]): Array<IFormField> {
    return [
        {
            'label': 'Nome',
            'name': 'first_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup
                .string()
                .max(255, msg_max255)
                .matches(
                    /(?=\S)[^\\]/,
                    "Il campo è obbligatorio e non puo' contenere solo spazi."
                )
        },
        {
            'label': 'Cognome',
            'name': 'last_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup
                .string()
                .max(255, msg_max255)
                .matches(
                    /(?=\S)[^\\]/,
                    "Il campo è obbligatorio e non puo' contenere solo spazi."
                )
        },
        {
            'label': 'Numero di telefono',
            'name': 'phone_number',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().max(20, msg_max20)
        },
        {
            'label': 'Indirizzo e-mail',
            'name': 'email_address',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().email("Inserire un indirizzo e-mail valido.").max(160, msg_max160)
        },
        {
            'label': 'Data',
            'name': 'date',
            'type': 'date',
            'class': 'form-control',
            'validation': Yup.date()
        },
        {
            'label': 'Broadcast',
            'name': 'broadcast',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().max(255, msg_max255)
        },
        {
            'label': 'Commerciale',
            'name': 'commercial',
            'type': 'select',
            'values': commerciali,
            'class': 'form-select',
            // 'validation': Yup.number().required(msg_required)
        }
    ]
}
