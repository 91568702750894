import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IUser, IRole } from "../../helpers/interfaces/user";
import { statusColors } from "../../helpers/settings/status-settings";

export const listUsersColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "first_name" },
    { title: "Cognome", data: "last_name" },
    { title: "Username", data: "username" },
    { title: "Email", data: "email_address" },
    {
        title: "Ruolo", "data": null, render: (data: any, type: any, row: IUser) => {
            let ruoli: string = "";
            (row.roles).map((role: IRole) => {
                return ruoli = ruoli === "" ? role.name : ruoli + ', ' + role.name;
            });
            return ruoli;
        }
    },
    { title: "Stato", data: "status" }
];

export default function getListUsersColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            width: "9%",
            render: (id: number, type: string, row: IUser) => {
                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${id}" title="Modifica dettagli utente">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}" title="Elimina utente">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + trash + '</div>';
            },
        },
        {
            target: listUsersColumns.length - 1,
            width: "6%",
            render: (id: number, type: string, row: IUser) => {
                // STATI: E=enabled, S=suspended, D=disabled
                switch (row.status.toLowerCase()) {
                    case 's':
                        return `<div style="width: 16px; height: 16px; margin: auto; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                    case 'd':
                        return `<div style="width: 16px; height: 16px; margin: auto; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; margin: auto; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }
            }
        }
    ];
}