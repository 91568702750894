import * as Yup from 'yup';
import { IFormField, IFormFieldOption } from "../../helpers/interfaces/generic";

const msg_required = "Campo obbligatorio."
const msg_min3 = "La stringa deve contenere un minimo di 3 caratteri."
const msg_max30 = "La stringa può contenere un massimo di 30 caratteri."
const msg_max60 = "La stringa può contenere un massimo di 60 caratteri."

export function addUserFormFields(ruoli: IFormFieldOption[]): Array<IFormField> {
    return [
        {
            'label': 'Nome',
            'name': 'first_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().min(3, msg_min3).max(60, msg_max60).required(msg_required)
        },
        {
            'label': 'Cognome',
            'name': 'last_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().min(3, msg_min3).max(60, msg_max60).required(msg_required)
        },
        {
            'label': 'Username',
            'name': 'username',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().min(3, msg_min3).max(30, msg_max30).required(msg_required)
        },
        {
            'label': 'Password',
            'name': 'password',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup
                .string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{10,})/,
                    "La password deve avere una lunghezza di almeno 10 caratteri, deve contenere almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale."
                )
                .max(60, msg_max60)
                .required(msg_required)
        },
        {
            'label': 'Indirizzo e-mail',
            'name': 'email_address',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().email("Inserire un indirizzo e-mail valido.").required(msg_required)
        },
        {
            'label': 'Ruolo',
            'name': 'roles',
            'type': 'multi-select',
            'options': ruoli,
            'class': 'form-control',
            'validation': Yup.array().min(1, msg_required).required(msg_required)
        },
        {
            'label': 'Stato',
            'name': 'status',
            'type': 'select',
            'values': [{ key: 1, value: 'Enabled' }, { key: 2, value: 'Suspended' }, { key: 3, value: 'Disabled' }],
            'class': 'form-select',
            'validation': Yup.string().required(msg_required)
        }
    ]
}

export function editUserFormFields(ruoli: IFormFieldOption[]): Array<IFormField> {
    return [
        {
            'label': 'Nome',
            'name': 'first_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().min(3, msg_min3).max(60, msg_max60).required(msg_required)
        },
        {
            'label': 'Cognome',
            'name': 'last_name',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().min(3, msg_min3).max(60, msg_max60).required(msg_required)
        },
        {
            'label': 'Username',
            'name': 'username',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().min(3, msg_min3).max(30, msg_max30).required(msg_required)
        },
        {
            'label': 'Indirizzo e-mail',
            'name': 'email_address',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().email("Inserire un indirizzo e-mail valido.").required(msg_required)
        },
        {
            'label': 'Ruolo',
            'name': 'roles',
            'type': 'multi-select',
            'options': ruoli,
            'class': 'form-control',
            'validation': Yup.array().min(1, msg_required).required(msg_required)
        },
        {
            'label': 'Stato',
            'name': 'status',
            'type': 'select',
            'values': [{ key: 1, value: 'Enabled' }, { key: 2, value: 'Suspended' }, { key: 3, value: 'Disabled' }],
            'class': 'form-select',
            'validation': Yup.string().required(msg_required)
        }
    ]
}
