import { AxiosResponse } from 'axios';
import axios from '../api';
import { IUser, IUserBody } from '../../helpers/interfaces/user';

const API_URL = process.env.REACT_APP_API_URL;

class GestioneUtentiService {

    getUsers(): Promise<IUser[]> {
        return axios.get<IUser[]>(API_URL + 'users')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getUser(id: any): Promise<IUser> {
        return axios.get<IUser>(API_URL + 'user/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addUser(body: any): Promise<IUser> {
        const status = body.status === 1 ? 'E' : body.status === 2 ? 'S' : 'D'; 
        const payload: IUserBody = {...body, status: status};
        return axios.post<IUserBody>(API_URL + 'user', payload )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editUser(id: any, body: any): Promise<IUser> {
        const status = body.status === 1 ? 'E' : body.status === 2 ? 'S' : 'D'; 
        const payload: IUserBody = {...body, status: status};
        return axios.put<IUserBody>(API_URL + 'user/' + id, payload )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteUser(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'user/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new GestioneUtentiService();