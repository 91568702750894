import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IFileType } from '../../helpers/interfaces/file-types';

export const listFileTypeColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Tipologia", data: "name" },
    { title: "Codice", data: "code" }
];

export default function getFileTypeColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            width: "15%",
            render: function (data: any, type: any, row: IFileType) {

                const edit = `<a class="edit_btn custom-icon btn topic-empty-btn-color rounded-circle me-1" data-id="${data}" title="Modifica dettagli tipologia">
                                <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                            </a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}" title="Elimina tipologia">
                                <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                            </a>`;

                return '<div class="d-flex">' + edit + trash + '</div>';
            },
        }
    ];
}
