import React, { Component } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { IFormField, IFormFieldOption, IFormFieldValue } from '../helpers/interfaces/generic';
import { Formik, Field, ErrorMessage, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import EventBus from "../common/EventBus";
import AutoNumeric from 'autonumeric';
import DomPurify from 'dompurify';
import DatePickerForm from './DatePickerForm';
import moment from 'moment';
import { base64Encode } from './Base64';

declare var bootstrap: any;
const $ = require('jquery');

type Props = {
    id?: string;
    initialValues: { [key: string]: any };
    formFields: Array<IFormField>;
    showModal: boolean;
    modalType: string;
    modalTitle: string;
    apiSubmit: CallableFunction;
    submitCallback?: CallableFunction;
    closeCallback: CallableFunction;
    successAction?: CallableFunction;
    saveButtonName?: string;
};

type State = {
    showModal: boolean,
    filteredBySearch?: any[],
    selectedLabel?: string
};

class ModalForm extends Component<Props, State> {
    formFields: Array<IFormField>;
    innerRefs: { [key: string]: string };
    initialValues: { [key: string]: any };
    isFile: boolean;
    modal: any;
    subtitle: string;

    constructor(props: Props) {
        super(props);
        this.formFields = props.formFields;
        this.innerRefs = {};
        this.initialValues = {};
        this.isFile = false;

        this.state = {
            showModal: false,
        }

        this.subtitle = ''
        this.modal = null;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if ((prevProps.showModal !== this.props.showModal && this.props.showModal) || (this.props.showModal && prevProps.formFields !== this.props.formFields)) {
            this.formFields = this.props.formFields;
            this.initialValues = this.props.initialValues;

            this.formFields.forEach(
                (value: IFormField) => {
                    let propsValue = this.props.initialValues[value.name];
                    // propsValue = propsValue == false || propsValue == true ? Number(propsValue) : (propsValue || '');
                    this.initialValues[value.name] = propsValue;
                }
            );

            // let parentJoborder = Object.keys(this.props.initialValues).find((element: string) => element === 'parentJoborder');
            // if (parentJoborder) this.subtitle = this.props.initialValues[parentJoborder]
            this.subtitle = this.props.initialValues?.parentJoborder ? this.props.initialValues?.parentJoborder : '';

            let searchField = this.props.formFields.find(field => field.type === 'search')
            if (searchField) this.setState({ filteredBySearch: searchField.values })

            this.setState({ showModal: true });
        }

        if (prevState.showModal !== this.state.showModal && this.state.showModal) {
            const id = this.props.id ? this.props.id : 'modal-form';
            const modalAction = document.getElementById(id);

            if (modalAction) {
                const _self = this;

                modalAction.addEventListener('hidden.bs.modal', function () {
                    _self.setState({ showModal: false }, () => {
                        _self.props.closeCallback();
                    });
                });

                modalAction.addEventListener('shown.bs.modal', function () {
                    if (!AutoNumeric.isManagedByAutoNumeric(modalAction)) {
                        const autoNumericOptionsEuro: any = {
                            digitGroupSeparator: '.',
                            decimalCharacter: ',',
                            decimalCharacterAlternative: '.',
                            currencySymbol: '€',
                        };
                        AutoNumeric.multiple(`#${id} .autoNumeric`, autoNumericOptionsEuro);
                    }
                });

                this.modal = new bootstrap.Modal(modalAction);
                this.modal.show();
            }
        }
    }

    handleSubmit(formValue: any, formik: any) {
        // console.log("MODAL FORM formValue:", formValue);

        let cloneFormValue: any = {};
        for (let key in formValue) {
            cloneFormValue[key] = formValue[key];
        }
        
        if (Object.keys(formValue).filter(elem => elem.toLowerCase() === 'id')) delete cloneFormValue.id;
        const isFormEmpty: boolean = Object.values(cloneFormValue).every(el => el === undefined || el === '');

        if (!isFormEmpty) {
            EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

            const { modalType, initialValues, successAction } = this.props;

            // const getFormData = (formValue: any) => Object.keys(formValue).reduce((formData, key) => {
            //     if (formValue[key]) { // per evitare che nell'oggetto finale vengano inseriti anche i campi con valore non definito
            //         formData.append(key, formValue[key]);
            //     };
            //     return formData;
            // }, new FormData());

            const getFormData = (formValue: any) => Object.keys(formValue).reduce((formData, key) => {
                if (formValue[key] !== null && formValue[key] !== undefined) {  // per evitare che vengano inseriti anche i campi con valore non definito
                    if (Array.isArray(formValue[key])) {
                        formValue[key].map((elem: any, i: number) => {
                            formData.append(key+'['+i+']', formValue[key][i]);
                            return null;
                        });
                    } else {
                        formData.append(key, formValue[key]);
                    }
                };
                return formData;
            }, new FormData());

            const getFormValues = (formValue: any) => Object.keys(formValue).reduce((previous, key, index) => {
                if (this.formFields[index] !== undefined) {
                    if (this.formFields[index].type === 'html') {
                        let rightKey = Object.keys(formValue).find(newKey => newKey === this.formFields[index].name) || ''
                        Object.assign(previous, { ...previous, [rightKey]: base64Encode(DomPurify.sanitize(formValue[rightKey])) })
                    }
                    if (this.formFields[index].type === 'multi-select' && this.formFields[index] !instanceof moment) {
                        Object.assign(previous, { ...previous, [key]: formValue[key].map((option: any) => option.value) })
                    } else {
                        // if (this.formFields[index].name !== 'roles')
                        //     Object.assign(previous, { ...previous, [key]: formValue[key] })
                        // else
                        //     Object.assign(previous, { ...previous, [key]: [formValue[key]] })
                        Object.assign(previous, { ...previous, [key]: formValue[key] });
                    }
                }
                return previous
            }, formValue);

            // const formData = this.isFile ? getFormData(formValue) : formValue;
            const formData = this.isFile ? getFormData(formValue) : getFormValues(formValue);

            if (this.props.submitCallback) {
                EventBus.dispatch("hideLoader");
                const resultCallback = this.props.submitCallback(formData, formik);
                if (resultCallback) { this.modal.hide(); }
                return;
            }

            // const apiSubmit = modalType === 'add' ? this.props.apiSubmit(formData) : this.props.apiSubmit(initialValues.id, formValue);
            const apiSubmit = modalType === 'add' ? this.props.apiSubmit(formData) : this.props.apiSubmit(initialValues.id, formData);

            apiSubmit
            .then(async (response: any) => {
                if (response && response.error) {
                    Swal.fire(
                        'Salvataggio non effettuato.',
                        "Si è verificato un errore.",
                        'error'
                    );
                } else {
                    Swal.fire({
                        title: 'Operazione eseguita.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(async () => {
                        successAction ? await successAction(response) : window.location.reload();
                    });
                }
            }, (error: any) => {
                // let respMessage =
                //     (error.response && error.response.data && error.response.data.message) ||
                //     (error.response && error.response.data && error.response.data.error) ||
                //     (error.response && error.response.data && error.response.data.description) ||
                //     error.message ||
                //     error.toString();
                
                // const stringyfiedRespMessage = respMessage ? JSON.stringify(respMessage) : "";

                // switch (true) {
                //     case (stringyfiedRespMessage.toLowerCase()).includes("cannot update"):
                //         respMessage = "L'operazione non è consentita.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("duplicate entry"):
                //         respMessage = "I dati sono già stati inseriti (anche se in seguito cancellati).";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes(" name is already in the database"): // non cancellare lo spazio davanti a name
                //         respMessage = "Il nome utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("code is already in the database"):
                //         respMessage = "Il codice utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("phone number is already in the database"):
                //         respMessage = "Il numero di telefono utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("address is already in the database"):
                //         respMessage = "L'indirizzo e-mail utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("username is already in the database"):
                //         respMessage = "Lo username utilizzato è già stato inserito.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("e-mail address is already registered to another customer"):
                //         respMessage = "L'indirizzo e-mail utilizzato è già stato associato ad un altro cliente.";
                //         break;
                //     case (stringyfiedRespMessage.toLowerCase()).includes("phone number is already registered to another customer"):
                //         respMessage = "Il numero di telefono utilizzato è già stato associato ad un altro cliente.";
                //         break;
                //     default:
                //         respMessage = "Si è verificato un errore."
                // }

                let respMessage: string = "";

                if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.message) {
                        const errorKeys: string[] = Object.keys(error.response.data.error.message);
        
                        if (errorKeys && errorKeys.length > 0) {
                            errorKeys.map((errorKey: string) => {
                                if (Array.isArray(error.response.data.error.message[errorKey])) {
                                    error.response.data.error.message[errorKey].map((msg: string) => {
                                        respMessage = respMessage === "" ? msg : respMessage + '\n' + msg;
                                        return null;
                                    })
                                } else {
                                    respMessage = respMessage === "" ? 
                                        error.response.data.error.message[errorKey] : respMessage + '\n' + error.response.data.error.message[errorKey];
                                }
                                return null;
                            });
                        };
                    } else if (error.response.data.error.description) {
                        respMessage = error.response.data.error.description;
                    }
                }

                Swal.fire(
                    'Salvataggio non effettuato.',
                    respMessage === "" ? 'Si è verificato un errore' : respMessage,
                    'warning'
                );
            })
            .catch(() => {
                Swal.fire(
                    'Operazione non completata.',
                    'Si è verificato un errore. Non è stato possibile effettuare il salvataggio dei dati.',
                    'error'
                );
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });

            this.modal.hide();
        } else {
             Swal.fire({
                title: 'Salvataggio non effettuato.',
                text: "Compilare almeno un campo.",
                icon: 'warning',
            });
        }
    }

    handleSearchClick(searchValue: string, allElements: IFormFieldValue[]) {
        this.setState(
            {
                selectedLabel: searchValue
            },
            () => {
                const all = allElements
                if (searchValue !== "") {
                    const filtered = all.filter(
                        (option) => {
                            const value = searchValue.toLowerCase()
                            const name = option.value.toLowerCase();

                            return value && name.includes(value)
                        })
                    this.setState({ filteredBySearch: filtered })
                } else {
                    this.setState({ filteredBySearch: all })
                }

                if ($("#search").is(':focus')) {
                    $('#search').addClass('show')
                    $('#menu').addClass('show')
                }
                else {
                    $('#search').removeClass('show')
                    $('#menu').removeClass('show')
                }
            }
        )
    }

    validationSchema() {
        let validations: any = {}
        this.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    componentWillUnmount(): void {
        $('.modal-backdrop').remove();
    }

    renderField(item: IFormField, key: number, formik: FormikProps<any>) {
        const { filteredBySearch, selectedLabel = '' } = this.state
        const value = !isNaN(Number(formik.values[item.showFromFieldName ?? ''])) ? Number(formik.values[item.showFromFieldName ?? '']) : formik.values[item.showFromFieldName ?? ''];
        const valueIsPresent: string = (item.showFromFieldName && formik.values[item.showFromFieldName] && formik.values[item.showFromFieldName] > 0) ? 'true' : 'false';

        const displayItem = ((item.showFromFieldName && item.showFromFieldValue?.includes(value)) ||
            (item.showFromFieldName && item.showFromFieldType?.toString().includes(valueIsPresent)) ||
            typeof item.showFromFieldName == 'undefined');
        formik.initialValues[item.name] = formik.initialValues[item.name] ?? item.value;

        if (!displayItem || item.hidden) {
            return null;
        }

        switch (item.type) {
            case 'unset':
                return null
            case 'search':
                return <React.Fragment>
                    <div className="nav-item dropdown">
                        <input
                            id="search"
                            type={item.type}
                            name={item.name}
                            value={selectedLabel}
                            className="form-control w-100"
                            data-bs-toggle="dropdown"
                            placeholder={item.placeholder ?? "Cerca un elemento..."}
                            onChange={event => {
                                item.values && this.handleSearchClick(event.target.value, item.values)
                            }}
                        />

                        <div id="menu" className="dropdown-menu w-100 dropdown-menu-arrow dropdown-menu-card">
                            <div className="search-list">
                                {
                                    filteredBySearch !== undefined && filteredBySearch.length > 0 && filteredBySearch.map(
                                        option =>
                                            <div key={option.key} className="dropdown-item" onClick={async () => {
                                                formik.setFieldValue(item.name, option.key)
                                                this.setState(
                                                    {
                                                        selectedLabel: option.value
                                                    },
                                                    () => item.values && this.handleSearchClick(option.value, item.values)
                                                )
                                            }}>
                                                {option.value}
                                            </div>
                                    )
                                }
                                {
                                    filteredBySearch !== undefined && filteredBySearch.length === 0 && <span className="dropdown-item">Nessun risultato</span>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            case 'select':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Field
                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                            as={item.type}
                            name={item.name}
                            className={item.class} 
                            value={formik.values[item.name]}
                            disabled={item.disabled}
                            onChange={async (event: any) => {
                                formik.setFieldValue(item.name, Number(event.target.value))  // N.B. il be aspetta interi sempre?
                                if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                    await item.updateCallback(event.target.value, formik);
                                }
                            }}
                        >
                            <option key={''} value={''}>{item.value ?? '----'}</option>
                            {item.values?.map((item: IFormFieldValue) => {
                                return <option key={item.key} value={item.key} defaultValue={item.defaultValue} disabled={item.disabled}>{item.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>
            case 'multi-select':
                const multiSelectValue = item.options?.filter((option: IFormFieldOption) => {
                    return formik.values[item.name] && formik.values[item.name].find((value: any) => {
                        return value === option.value
                    })
                });

                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Select
                            options={item.options}
                            isMulti={true}
                            name={item.name}
                            defaultValue={multiSelectValue}
                            isDisabled={item.disabled}
                            onChange={async (values: MultiValue<IFormFieldOption>) => {
                                // formik.setFieldValue(item.name, values.length && values.map((item: IFormFieldOption) => item.value));
                                formik.setFieldValue(item.name, values.length ? values.map((item: IFormFieldOption) => item.value) : []);
                                if (
                                    typeof item.updateField !== 'undefined' &&
                                    typeof item.updateCallback !== 'undefined'
                                ) {
                                    await item.updateCallback(values, formik);
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
            case 'search-select':
                const searchSelectValue = item.options?.filter((option: IFormFieldOption) => {
                    return formik.values[item.name] && formik.values[item.name] === option.value
                });

                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Select
                            name={item.name}
                            isSearchable={true}
                            isClearable={true}
                            // isOptionDisabled={(option) => option.disabled ?? false}
                            placeholder={item.placeholder}
                            defaultValue={searchSelectValue}
                            value={item.options?.find(el => el.value === formik.values[item.name])}
                            options={item.options}
                            isDisabled={item.disabled}
                            onChange={async (option: SingleValue<IFormFieldOption>) => {
                                formik.setFieldValue(item.name, option ? option.value : '');
                
                                if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                    await item.updateCallback(option?.value, formik);
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
            case 'file':
                this.isFile = true;
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <input
                            ref={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type={item.type}
                            className={item.class}
                            onChange={(event: any) => {
                                formik.setFieldValue(item.name, event.currentTarget.files[0]);
                            }}
                            title='Select a file'
                            disabled={item.disabled}
                        />
                    </div>
                </React.Fragment>
            case 'files':
                this.isFile = true;
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <input
                            ref={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type='file'
                            multiple={true}
                            className={item.class}
                            onChange={(event: any) => {
                                formik.setFieldValue(item.name, event.currentTarget.files[0]);
                            }}
                            title='Select files'
                            disabled={item.disabled}
                        />
                    </div>
                </React.Fragment>
            case 'radio':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        {item.values?.map((value: IFormFieldValue, key: number) => {
                            return <label key={value.key}>
                                <Field
                                    type="radio"
                                    name={item.name}
                                    value={value.key}
                                    disabled={item.disabled}
                                />
                                {value.value}
                            </label>
                        })}
                    </div>
                </React.Fragment>
            case 'checkbox':
                return displayItem && <label className={"form-check form-switch" + (item.labelClass ? ' ' + item.labelClass : '')}>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        name={item.name}
                        type={item.type}
                        className={item.class}
                        disabled={item.disabled}
                    />
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                </label>
            case 'number':
                return displayItem && <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        type={item.type}
                        name={item.name}
                        className={item.class}
                        min={item.min}
                        max={item.max}
                        disabled={item.disabled}
                        onChange={async (event: any) => {
                            formik.setFieldValue(item.name, event.target.value); //Number(event.target.value)
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.target.value, formik);
                            }
                        }}
                    />
                </label>
            case 'time':
                return displayItem && <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                    <div className='row'>
                        <div className='col'>
                            <Field
                                innerRef={(el: any) => this.innerRefs[item.name + '_hh'] = el}
                                type={'number'}
                                name={item.name + '_hh'}
                                className={item.class}
                                min={0}
                                max={23}
                                disabled={item.disabled}
                                onChange={async (event: any) => {
                                    formik.setFieldValue(item.name + '_hh', event.target.value)
                                    if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                        await item.updateCallback(event.target.value, formik);
                                    }
                                }}
                            />
                        </div>
                        <div className='col'>
                            <Field
                                innerRef={(el: any) => this.innerRefs[item.name + '_mm'] = el}
                                as={item.minuteType === 'select' ? 'select' : 'input'}
                                type={item.minuteType !== 'select' ? 'number' : undefined}
                                name={item.name + '_mm'}
                                className={item.class}
                                min={0}
                                max={59}
                                disabled={item.disabled}
                                onChange={async (event: any) => {
                                    formik.setFieldValue(item.name + '_mm', event.target.value)
                                    if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                        await item.updateCallback(event.target.value, formik);
                                    }
                                }}
                            >
                                {item.values?.map((item: IFormFieldValue) => {
                                    return <option key={item.key} value={item.key}>{item.value}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                </label>
            case 'datetime':
                return <>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>
                        {item.label}
                    </label>
                    <DatePickerForm
                        name={item.name}
                        className={item.class}
                        dataFormat={'dd/MM/yyyy HH:mm'}
                        readValue={formik.values[item.name]}
                        valueFormat={'yyyy-MM-DDTHH:mm:ssZ'}
                        shouldCloseOnSelect
                        showTimeSelect
                        timeIntervals={15}
                        timeFormat='HH:mm'
                        disabled={item.disabled}
                        onChange={async (event) => {
                            formik.setFieldValue(item.name, event.value);

                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.value, formik);
                            }
                        }}
                    />
                </>
            case 'textarea':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        name={item.name}
                        as={item.type}
                        className={item.class}
                        rows="5"
                        disabled={item.disabled}
                        onChange={async (event: any) => {
                            formik.setFieldValue(item.name, event.target.value)
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.value, formik);
                            }
                        }}
                    />
                </React.Fragment>
            case 'html':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <Field
                        innerRef={(el: any) => this.innerRefs[item.name] = el}
                        name={item.name}
                        as={'textarea'}
                        className={item.class}
                        rows="5"
                        disabled={item.disabled}
                    />
                    {
                        formik.values[item.name] && <div className='mt-2'>
                            <h4>Anteprima del risultato</h4>
                            <div className='border border-1 p-3' dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(formik.values[item.name]) }}></div>
                        </div>
                    }
                </React.Fragment>
            default:
                //return <Field innerRef={(el: any) => this.innerRefs[item.name] = el} name={item.name} type={item.type} className={item.class} />
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Field
                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type={item.type}
                            className={item.class}
                            value={formik.values[item.name] ?? ''}
                            disabled={item.disabled}
                            onChange={async (event: any) => {
                                formik.setFieldValue(item.name, event.target.value)
                                if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                    await item.updateCallback(event.target.value, formik);
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
        }
    }

    render() {
        const { id = "modal-form", modalTitle, initialValues, saveButtonName } = this.props;
        const { showModal } = this.state;

        return showModal && <React.Fragment>
            <div id={id} className="modal modal-blur fade" role="dialog" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="topic-empty-btn-color btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                            this.setState({ selectedLabel: '' })
                        }}></button>
                        <div className="modal-body">
                            <h3>
                                {modalTitle}
                                {
                                    this.subtitle !== '' && <span className='ms-1'>
                                        figlia di {initialValues.parentJoborder}
                                    </span>
                                }
                            </h3>
                            <Formik
                                initialValues={this.initialValues}
                                validationSchema={this.validationSchema.bind(this)}
                                onSubmit={this.handleSubmit.bind(this)}
                            >
                                {(formik: FormikProps<{[key: string]: any;}>) => {
                                    return <Form>
                                        {
                                            this.formFields && this.formFields.map((item: IFormField, key: number) => {
                                                return <div key={key} id={`${id}-${item.name}`} className="form-group my-2">

                                                    {
                                                        this.renderField(item, key, formik)
                                                    }

                                                    <ErrorMessage
                                                        name={item.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            })
                                        }
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn topic-full-btn-color mt-30">
                                                <span>{saveButtonName ? saveButtonName : 'Salva'}</span>
                                            </button>
                                        </div>
                                    </Form>
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default ModalForm;
