import { AxiosResponse } from 'axios';
import axios from '../api';
import { IPartCategory, IPartCategoryRow, IPartCategoryBody } from '../../helpers/interfaces/categories';

const API_URL = process.env.REACT_APP_API_URL;

class CategorieRicambioService {

    getPartCategories(): Promise<IPartCategory> {
        return axios.get<IPartCategory>(API_URL + 'partCategories')
            .then((response: AxiosResponse<any>) => { return response.data.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    getPartCategory(id: any): Promise<IPartCategoryRow> {
        return axios.get<IPartCategoryRow>(API_URL + 'partCategory/' + id)
            .then((response: AxiosResponse<any>) => { return response.data.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addPartCategory(body: IPartCategoryBody): Promise<IPartCategoryRow> {
        return axios.post<IPartCategoryBody>(API_URL + 'partCategory', body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editPartCategory(id: any, body: IPartCategoryBody): Promise<IPartCategoryRow> {
        return axios.put<IPartCategoryBody>(API_URL + 'partCategory/' + id, body )
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deletePartCategory(id: any): Promise<any> {
        return axios.delete<any>(API_URL + 'partCategory/' + id)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}
export default new CategorieRicambioService();