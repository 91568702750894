import React from 'react';
import GestioneUtentiService from '../../services/api/gestione-utenti.service';
import TipologieRuoliService from '../../services/api/tipologie-ruoli.service';
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import getListUsersColumnDefs, { listUsersColumns } from '../../config/table-columns/gestione-utenti';
import { addUserFormFields, editUserFormFields } from '../../config/form-fields/gestione-utenti';
import { IRole, IUser, ILoggedUser } from '../../helpers/interfaces/user';
import { IFormField, IFormFieldOption } from '../../helpers/interfaces/generic';
import Swal from "sweetalert2";
import EventBus from '../../common/EventBus';

const $ = require('jquery');

type Props = {}

type State = {
    formFields: Array<IFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    utenti: Array<IUser>,
    ruoli: IFormFieldOption[],
    hasPermissions: boolean
}

export default class GestioneUtenti extends React.Component<Props, State> {

    apiSubmit: any;

    constructor(props: Props) {
        super(props)

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            utenti: [],
            ruoli: [],
            hasPermissions: false
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const userString: any = localStorage.getItem("user");
        const currentUser: ILoggedUser = JSON.parse(userString);
        currentUser.roles.map((role: IRole) => {
            // if (role.code.toLocaleLowerCase() === "adm") this.setState({hasPermissions: true});
            this.setState({hasPermissions: true});
            return null;
        });

        Promise.all([
            GestioneUtentiService.getUsers(),
            TipologieRuoliService.getRoles()
        ])
        .then(async (data: [
            IUser[],
            IRole[]
        ]) => {
            this.setState({ utenti: data[0] });

            if (data[1] && data[1].length > 0) {
            const roles: IFormFieldOption[] = data[1].map((role: IRole) => { return { value: role.id, label: role.name } });
            this.setState({ ruoli: roles});
            }
        })
        .catch(() => {
            Swal.fire({
                title: 'Si è verificato un errore.',
                text: 'Non è stato possibile recuperare i dati.',
                icon: 'error',
            });
        })
        .finally(() => {
            EventBus.dispatch("hideLoader");
        });

        $('#table_utenti').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

            const utenteId = $(e.currentTarget).data('id');
            const utenteDettagli = await GestioneUtentiService.getUser(utenteId);

            const status = utenteDettagli.status.toUpperCase() === 'E' ? 1 : utenteDettagli.status.toUpperCase() === 'S' ? 2 : 3;
            const roles = utenteDettagli.roles && utenteDettagli.roles.length > 0 ?
                utenteDettagli.roles.map((role: IRole) => role.id) : [];

            if (utenteDettagli) {
                this.setState({
                    formFields: editUserFormFields(this.state.ruoli),
                    formInitialValues: { ...utenteDettagli, roles: roles, status: status }
                }, () => {
                    this.apiSubmit = GestioneUtentiService.editUser;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica i dati utente' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_utenti').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Confermi l\'eliminazione?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idUtente = $(e.currentTarget).data('id');
                    const utente: any = await GestioneUtentiService.deleteUser(idUtente);
                    if (utente && typeof utente.error !== 'undefined') {
                        Swal.fire(
                            utente.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    async openAddModale() {
        this.setState({
            formFields: addUserFormFields(this.state.ruoli),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = GestioneUtentiService.addUser;
            this.setState({ showModal: true, modalType: 'add', modalTitle: 'Aggiungi un nuovo utente' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { formFields, formInitialValues, showModal, modalType, modalTitle, utenti, hasPermissions } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className="custom-container">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Elenco utenti</h2>
                                {
                                    hasPermissions &&
                                    <button id="add_btn" type="button" className="btn topic-empty-btn-color" onClick={() => this.openAddModale()}>
                                        <span>Aggiungi utente</span>
                                    </button>
                                }   
                            </div>
                            <ModalForm
                                showModal={showModal}
                                modalTitle={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                            <Table id="table_utenti" columns={listUsersColumns} columnDefs={getListUsersColumnDefs()} datas={utenti} buttons={buttons} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}