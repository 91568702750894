import { Component } from "react";
// import authService from "../services/auth.service";
import { History } from 'history';

type Props = {
    history: History;
};

type State = {};

export default class SetUserHome extends Component<Props, State>
{
    async componentDidMount(): Promise<void> {
        // const currentUser = await authService.getCurrentUser();

        // if (currentUser) {
        //   if (currentUser?.routes.includes('//inserire-testo//')) {
        //     this.props.history.push("/admin/home");
        //   } else {
        //     this.props.history.push("/user/home");
        //   }
        // }

        this.props.history.push("/dashboard");
    }

    
    render() {
        return <></>;
    }
}