import * as Yup from 'yup';
import { IFormField, IFormFieldValue } from "../../helpers/interfaces/generic";

const msg_required = "Campo obbligatorio."
const msg_max = "E' stato superato il limite massimo di caratteri consentiti."
// const msg_max160 = "La stringa può contenere un massimo di 160 caratteri."
// const msg_max255 = "La stringa può contenere un massimo di 255 caratteri."

export function addPraticheFormFields(
        // operatori: IFormFieldValue[],
        // categoriePratiche: IFormFieldValue[],
        categorieRicambio: IFormFieldValue[],
        // elencoClienti: IFormFieldOption[],
        affiliazione: IFormFieldValue[]
    ): Array<IFormField> {
    return [
        { // 0
            'label': 'Da esportare',
            'name': 'to_export',
            'type': 'select',
            'values': [{ key: 1, value: 'SI' }, { key: 0, value: 'NO' }],
            'class': 'form-select',
            'validation': Yup.string().required(msg_required)
        },
        // {
        //     'label': 'Da esportare',
        //     'name': 'to_export',
        //     'type': 'radio',
        //     'values': [{ key: 1, value: 'selectyexport' }, { key: 0, value: 'ignore_x_export' }],
        //     'class': 'form-control',
        //     // 'validation': Yup.string().required(msg_required)
        // },
        // quando una pratica viene creata l'operatore (l'utente che ha fatto il login) viene assegnato dal BE
        // {
        //     'label': 'Operatore',
        //     'name': 'operator',
        //     'type': 'select',
        //     'values': operatori,
        //     'class': 'form-select',
        //     'validation': Yup.string().required(msg_required)
        // },
        { // 1
            'label': 'Data pratica',
            'name': 'date',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.date().max(255, msg_max255).required(msg_required)
        },
        { // 2
            'label': 'Fonte',
            'name': 'source',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        // quando una pratica viene creata le viene assegnata la categoria 'Nuove richieste' dal BE
        // {
        //     'label': 'Categoria pratica',
        //     'name': 'category_id',
        //     'type': 'select',
        //     'values': categoriePratiche,
        //     'class': 'form-select',
        //     // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        // },
        { // 3
            'label': 'Categoria ricambio',
            'name': 'part_category',
            'type': 'select',
            'values': categorieRicambio,
            'class': 'form-select',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 4
            'label': 'Ricambio richiesto',
            'name': 'part_required',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 5
            'label': 'Codice motore',
            'name': 'engine_code',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 6
            'label': 'Veicolo',
            'name': 'vehicle',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 7
            'label': 'Anno veicolo',
            'name': 'vehicle_year',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 8
            'label': 'Cilindrata veicolo',
            'name': 'engine_capacity',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 9
            'label': 'Alimentazione',
            'name': 'vehicle_supply',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 10
            'label': 'Nome',
            'name': 'first_name',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 11
            'label': 'Cognome',
            'name': 'last_name',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 12
            'label': 'E-mail',
            'name': 'email_address',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().email("Inserire un indirizzo e-mail valido.").max(160, msg_max160).required(msg_required)
        },
        { // 13
            'label': 'Telefono',
            'name': 'phone_number',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        // {
        //     'label': 'Cliente',
        //     'name': 'customer_id',
        //     'type': 'search-select',
        //     'options': elencoClienti,
        //     'placeholder': 'Digita la stringa da cercare',
        //     'class': 'form-select',
        //     // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        // },
        { // 14
            'label': 'Codice sconto',
            'name': 'discount_code',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 15
            'label': 'Provincia',
            'name': 'province',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 16
            'label': 'Affiliazione',
            'name': 'commercial',
            'type': 'select',
            'values': affiliazione,
            'class': 'form-select',
            // 'validation': Yup.string().required(msg_required)
        },
        { // 17
            'label': 'Note ricambio + informazioni cliente',
            'name': 'part_notes',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 18
            'label': 'Risposte',
            'name': 'replies',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 19
            'label': 'Informazioni pratica',
            'name': 'informations',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 20
            'label': 'Dati spedizione',
            'name': 'shipping',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 21
            'label': 'Dati fatturazione',
            'name': 'billing',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 22
            'label': 'Data conferma',
            'name': 'confirmation_date',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.date().required(msg_required)
        },
        { // 23
            'label': 'Acconto',
            'name': 'deposit',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 24
            'label': 'Metodo di pagamento',
            'name': 'payment_type',
            'type': 'select',
            'values': [{
                key: 'CONTRASSEGNO', value: 'CONTRASSEGNO'
            }, {
                key: 'BONIFICO', value: 'BONIFICO'
            }],
            'class': 'form-select',
            // 'validation': Yup.string().required(msg_required)
        },
        { // 25
            'label': 'Contrassegno',
            'name': 'countersign',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 26
            'label': 'Bonifico',
            'name': 'bank_transfer',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 27
            'label': 'Spese spedizione',
            'name': 'shipping_costs',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 28
            'label': 'Mezzo pagamento',
            'name': 'payment_mean',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 29
            'label': 'N° conferma',
            'name': 'confirmation_number',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 30
            'label': 'Data spedizione',
            'name': 'shipping_date',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.date().required(msg_required)
        },
        { // 31
            'label': 'Demolitore',
            'name': 'wrecker',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 32
            'label': 'N° fattura',
            'name': 'invoice_number',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 33
            'label': 'Allegati',
            'name': 'files',
            'type': 'attachment',
            'class': 'form-control'
            // 'validation': Yup.string().required("Campo obbligatorio")
        }
    ]
}

export function editPraticheFormFields (
    operatori: IFormFieldValue[],
    categoriePratiche: IFormFieldValue[],
    categorieRicambio: IFormFieldValue[],
    // elencoClienti: IFormFieldOption[],
    affiliazione: IFormFieldValue[],
    // isDisabled: boolean
): Array<IFormField> {
    // console.log("CUSTORE FORM FIELDS DISABLED", isDisabled);

    return [
        { // 0
            'label': 'Da esportare',
            'name': 'to_export',
            'type': 'select',
            'values': [{ key: 1, value: 'SI' }, { key: 0, value: 'NO' }],
            'class': 'form-select',
            'validation': Yup.string().required(msg_required)
        },
        { // 1
            'label': 'Operatore',
            'name': 'operator',
            'type': 'select',
            'values': operatori,
            'class': 'form-select',
            'validation': Yup.string().required(msg_required)
        },
        { // 2
            'label': 'Data pratica',
            'name': 'date',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.date().max(255, msg_max255).required(msg_required)
        },
        { // 3
            'label': 'Fonte',
            'name': 'source',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 4
            'label': 'Categoria pratica',
            'name': 'category_id',
            'type': 'select',
            'values': categoriePratiche,
            'class': 'form-select',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 5
            'label': 'Categoria ricambio',
            'name': 'part_category',
            'type': 'select',
            'values': categorieRicambio,
            'class': 'form-select',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 6
            'label': 'Ricambio richiesto',
            'name': 'part_required',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 7
            'label': 'Codice motore',
            'name': 'engine_code',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 8
            'label': 'Veicolo',
            'name': 'vehicle',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 9
            'label': 'Anno veicolo',
            'name': 'vehicle_year',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 10
            'label': 'Cilindrata veicolo',
            'name': 'engine_capacity',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 11
            'label': 'Alimentazione',
            'name': 'vehicle_supply',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 12
            'label': 'Nome',
            'name': 'first_name',
            'type': 'text',
            'class': 'form-control',
            // 'disabled': isDisabled
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 13
            'label': 'Cognome',
            'name': 'last_name',
            'type': 'text',
            'class': 'form-control',
            // 'disabled': isDisabled
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 14
            'label': 'E-mail',
            'name': 'email_address',
            'type': 'text',
            'class': 'form-control',
            // 'disabled': isDisabled
            // 'validation': Yup.string().email("Inserire un indirizzo e-mail valido.").max(160, msg_max160).required(msg_required)
        },
        { // 15
            'label': 'Telefono',
            'name': 'phone_number',
            'type': 'text',
            'class': 'form-control',
            // 'disabled': isDisabled
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        // {
        //     'label': 'Cliente',
        //     'name': 'customer_id',
        //     'type': 'search-select',
        //     'options': elencoClienti,
        //     'class': 'form-select',
        //     // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        // },
        { // 16
            'label': 'Codice sconto',
            'name': 'discount_code',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(255, msg_max255).required(msg_required)
        },
        { // 17
            'label': 'Provincia',
            'name': 'province',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 18
            'label': 'Affiliazione',
            'name': 'affiliate',
            'type': 'select',
            'values': affiliazione,
            'class': 'form-select',
            // 'validation': Yup.string().required(msg_required)
        },
        { // 19
            'label': 'Note ricambio + informazioni cliente',
            'name': 'part_notes',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 20
            'label': 'Risposte',
            'name': 'replies',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 21
            'label': 'Informazioni pratica',
            'name': 'informations',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 22
            'label': 'Dati spedizione',
            'name': 'shipping',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 23
            'label': 'Dati fatturazione',
            'name': 'billing',
            'type': 'textarea',
            'class': 'form-control',
            'validation': Yup.string().max(1000000000, msg_max)
        },
        { // 24
            'label': 'Data conferma',
            'name': 'confirmation_date',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.date().required(msg_required)
        },
        { // 25
            'label': 'Acconto',
            'name': 'deposit',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 26
            'label': 'Metodo di pagamento',
            'name': 'payment_type',
            'type': 'select',
            'values': [{
                key: 'CONTRASSEGNO', value: 'CONTRASSEGNO'
            }, {
                key: 'BONIFICO', value: 'BONIFICO'
            }],
            'class': 'form-select',
            // 'validation': Yup.string().required(msg_required)
        },
        { // 27
            'label': 'Contrassegno',
            'name': 'countersign',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 28
            'label': 'Bonifico',
            'name': 'bank_transfer',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 29
            'label': 'Spese spedizione',
            'name': 'shipping_costs',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 30
            'label': 'Mezzo pagamento',
            'name': 'payment_mean',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 31
            'label': 'N° conferma',
            'name': 'confirmation_number',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 32
            'label': 'Data spedizione',
            'name': 'shipping_date',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.date().required(msg_required)
        },
        { // 33
            'label': 'Demolitore',
            'name': 'wrecker',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 34
            'label': 'N° fattura',
            'name': 'invoice_number',
            'type': 'text',
            'class': 'form-control',
            // 'validation': Yup.string().max(20, msg_max20).required(msg_required)
        },
        { // 35
            'label': 'Allegati',
            'name': 'files',
            'type': 'attachment',
            'class': 'form-control'
            // 'validation': Yup.string().required("Campo obbligatorio")
        }
    ]
}
