import * as Yup from "yup";
import { IFormField, IFormFieldValue } from "../../helpers/interfaces/generic";

const msg_required = "Campo obbligatorio."
const msg_min3 = "La stringa deve contenere un minimo di 3 caratteri."
const msg_max30 = "La stringa può contenere un massimo di 30 caratteri."

export function addPracticeCategoryFormFields(categorie: IFormFieldValue[]): Array<IFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup
        .string()
        .max(30, msg_max30)
        .matches(
          /(?=\S)[^\\]/,
          "Il nome della categoria è obbligatorio e non puo' contenere solo spazi."
        )
        .required(msg_required)
    },
    {
      'label': 'Categoria padre',
      'name': 'parent_id',
      'type': 'select',
      'values': categorie,
      'class': 'form-select'
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().min(3, msg_min3).max(30, msg_max30).required(msg_required)
    }
  ];
}

export function editPracticeCategoryFormFields(categorie: IFormFieldValue[]): Array<IFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup
        .string()
        .max(30, msg_max30)
        .matches(
          /(?=\S)[^\\]/,
          "Il nome della categoria è obbligatorio e non puo' contenere solo spazi."
        )
        .required(msg_required)
    },
    {
      'label': 'Categoria padre',
      'name': 'parent',
      'type': 'select',
      'values': categorie,
      'class': 'form-select',
      'disabled': true
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().min(3, msg_min3).max(30, msg_max30).required(msg_required)
    }
  ];
}