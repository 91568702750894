import { AxiosResponse } from 'axios';
import { IRoute, IRouteBody, IRouteGroup, IRouteGroupBody, IRouteSync, IRouteToRouteGroup, IUserToRouteGroup, IRouteGroupSync, IRouteObj } from '../../helpers/interfaces/admin';
import { IRole} from '../../helpers/interfaces/user';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class AdminService {

  // ROUTES
  getRoutes(): Promise<Array<IRoute>> {
    return axios.get(API_URL + 'routes')
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error: any) => { return Promise.reject(error); });
  }

  getRoute(id: any): Promise<IRoute> {
    return axios.get(API_URL + 'route/' + id)
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error: any) => { return Promise.reject(error); });
  }

  editRoute(id: any, body: IRouteBody): Promise<IRoute> {
    return axios.put(API_URL + 'route/' + id, body)
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  syncRoute(): Promise<IRouteSync> {
    return axios.get(API_URL + 'routes/sync')
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error: any) => { return Promise.reject(error); });
  }

  // ROUTES GROUPS
  getRouteGroups(): Promise<IRouteGroup[]> {
    return axios.get<IRouteGroup[]>(API_URL + 'routeGroups')
      .then((response: AxiosResponse<any>) => { return Array.isArray(response.data.data) ? response.data.data : []; })
      .catch((error: any) => { return Promise.reject(error); });
  }

  getRouteGroup(id: any): Promise<IRouteGroup> {
    return axios.get<IRouteGroup>(API_URL + 'routeGroup/' + id)
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error: any) => { return Promise.reject(error); });
  }

  addRouteGroup(body: IRouteGroupBody): Promise<IRouteGroup> {
      return axios.post<any>(API_URL + 'routeGroup', body )
          .then((response: AxiosResponse<any>) => { return response.data; })
          .catch((error) => { return Promise.reject(error); });
  }

  editRouteGroups(id: any, body: IRouteGroupBody): Promise<any> {
    return axios.put<any>(API_URL + 'routeGroup/' + id, body )
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  deleteRouteGroup(id: any): Promise<any> {
      return axios.delete<any>(API_URL + 'routeGroup/' + id)
          .then((response: AxiosResponse<any>) => { return response.data; })
          .catch((error) => { return Promise.reject(error); });
  }

  // SYNC
  getRoutePerRouteGroup(id: any): Promise<IRouteObj[]> {
    return axios.get<IRouteObj[]>(API_URL + 'routeGroup/' + id + '/routes' )
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  getRolesPerRouteGroup(id: any): Promise<IRole[]> {
    return axios.get<IRole[]>(API_URL + 'routeGroup/' + id + '/userRoles' )
      .then((response: AxiosResponse<any>) => { return response.data.data; })
      .catch((error) => { return Promise.reject(error); });
  }
  assignRoutesToRouteGroup(id: any, body: IRouteToRouteGroup): Promise<IRouteGroupSync> {
    return axios.put<IRouteToRouteGroup>(API_URL + 'routeGroup/' + id + '/routes', body )
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  assignRolesToRouteGroup(id: any, body: IUserToRouteGroup): Promise<IRouteGroupSync> {
    return axios.put<IUserToRouteGroup>(API_URL + 'routeGroup/' + id + '/userRoles', body )
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }
}
export default new AdminService();